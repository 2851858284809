export const styles = {
    // chatWithMeButton: {
    //     cursor: 'pointer',
    //     boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
    //     // Border
    //     borderRadius: '50%',
    //     // Background 
    //     // backgroundImage: 'url(../../assets/bjmp_baliwag_logo.png)', 
    //     // backgroundRepeat: 'no-repeat',
    //     // backgroundPosition: 'center',
    //     // backgroundSize: '84px',
    //     // Size
    //     width: '50px',
    //     height: '50px',
    // },
    chatWithMeButton: {
        cursor: 'pointer',
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
        // Border
        borderRadius: '50px',
        background: 'rgb(0,46,75)',
        background: 'linear-gradient(90deg, rgba(0,46,75,1) 0%, rgba(0,71,117,1) 55%, rgba(3,92,168,1) 100%)',
        // Background 
        // backgroundImage: 'url(../../assets/bjmp_baliwag_logo.png)', 
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        // backgroundSize: '84px',
        // Size
        width: '200px',
        height: '60px',
        color: 'white',
        fontWeight: '800',
        fontSize:'20px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarHello: { 
        // Position
        position: 'absolute', 
        left: 'calc(-100% - 44px - 28px)', 
        top: 'calc(50% - 24px)', 
        // Layering
        zIndex: '10000',
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
        // Border
        padding: '12px 12px 12px 16px',
        borderRadius: '24px', 
        // Color
        backgroundColor: '#f9f0ff',
        color: 'black',
    },
    supportWindow: {
        // Position
        position: 'fixed',
        bottom: '116px',
        right: '24px',
        // Size
        width: '420px',
        height: '530px',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        backgroundColor: 'white',
        // Border
        borderRadius: '12px',
        border: `2px solid #3569c9d3`,
        overflow: 'hidden',
        // Shadow
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.33)',
    },
    emailFormWindow: { 
        width: '100%',  
        overflow: 'hidden',
        transition: "all 0.5s ease",
        WebkitTransition: "all 0.5s ease",
        MozTransition: "all 0.5s ease",
    },
    stripe: {
        position: 'relative',
        top: '-45px',
        width: '100%',
        height: '308px',
        backgroundColor: '#7a39e0',
        transform: 'skewY(-12deg)',
    },
    topText: { 
        position: 'relative',
        width: '100%', 
        top: '15%', 
        color: 'white', 
        fontSize: '24px', 
        fontWeight: '600',
    },
    emailInput: { 
        width: '66%',
        textAlign: 'center',
        outline: 'none',
        padding: '12px',
        borderRadius: '12px',
        border: '2px solid #7a39e0',
    },
    bottomText: { 
        position: 'absolute', 
        width: '100%', 
        top: '60%', 
        color: '#7a39e0', 
        fontSize: '24px', 
        fontWeight: '600' 
    },
    loadingDiv: { 
        position: 'absolute', 
        height: '100%', 
        width: '100%', 
        textAlign: 'center', 
        backgroundColor: 'white',
    },
    loadingIcon: { 
        color: 'white', 
        position: 'absolute', 
        top: 'calc(50% - 51px)', 
        left: 'calc(50% - 51px)',  
        fontWeight: '600',
        backgroundColor: 'rgba(25, 80, 199, 0.938)',
        border: 'none',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    chatEngineWindow: {
        width: '100%',  
        backgroundColor: '#fff',
    }
}