import { useState } from "react";
import '../../styles/FTF.css'

const FormInput = (props) => {

  const [focused, setFocused] = useState(false);
  const { errorMessage, setField, ...inputProps } = props;
  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <>
      <input
        {...inputProps}
        onBlur={handleFocus}
        focused={focused.toString()}
      />
      <span className='errorMsgtest'>{errorMessage}</span>
    </>
  )
}

export default FormInput
