import axios from 'axios'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import FormInput from '../components/forms/FormInput'
import "../styles/ChangePass.css"
import * as IoIcons from 'react-icons/io';

const ForgotPassword = () => {
  const { id } = useParams()
  const [errorPass, setErrorPass] = useState('')
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    tempPass: '',
    password: '',
    password_confirmation: '',
  })

  const containsAnyLetters = (str) => {
    return /[a-zA-Z]/.test(str);
  }
  const containsAnyNumbers = (str) => {
    return /[0-9]/.test(str);
  }
  const containsAnySpecial = (str) => {
    return /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/.test(str);
  }

  const setField = (field, value, e) => {
    setForm({
      ...form, [field]: value
    })

    if (e.target.name === 'password') {
      if (!(e.target.value.length > 7 && e.target.value.length < 21)) {
        setErrorPass('Password length should be 8-20 characters.')
      } else if (!containsAnyLetters(e.target.value)) {
        setErrorPass('Password does not contain a letter.')
      } else if (!containsAnyNumbers(e.target.value)) {
        setErrorPass('Password does not contain a number.')
      } else if (!containsAnySpecial(e.target.value)) {
        setErrorPass('Password does not contain a special characters.')
      } else {
        setErrorPass('')
      }
    }
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();

    const formData = new FormData();
    formData.append('tempPass', form.tempPass);
    formData.append('password', form.password);
    formData.append('id', id);
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/user/changePass`, formData).then(res => {
        if (res.data.success) {
          swal("Success", res.data.msg, "success");
          setLoading(false)
          setTimeout((_) => window.location.assign("/Login"), 1000);
        }
        else {
          swal("Warning", res.data.msg, "warning");
          setLoading(false)
        }
        // console.log(res.data.data)
      });
    });
  }


  const [showPass, setShowPass] = useState(false)
  const [showConPass, setShowConPass] = useState(false)
  const [showTemp, setShowTemp] = useState(false)
  const handleShow = () => {
    setShowPass(!showPass)
  }
  const handleShowConPass = () => {
    setShowConPass(!showConPass)
  }
  const handleShowTempPass = () => {
    setShowTemp(!showTemp)
  }

  return (
    <div className='ChangePass_Body'>
      <div className='ChangePass_Con'>

        <div className='change_pass_upper'>
          <div className='upper_logo'>
            <div className='change_pass_logo'> </div>
          </div>

          <div className='upper_label'>
            <label className='change_pass_label'>Change Password</label>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='change_pass_lower'>
            <div className='email_pic'></div>
            <div className='formChangePass'>
              <div style={{ position: 'relative' }}>
                <label className='loginShowPass' style={{ paddingTop: '19px' }} onClick={handleShowTempPass}>{showTemp ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
              </div>
              <FormInput
                type={showTemp ? 'text' : 'password'}
                className="temp_pass"
                name='tempPass'
                onChange={e => setField('tempPass', e.target.value, e)}
                value={form.tempPass}
                placeholder='Input Temporary Password'
                required
                errorMessage='This field is required.'
              />
            </div>
            <div className='formChangePass'>
              <div style={{ position: 'relative' }}>
                <label className='loginShowPass' style={{ paddingTop: '19px' }} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
              </div>
              <FormInput
                type={showPass ? 'text' : 'password'}
                className="new_pass"
                name='password'
                onChange={e => setField('password', e.target.value, e)}
                value={form.password}
                placeholder='Input New Password'
                pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$'
                required
                errorMessage={errorPass}
              />
            </div>
            <div className='formChangePass'>
              <div style={{ position: 'relative' }}>
                <label className='loginShowPass' style={{ paddingTop: '19px' }} onClick={handleShowConPass}>{showConPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
              </div>
              <FormInput
                type={showConPass ? 'text' : 'password'}
                className="confirm_pass"
                name='password_confirmation'
                onChange={e => setField('password_confirmation', e.target.value, e)}
                value={form.password_confirmation}
                placeholder='Confirm New Password'
                pattern={form.password}
                required
                errorMessage="Passwords don't match!"
              />
            </div>
          </div>

          <div className='change_pass_buttons'>
            {loading ? (
              <button
                className='change_pass'>
                <ClipLoader color={'white'} loading={loading} size={25} />
              </button>
            ) : (
              <button className='change_pass' type='submit'>Apply</button>
            )}
            {/* <button className='change_pass' type='submit'>Apply</button> */}
            <button className='change_pass_cancel' onClick={() => window.location.assign("/Login")}> Cancel</button>
          </div>
        </form>


      </div>


    </div>
  )
}

export default ForgotPassword