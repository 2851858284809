import React from 'react'

function dateFormat(date, type) {
  let timestamp = new Date(date).getTime();
  let Month = new Date(timestamp).toLocaleString('default', {month: type});
  let Day = new Date(timestamp).getDate();
  let Year = new Date(timestamp).getFullYear();
  let NewDateFormat = `${Month} ${Day} ${Year}`;
  return NewDateFormat
}

export default dateFormat