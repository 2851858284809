import axios from 'axios'
import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import emailjs from 'emailjs-com'

const DeclineRequestModal = ({data, closeModal, type, port, link}) => {
    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        e.persist();
        setReason(e.target.value);
    }

    const handleDeclineItem = (e) => {
        e.preventDefault()

        let emailTemp = {}
        if(type === 'edalaw'){
            emailTemp = {
                name: `${data.fname} ${data.lname}`,
                header: 'Your E-Dalaw Request has  been DECLINED.',
                header_tagalog: 'Ang iyong kahilingan upang makipag-usap online ay HINDI NAAPRUBAHAN.',
                email: data.email,
                pdl_name: `${data.fname} ${data.lname}`,
                date: data.date,
                time: `${data.start_time} - ${data.end_time}`,
                type: 'Reason:',
                type_tagalog: 'Rason:',
                body: reason === '' ? 'Unable to process your request due to some circumstances.' : reason,
                note: 'Try requesting again. Thank you for understanding.',
                note_tagalog: 'Subukang humiling muli. Maraming salamat at salamat sa pagunawa.',
            }
        } else {
            emailTemp = {
                name: `${data.fname} ${data.lname}`,
                header: 'Your Face-To_Face Request has  been DECLINED.',
                header_tagalog: 'Ang iyong kahilingan upang makipag-usap personal ay HINDI NAAPRUBAHAN.',
                email: data.email,
                pdl_name: `${data.fname} ${data.lname}`,
                date: data.date,
                time: `${data.start_time} - ${data.end_time}`,
                type: 'Reason:',
                type_tagalog: 'Rason:',
                body: reason === '' ? 'Unable to process your request due to some circumstances.' : reason,
                note: 'Try requesting again. Thank you for understanding.',
                note_tagalog: 'Subukang humiling muli. Maraming salamat at salamat sa pagunawa.',
            }
        }

        setLoading(true)
        axios.patch(`${port}${data.id}`).then(res => {
            emailjs.send('service_l0qpn9e', 'template_1zl4ytw', emailTemp, 'uwAmRC_Wd-V1u9SXo').then(response => {
                swal("Success", res.data.msg, "success");
                setLoading(false)
                setTimeout((_) => window.location.assign(link), 1000);
            }).catch(err => {
                console.log(err)
                swal("Error", "Please try again!", "error");
                setLoading(false)
            });
        });
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Decline</p>
                </div>
                <div className='modalBody'>
                    <form>
                        <div className='reasonForm'>
                            <label>Reason: <span style={{color: 'gray'}}>Optional</span></label>
                            <textarea name='objective' value={reason} onChange={handleChange} placeholder="Write your reason..."/>
                        </div>
                    </form>
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' style={{ backgroundColor: 'crimson' }} onClick={() => closeModal(false)}>Close</button>
                    {loading ? (
                        <button
                            className='submitbtn'
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                            <ClipLoader color={'white'} loading={loading} size={25} />
                        </button>
                    ) : (
                        <button className='closebtnModal' onClick={handleDeclineItem}>Send</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DeclineRequestModal