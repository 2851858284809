import React, { useState } from 'react'
import axios from 'axios'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import emailjs from 'emailjs-com'
import './modal.css'

const ForgotPass = ({ closeModal }) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        e.persist();
        setEmail(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            email: email,
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/user/forgotPass`, data).then(res => {
                if (res.data.success) {
                    let emailTemp = {
                        email: res.data.data.email,
                        name: res.data.data.fname,
                        tempPass: res.data.tempPass,
                        link: `https://bjmpbaliwag.xyz/ForgotPassword/${res.data.hashId}`
                    }
                    emailjs.send('service_bqoeool', 'template_y2cyiys', emailTemp, '3EVvUb1f8h3EPOnhU').then(response => {
                        swal("Success", res.data.msg, "success");
                        setLoading(false)
                    }).catch(err => {
                        console.log(err)
                        swal("Error", "Please try again!", "error");
                        setLoading(false)
                    });
                } else {
                    swal("Success", res.data.msg, "success");
                    setLoading(false)
                }
            });
        });
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Forgot Password</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='modalBody'>
                        {/* <form> */}
                        <div className='reasonForm'>
                            <label>Email: </label>
                            <input name='email' className='forgotpassEmail' value={email} onChange={handleChange} placeholder='Input your verified email' required />
                        </div>
                        {/* </form> */}
                    </div>
                    <div className='modalFooter'>
                        <button className='closebtnModal' style={{ backgroundColor: 'crimson' }} onClick={() => closeModal(false)}>Close</button>
                        {loading ? (
                            <button
                                className='submitbtn'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                                <ClipLoader color={'white'} loading={loading} size={25} />
                            </button>
                        ) : (
                            <button className='closebtnModal' type='submit'>Submit</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPass