import React from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const ExportAdminLog = ({data,filename}) => {
  const headers = [
    {label: 'Admin Name', key: 'name'},
    {label: 'Admin Email', key: 'email'},
    {label: 'Location', key: 'location'},
    {label: 'Date of Activity', key: 'date_activity'},
  ]

  const csvReport = {
    filename: filename,
    headers: headers,
    data: data
  }
  return (
    <div className='al_exportbtn'>
        <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>
  )
}

export default ExportAdminLog