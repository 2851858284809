import Cookies from 'js-cookie'

const SetCookie = (cookieName, user, isChecked) => {
    console.log(isChecked)
    if (isChecked === undefined) {
        Cookies.set(cookieName, user, {
            expires:1, //1 day
            secure:true,
            sameSite: 'strict',
            path:'/'
        });
    } else if (isChecked){
        Cookies.set(cookieName, user, {
            expires:30, 
            secure:true,
            sameSite: 'strict',
            path:'/'
        });
    } else if (!isChecked){
        Cookies.set(cookieName, user, {
            secure:true,
            sameSite: 'strict',
            path:'/'
        });
    }
    // Cookies.set(cookieName, user, {
    //     expires:1, //1 day
    //     secure:true,
    //     sameSite: 'strict',
    //     path:'/'
    // });
}

export default SetCookie;