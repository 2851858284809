import { useEffect } from "react";
import GetCookie from "../hooks/getCookie";

const IsLoggedIn = () => {
  let isLogged = false;
  let isAdmin = false;
  const usrtk = GetCookie("userToken");
  usrtk !== undefined ? isLogged = true : isLogged = false;
  if(isLogged){
    const { userType } = JSON.parse(usrtk);
    userType === 'admin' ? isAdmin = true : isAdmin = false;
  }
  // useEffect(() => {
  //   getInfo()
  // }, [usrtk])

  // const getInfo = () => {
  //   usrtk !== undefined ? isLogged = true : isLogged = false;
  //   if(isLogged){
  //       const { userType } = JSON.parse(usrtk);
  //       userType === 'admin' ? isAdmin = true : isAdmin = false;
  //   }
  // }

  return isAdmin;
}

export default IsLoggedIn;