import React, { useEffect, useState } from 'react'
import "../../css/Admin_Admin_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios'
import swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'
import Avatar from 'react-avatar-edit';
import * as IoIcons from 'react-icons/io';
import loadingUI from '../../../../hooks/loadingUI'
import Default from '../../../../assets/default_user.png'
import SuperAdmin from '../../components/Modal/SuperAdmin'

function Admin_Edit_Admin() {

  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const [disable, setDisable] = useState(true)
  const [superAdmin, setSuperAdmin] = useState(true)
  const [form, setForm] = useState({
    lname: '',
    fname: '',
    mname: '',
    email: '',
    password: '',
    confirm_password: '',
  })
  const [src, setSrc] = useState(null)
  const onClose = () => {
    setImage(null);
  }
  const onCrop = view => {
    setImage(view)
  }

  const [image, setImage] = useState([]);

  const setField = (field, value) => {
    setForm({
      ...form, [field]: value
    })
  }

  useEffect(() => {
    setLoadingPage(true)
    adminData()
  }, [])

  const [adminPic, setAdminPic] = useState('')
  const [adminId, setAdminId] = useState(0)

  const adminData = () => {
    try {
      axios.get(`/api/admin/profile`).then(res => {
        setForm(res.data)
        setAdminPic(res.data.adminImg)
        setAdminId(res.data.id)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    let profile_picture = ''
    if (image.length === 0 && adminPic !== null) {
      profile_picture = adminPic
    } else if (image !== null) {
      profile_picture = image
    } else {
      profile_picture = null
    }
    const formData = new FormData();
    formData.append('adminImg', profile_picture);
    formData.append('lname', form.lname);
    formData.append('fname', form.fname);
    formData.append('mname', form.mname);
    formData.append('email', form.email);
    formData.append('password', form.password);

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/editProfile/${adminId}`, formData).then(res => {
        if (res.data.success) {
          setLoading(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Edit_Admin"), 1500);
        }
        else {
          setLoading(false)
          console.log(res.data.data, res.data.req)
          let tempstr = ''
          if (res.data.msg.email.length > 0) {
            tempstr += res.data.msg.email.toString() + '\n'
          }
          // if (res.data.msg.adminImg.length > 0) {
          //   tempstr += res.data.msg.adminImg.toString() + '\n'
          // }
          swal("Warning", tempstr, "warning");
        }
      });
    });
  }

  const [showPass, setShowPass] = useState(false)
  const [showPassCon, setShowPassCon] = useState(false)
  const handleShow = () => {
    setShowPass(!showPass)
  }
  const handleShowCon = () => {
    setShowPassCon(!showPassCon)
  }

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      <div className='Admin_E_Dalaw_Main'>
        {superAdmin ? <SuperAdmin closeModal={setSuperAdmin} /> : <></>}
        <div><SQB /></div>
        <div className='Admin_Add_Admin'>
          <div className='Add_PDL_Title'><label>EDIT ADMIN : Fill out the following necessary information</label></div>
          <form onSubmit={handleSubmit}>
            <label style={{ fontSize: '20px', marginTop: '10px' }}>Admin Profile Picture</label>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginBottom: '30px', marginTop: '10px' }}>
              <Avatar
                width={200}
                height={200}
                src={src}
                onClose={onClose}
                onCrop={onCrop}
              />
            </div>
            <div className='add_ad'>
              <div className='admin_partition_one'>
                <div className='add_pdl_table'>
                  <div className='left'>First Name: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='fname' className='right' value={form.fname} onChange={e => setField('fname', e.target.value)} placeholder='Input First Name Here' required />
                </div>
                {/* <div className='add_pdl_table'>
                  <div className='left_one'>Middle Name: </div>
                  <input type='text' name='mname' className='right_one' value={form.mname} onChange={e => setField('mname', e.target.value)} placeholder='Input Middle Name Here' />
                </div> */}
                <div className='add_pdl_table'>
                  <div className='left'>Middle Name:</div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '70%', position: 'relative' }}>
                    <input type='text' name='mname' className='right_one' value={form.mname} style={{ width: '100%' }} onChange={e => setField('mname', e.target.value)} placeholder='Input Middle Name Here' />
                    <span style={{ color: 'transparent', fontSize: '12px', textAlign: 'center' }}>Password should be atleast 8-20 characters and include at least 1 letter, 1 number and 1 special character.</span>
                  </div>
                </div>

                <div className='add_pdl_table'>
                  <div className='left'>Last Name: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='lname' className='right' value={form.lname} onChange={e => setField('lname', e.target.value)} placeholder='Input Last Name Here' required />
                </div>

                {/* <div className='add_pdl_table'>
                  <div className='left_one'>Birthday: <span style={{ color: "red" }}>*</span></div>
                  <input className='right_one' type="date" name="birthdate" value={form.birthdate} onChange={e => setField('birthdate', e.target.value)} required placeholder='Select Date' />
                </div>

                <div className='add_pdl_table'>
                  <div className='left'>Gender: </div>
                  <select className='right' name="gender" style={{ fontSize: '14px', color: "grey" }} value={form.gender} onChange={e => setField('gender', e.target.value)} required>
                    <option value="">Select Gender</option>
                    <option value="Male">Male/Lalaki</option>
                    <option value="Female">Female/Babae</option>
                  </select>
                </div> */}

                {/* <div className='add_pdl_table'>
                  <div className='left_one'>Email Address: <span style={{ color: "red" }}>*</span></div>
                  <input type='email' name='email' className='right_one' value={form.email} onChange={e => setField('email', e.target.value)} required placeholder='Input Email Address Here' />
                </div> */}

                {/* <div className='add_pdl_table'>
                  <div className='left_one'>Contact #: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='contactNumber' className='right_one' value={form.contactNumber} onChange={e => setField('contactNumber', e.target.value)} required pattern='[0-9]{11}' placeholder='Input Contact Number Here' />
                </div> */}

              </div>

              <div className='admin_partition_two'>
                {/* <div className='add_pdl_table'>
                  <div className='left_one'>House No.: </div>
                  <input type='text' name='houseNo' className='right_one' value={form.houseNo} onChange={e => setField('houseNo', e.target.value)} placeholder='Input House No. Here' />
                </div>
                <div className='add_pdl_table'>
                  <div className='left'>Street: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='street' className='right' value={form.street} onChange={e => setField('street', e.target.value)} required placeholder='Input Street Here' />
                </div>

                <div className='add_pdl_table'>
                  <div className='left_one'>Barangay: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='barangay' className='right_one' value={form.barangay} onChange={e => setField('barangay', e.target.value)} required placeholder='Input Barangay Here' />
                </div>
                <div className='add_pdl_table'>
                  <div className='left'>City: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='city' className='right' value={form.city} onChange={e => setField('city', e.target.value)} required placeholder='Input City Here' />
                </div>

                <div className='add_pdl_table'>
                  <div className='left_one'>Province: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='province' className='right_one' value={form.province} onChange={e => setField('province', e.target.value)} required placeholder='Input Province Here' />
                </div>

                <div className='add_pdl_table'>
                  <div className='left'>Country: <span style={{ color: "red" }}>*</span></div>
                  <input type='text' name='country' className='right' value={form.country} onChange={e => setField('country', e.target.value)} required placeholder='Input Country Here' />
                </div> */}

                <div className='add_pdl_table'>
                  <div className='left_one'>Email Address: <span style={{ color: "red" }}>*</span></div>
                  <input type='email' name='email' className='right_one' value={form.email} onChange={e => setField('email', e.target.value)} required placeholder='Input Email Address Here' />
                </div>

                <div className='add_pdl_table'>
                  <div className='left'>Password: <span style={{ color: "red" }}>*</span></div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '70%', position: 'relative' }}>
                    <input name='password' className='right' style={{ width: '100%' }} type={showPass ? 'text' : 'password'} value={form.password} onChange={e => setField('password', e.target.value)} required pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$' placeholder='Input Password here' />
                    <label className='loginShowPass' style={{ right: '4%', top: '20%' }} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
                    <span style={{ color: 'gray', fontSize: '12px', textAlign: 'center' }}>Password should be atleast 8-20 characters and include at least 1 letter, 1 number and 1 special character.</span>
                  </div>
                </div>
                <div className='add_pdl_table'>
                  <div className='left'>Confirm Password: <span style={{ color: "red" }}>*</span></div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '70%', position: 'relative' }}>
                    <input name='confirm_password' className='right' style={{ width: '100%' }} type={showPassCon ? 'text' : 'password'} value={form.confirm_password} onChange={e => setField('confirm_password', e.target.value)} required pattern={form.password} placeholder='Input Password here' />
                    <label className='loginShowPass' style={{ right: '4%', top: '20%' }} onClick={handleShowCon}>{showPassCon ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <button className='add_admin_submit'>
                <ClipLoader color={'white'} loading={loading} size={25} />
              </button>
            ) : (
              <button className='add_admin_submit' type='submit'>Submit</button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Admin_Edit_Admin