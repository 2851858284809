import React, { useState, useEffect } from 'react'
import "../../css/Admin_Report_Management.css"

import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';
import HelpdeskDetails from '../../components/Modal/HelpdeskDetails'

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import HelpDeskList from '../../components/Export/HelpDeskList';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_Inquiries() {

  const [request, setRequest] = useState([])
  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  useEffect(() => {
    setLoadingPage(true)
    getRequest()
  }, [])

  const getRequest = () => {
    try {
      axios.get(`/api/admin/helpdesk`).then(res => {
        setRequest(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = (id) => {
    setShow(true)
    setDeleteId(id)
  }

  const handleDeleteItem = () => {
    setLoading(true)
    axios.post(`/api/admin/helpdesk/delete/${deleteId}`).then(res => {
      setLoading(false)
      swal("Success", res.data.msg, "success");
      setTimeout((_) => window.location.assign("/Admin_Inquiries"), 1000);
    });
  }

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;


  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <HelpDeskList data={request} filename='Inquiries.csv' />
          <ModalConfirmation title="Archive Post" body="Are you sure you want to archive this post?" setShow={setShow} show={show} yesFunc={handleDeleteItem} loading={loading} />
          <div className='Admin_Inqui_Con'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>HELPDESK</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <table className='styled-table marginContent'>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Contact #</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Date Submitted</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {request && request.slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.name}</td>
                      <td>{item.contactNumber}</td>
                      <td>{item.email}</td>
                      <td>{dateFormat(item.created_at, 'short')}</td>
                      <td className='actionstatus'>
                        <button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button>
                        <button className='cancelbtn' onClick={() => handleDelete(item.id)}>Archive</button></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={request}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
            <a className='archivesbtn' href='/Admin_Inquiries_Archive'>View Archives</a>
          </div>
        </div>
      )}
      {openModal && <HelpdeskDetails closeModal={setOpenModal} data={item} />}
    </div>
  )
}

export default Admin_Inquiries