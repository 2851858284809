import React from 'react'
import { HashLoader } from 'react-spinners'

function loadingUI(loading) {
  return (
    <div style={{display:'flex', height:'100vh', justifyContent:'center'}}>
      <div style={{height:'100px', alignSelf:'center', marginBottom:'200px'}}>
        <HashLoader color={'#6e9afaef'} loading={loading} size={75}/>
      </div>
    </div>
  )
}

export default loadingUI