import React, { useEffect, useState } from 'react'
import '../styles/AboutUs.css'
import Logo from "../assets/bjmp_baliwag_logo.png";
import BJMP_Logo from "../assets/bjmp_logo.png";
import Head_Banner from "../components/Banner";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import loadingUI from '../hooks/loadingUI';
import axios from 'axios';
import ContactFooter from "../components/Con_Footer";

function AboutUs() {
  const [vmgo, setVmgo] = useState([])
  const [loading, setLoading] = useState(false)
  const [img, setImg] = useState([])

  useEffect(() => {
    setLoading(true)
    getLogo()
    getVmgo()
  }, [])

  const getLogo = () => {
    try {
      axios.get(`/api/admin/logo/getAll`).then(res => {
        setImg(res.data.data)
        // console.log(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getVmgo = () => {
    try {
      axios.get(`/api/admin/vmgo/get`).then(res => {
        setVmgo(res.data.data[0])
        setLoading(false)
      });
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }


  if (loading) return loadingUI(loading)
  return (
    <div>
      <Navbar />
      <div className='Banner'><Head_Banner /></div>
      <div className='Main_body' style={{ paddingTop: '130px' }}>

        <div className="what_bjmp">
          <label className='bjmp_title'>WHAT IS BUREAU OF JAIL MANAGEMENT AND PENOLOGY </label>
          <div className='hidden_logo'>
            <img src={Logo} alt="" className="picture_one_hide" />
            <img src={BJMP_Logo} alt="" className="picture_two_hide" />
          </div>
          <div className='description_bjmp'>
            <div className="bal_des">
              <div className="about_bjmp_right" align="right">
                <label className='bjmp_description_right' > On January 2, 1991, the Bureau of Jail Management and Penology was created
                  thru Republic Act 6975 as a line Bureau under the Department of Interior and Local
                  Government. The Jail Bureau is the upgraded version of its forerunner, the Office of
                  Jail Management and Penology of the defunct PC/INP
                  last headed by BRIG GEN Arsenio E. Concepcion.</label>
              </div>
              {/* <img src={Logo} alt="" className="picture_one" /> */}
              {img.length !== 0 && <img src={img[0].image} alt="" className="picture_two" />}
            </div>


            <div className="bjmp_des">
              <div className='bjmp_bal_left'>
                {/* <img src={BJMP_Logo} alt="" className="picture_two" /> */}
                {img.length !== 0 && <img src={img[1].image} alt="" className="picture_two" />}
              </div>
              <div className='bjmp_desc_left'>
                <div className='bjmp_description' style={{textAlign: 'left'}}> As one of the five pillars of the Criminal Justice System,
                  the BJMP was created to address growing concern of jail management
                  and penology problem. Primarily, its clients are detainees accused before
                  a court who are temporarily confined in such jails while undergoing investigation,
                  waiting final judgement and those who are serving sentence promulgated by the
                  court 3 years and below. As provided for under R.A. No. 6975, the Jail Bureau
                  is mandated to take operational and administrative control
                  over all city, district and municipal jails.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Title'><h2>BJMP VMGO</h2></div>
        <div className='about_us_body'>

          <div className='vis'>
            <div className='vmgo'>Vision</div>
            {vmgo && (<div className='def'>{vmgo.vision}</div>)}
          </div>

          <div className='mis'>
            <div className='vmgo'>Mission</div>
            {vmgo && (<div className='def'>{vmgo.mission}</div>)}
          </div>

          <div className='goal'>
            <div className='vmgo'>Goal</div>
            {vmgo && (<div className='def'>{vmgo.goal}</div>)}
          </div>

          <div className='obj'>
            <div className='vmgo'>Objective</div>
            {vmgo && (<div className='def'>{vmgo.objective}</div>)}
          </div>
        </div>
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  )
}

export default AboutUs