import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import dateFormat from '../hooks/dateFormat'
import url from '../hooks/url'
import Head_Banner from "../components/Banner";
import '../styles/SinglePost.css'
import loadingUI from '../hooks/loadingUI'
import { FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon, MailruShareButton, ViberShareButton, ViberIcon, RedditShareButton, RedditIcon } from "react-share";
import { RWebShare } from "react-web-share";

const SinglePost = () => {
    const { id } = useParams()
    const [post, setPost] = useState({})
    const [loading, setLoading] = useState(false)
    const port = url()

    useEffect(() => {
        if (id) {
            try {
                setLoading(true)
                axios.get(`/api/user/getSinglePost/${id}`).then(res => {
                    setLoading(false)
                    setPost(res.data.data)
                });
            } catch (error) {
                console.log(error)
            }
        }
    }, [id])

    const shareUrl = `https://bjmpbaliwag.xyz/Post/${id}`

    if (loading) return loadingUI(loading)

    return (
        <>
            <Navbar />
            {/* <div className="body">
                <div className='Banner'><Head_Banner /></div>

                <div style={{ textAlign: 'unset', paddingTop:'130px'}}>
                    <div>
                        <div>{post && post.title}</div>
                        <div>{post.date_posted ? <p>{dateFormat(post.date_posted, 'long')}</p> : <p>{dateFormat(post.created_at, 'long')}</p>}</div>
                        {post.media_img && (
                            <div>
                                <img src={`${port}${post.media_img}`}></img>
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: post.body }} style={{ height: 'fit-content' }}></div>
                    </div>
                </div>
            </div> */}
            <div className='art_card' style={{marginTop:'40px', marginBottom:'20px'}}>
                <div className='singlePostImage' >
                    <img src={`${port}${post.media_img}`} style={{width:'100%', height:'100%', objectFit:'cover'}} alt=''></img>
                    {/* <div className='news_image' style={{height:'100%', backgroundImage: `url(${port}${post.media_img})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div> */}
                </div>
                <div className='news_cont'>
                    <label className='news_T'><h2 style={{ paddingRight: '20px', textAlign:'center', marginBottom:'15px' }}>{post && post.title}</h2></label>
                    {post.date_posted && (<label className='news_D'>DATE: {post.date_posted}</label>)}
                    <label className='news_B' dangerouslySetInnerHTML={{ __html: post.body }}></label>
                    <label className='news_Pub'>Date Published : {dateFormat(post.created_at, 'long')}</label>
                    <div className='sharetoFB'>
                        <label>Share this on:</label>
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={50}></FacebookIcon>
                        </FacebookShareButton>
                        <RedditShareButton url={shareUrl}>
                            <RedditIcon size={50}></RedditIcon>
                        </RedditShareButton>
                        {/* <RWebShare
                            data={{
                                url: shareUrl,
                                title: post.title,
                            }}
                            disableNative
                            onClick={() => console.log("shared successfully!")}
                        >
                            <EmailIcon size={50}></EmailIcon>
                        </RWebShare> */}
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={50}></WhatsappIcon>
                        </WhatsappShareButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SinglePost