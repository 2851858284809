import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import geoModalFunc from './geoModalFunc'
import ExportPdlList from '../../components/Export/ExportPdlList';

const Barangay = styled.p`
background-color: #054068;
width: 100%;
color: white;
padding: 10px;
text-align: center;
font-size: 22px;
`

const Count = styled.p`
background-color: #d3d3d3;
width: 100%;
padding: 10px;
text-align: center;
font-size: 22px;
`

const GeoModal = ({ closeModal, data, type }) => {
    const [pdlData, setPdlData] = useState([])
    useEffect(() => {
        getPdlData()
    }, [data])

    const getPdlData = () => {
        try {
            if(type === 'pdl'){
                axios.get(`/api/admin/pdl/getAll`).then(res => {
                    setPdlData(res.data.data)
                });
            } else if (type === 'user'){
                axios.get(`/api/admin/user/getVerified`).then(res => {
                    setPdlData(res.data.data)
                });
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    let arr = geoModalFunc(data, pdlData)

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Details</p>
                </div>
                <div className='modalBody' style={{overflowY:'scroll', maxHeight:'500px'}}>
                    <div className='chosen_barangay'>
                        <Barangay>{data.barangay}</Barangay>
                        <Count>{type === 'pdl' ? data.pdl_count : data.user_count}</Count>
                    </div>

                    {arr.length !== 0 && <ExportPdlList data={arr} filename={`GeoTag${type}Info.csv`} />}

                    {arr.length > 0 && (
                    <table className='geo-table-map' style={{marginTop:'20px'}}>
                        <thead>
                            <tr style={{ position: 'sticky', top: '0' }}>
                                <td style={{ textAlign: "center" }}>No.</td>
                                {type === 'pdl' && <td>PDL No.</td>}
                                <td>Name</td>
                            </tr>
                        </thead>
                        <tbody>
                            {arr && arr.map((item, index) => {
                                return (
                                    <tr key={index} className={index % 2 == 0 ? "odd" : "even"}>
                                        <td scope='row' style={{textAlign:'center', padding:'10px'}}>{index + 1}</td>
                                        {type === 'pdl' && <td>{item.pdl_no}</td>}
                                        <td>{item.fname + " " + item.lname}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    )}
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' onClick={() => closeModal(false)}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default GeoModal