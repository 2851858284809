/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import Home from "./pages/Home";
import AgencyLinks from "./pages/AgencyLinks";
import HelpDesk from "./pages/Helpdesk_Page";
import News from "./pages/News";
import AboutUs from "./pages/AboutUs";
import Login from "./pages/Login";
import Announcement from "./pages/Announcement";
import GeoTag from "./pages/GeoTag";
import Face_to_Face from "./pages/FacetoFace";
import EDalaw from "./pages/E_Dalaw";
import FTF_Not_In from "./pages/FaceToFace_NotIn";
import Register from "./pages/Register"
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import GetCookie from "./hooks/getCookie";
import React, { useState } from 'react'
import IsLoggedIn from "./auth/isLoggedIn";
import EditProfile from "./pages/Edit_Profile";


import Admin_Dashboard from "./pages/AdminPages/js/Admin_Dashboard";
import Dash_Login from "./pages/AdminPages/js/Dash_Login";

import Admin_Add_Post from "./pages/AdminPages/js/Content_Management/Admin_Add_Post";
import Admin_Manage_Post from "./pages/AdminPages/js/Content_Management/Admin_Manage_Post";
import Admin_Edit_VMGO from "./pages/AdminPages/js/Content_Management/Admin_Edit_VMGO";
import Admin_Edit_Carousel from "./pages/AdminPages/js/Content_Management/Admin_Edit_Carousel";

import EDalaw_Accepted from "./pages/AdminPages/js/E_Dalaw_Management/E_Dalaw_Accepted";
import EDalaw_Pending from "./pages/AdminPages/js/E_Dalaw_Management/E_Dalaw_Pending";
import EDalaw_Declined from "./pages/AdminPages/js/E_Dalaw_Management/E_Dalaw_Declined";
import EDalaw_History from "./pages/AdminPages/js/E_Dalaw_Management/E_Dalaw_History";

import FTF_Accepted from "./pages/AdminPages/js/FTF_Management/FTF_Accepted";
import FTF_Pending from "./pages/AdminPages/js/FTF_Management/FTF_Pending";
import FTF_Declined from "./pages/AdminPages/js/FTF_Management/FTF_Declined";
import FTF_History from "./pages/AdminPages/js/FTF_Management/FTF_History";

import Users_Report from "./pages/AdminPages/js/Report_Management/Admin_Users_Report";
import Pending_Users from "./pages/AdminPages/js/Report_Management/Admin_Pending_Users";
import Inquiries from "./pages/AdminPages/js/Report_Management/Admin_Inquiries";

import PDL_Info from "./pages/AdminPages/js/PDL_Management/Admin_PDL_Info";
import Add_PDL from "./pages/AdminPages/js/PDL_Management/Admin_Add_PDL";
import PDL_Archive from "./pages/AdminPages/js/PDL_Management/Admin_PDL_Archived";

import Admin_GeoTag from "./pages/AdminPages/js/GeoTag_Management/Admin_GeoTag_Info";
import Admin_GeoTag_User from './pages/AdminPages/js/GeoTag_Management/Admin_GeoTag_UserInfo'

import Admin_View_Admins from "./pages/AdminPages/js/Admin_Management/Admin_View_Admins";
import Admin_View_Logs from "./pages/AdminPages/js/Admin_Management/Admin_View_Logs";

import Admin_Add_Admin from "./pages/AdminPages/js/Admin_Management/Admin_Add_Admin";
import Chat from './pages/AdminPages/js/Chat/Chat'

import isAdmin from './auth/isAdmin'
import Admin_View_Logs_Archive from "./pages/AdminPages/js/Admin_Management/Admin_View_Logs_Archive";
import Admin_Inquiries_Archive from "./pages/AdminPages/js/Report_Management/Admin_Inquiries_Archive";
import Admin_Archive_Post from "./pages/AdminPages/js/Content_Management/Admin_Archive_Post";
import url from "./hooks/url";
import Admin_Edit_Post from "./pages/AdminPages/js/Content_Management/Admin_Edit_Post";
import SinglePost from "./pages/SinglePost";
import Admin_Edit_PDL from "./pages/AdminPages/js/PDL_Management/Admin_Edit_PDL";
import ForgotPassword from "./pages/ForgotPassword";
import Admin_Edit_Admin from "./pages/AdminPages/js/Admin_Management/Admin_Edit_Admin";
import Admin_ForgotPassword from "./pages/AdminPages/js/AdminForgotPassword";

axios.defaults.baseURL = url();
// axios.defaults.baseURL = "https://apiproject.bjmpbaliwag.xyz/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const userCookie = GetCookie("userToken");
  if (userCookie === undefined) {
    config.headers.Authorization = userCookie ? `Bearer ${userCookie}` : '';
    return config;
  } else {
    const { token } = JSON.parse(userCookie);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  }
})

function App() {

  let isLogged = false;
  const usrtk = GetCookie("userToken");
  usrtk === undefined ? isLogged = false : isLogged = true;

  return (
    <div className="App">
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/Home' exact element={<Home />} />
          <Route path='/AgencyLinks' exact element={<AgencyLinks />} />
          <Route path='/HelpDesk' exact element={<HelpDesk />} />
          <Route path='/News' exact element={<News />} />
          <Route path='/About_Us' exact element={<AboutUs />} />
          <Route path='/Post/:id' element={<SinglePost />} />
          <Route path='/ForgotPassword/:id' element={<ForgotPassword />} />
          <Route
            path='/Login'
            element={IsLoggedIn() || isLogged ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path='/Sign_Up'
            element={IsLoggedIn() || isLogged ? <Navigate to="/" /> : <Register />}
          />
          <Route path='/Announcement' exact element={<Announcement />} />
          {/* <Route path='/Edit_Profile' exact element={<EditProfile />} /> */}

          {/* protected routes for verified user only */}
          <Route
            path="/Geo_Tag"
            element={IsLoggedIn() && !isAdmin() ? <GeoTag /> : <FTF_Not_In />}
          />
          <Route
            path="/Edit_Profile"
            element={IsLoggedIn() && !isAdmin() ? <EditProfile /> : <FTF_Not_In />}
          />
          <Route
            path="/Face_to_Face"
            element={IsLoggedIn() && !isAdmin() ? <Face_to_Face /> : <FTF_Not_In />}
          />

          <Route
            path="/Online_Visitation"
            element={IsLoggedIn() && !isAdmin() ? <EDalaw /> : <FTF_Not_In />}
          />

          <Route path='/Admin_Login' exact  element={isAdmin() ? <Navigate to="/Admin_Dashboard" /> : <Dash_Login />} />
          <Route path='/Admin_ForgotPassword' exact element={<Admin_ForgotPassword />} />
          <Route path='/Admin_Dashboard' element={isAdmin() ? <Admin_Dashboard /> : <FTF_Not_In />} />

          <Route path='/Admin_Add_Post' exact element={isAdmin() ? <Admin_Add_Post /> : <FTF_Not_In />} />
          <Route path='/Admin_Edit_Post' exact element={isAdmin() ? <Admin_Edit_Post /> : <FTF_Not_In />} />
          <Route path='/Admin_Manage_Post' exact element={isAdmin() ? <Admin_Manage_Post /> : <FTF_Not_In />} />
          <Route path='/Admin_Archive_Post' exact element={isAdmin() ? <Admin_Archive_Post /> : <FTF_Not_In />} />
          <Route path='/Admin_Edit_VMGO' exact element={isAdmin() ? <Admin_Edit_VMGO /> : <FTF_Not_In />} />
          <Route path='/Admin_Edit_Carousel' exact element={isAdmin() ? <Admin_Edit_Carousel /> : <FTF_Not_In />} />

          <Route path='/Admin_E_Dalaw_Accepted' exact element={isAdmin() ? <EDalaw_Accepted /> : <FTF_Not_In />} />
          <Route path='/Admin_E_Dalaw_Pending' exact element={isAdmin() ? <EDalaw_Pending /> : <FTF_Not_In />} />
          <Route path='/Admin_E_Dalaw_Declined' exact element={isAdmin() ? <EDalaw_Declined /> : <FTF_Not_In />} />
          <Route path='/Admin_E_Dalaw_History' exact element={isAdmin() ? <EDalaw_History /> : <FTF_Not_In />} />

          <Route path='/Admin_FTF_Accepted' exact element={isAdmin() ? <FTF_Accepted /> : <FTF_Not_In />} />
          <Route path='/Admin_FTF_Pending' exact element={isAdmin() ? <FTF_Pending /> : <FTF_Not_In />} />
          <Route path='/Admin_FTF_Declined' exact element={isAdmin() ? <FTF_Declined /> : <FTF_Not_In />} />
          <Route path='/Admin_FTF_History' exact element={isAdmin() ? <FTF_History /> : <FTF_Not_In />} />

          <Route path='/Admin_Users_Report' exact element={isAdmin() ? <Users_Report /> : <FTF_Not_In />} />
          <Route path='/Admin_Pending_Users' exact element={isAdmin() ? <Pending_Users /> : <FTF_Not_In />} />
          <Route path='/Admin_Inquiries' exact element={isAdmin() ? <Inquiries /> : <FTF_Not_In />} />
          <Route path='/Admin_Inquiries_Archive' exact element={isAdmin() ? <Admin_Inquiries_Archive /> : <FTF_Not_In />} />

          <Route path='/Admin_PDL_Info' exact element={isAdmin() ? <PDL_Info /> : <FTF_Not_In />} />
          <Route path='/Admin_Add_PDL' exact element={isAdmin() ? <Add_PDL /> : <FTF_Not_In />} />
          <Route path='/Admin_Edit_PDL' exact element={isAdmin() ? <Admin_Edit_PDL /> : <FTF_Not_In />} />
          <Route path='/Admin_PDL_Archive' exact element={isAdmin() ? <PDL_Archive /> : <FTF_Not_In />} />

          <Route path='/Admin_GeoTag_Management' exact element={isAdmin() ? <Admin_GeoTag /> : <FTF_Not_In />} />
          <Route path='/Admin_GeoTag_UserInfo' exact element={isAdmin() ? <Admin_GeoTag_User /> : <FTF_Not_In />} />

          <Route path='/Admin_View_Admins' exact element={isAdmin() ? <Admin_View_Admins /> : <FTF_Not_In />} />
          <Route path='/Admin_Add_Admin' exact element={isAdmin() ? <Admin_Add_Admin /> : <FTF_Not_In />} />
          <Route path='/Admin_Edit_Admin' exact element={isAdmin() ? <Admin_Edit_Admin /> : <FTF_Not_In />} />
          <Route path='/Admin_View_Logs' exact element={isAdmin() ? <Admin_View_Logs /> : <FTF_Not_In />} />
          <Route path='/Admin_View_Logs_Archived' exact element={isAdmin() ? <Admin_View_Logs_Archive /> : <FTF_Not_In />} />

          <Route path='/Admin_Messenger_Application' exact element={isAdmin() ? <Chat /> : <FTF_Not_In />} />

        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}




export default App;
