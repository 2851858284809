import React, { useState, useEffect } from 'react'
import "../../css/Admin_Admin_Management.css"
import styled from 'styled-components';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import Pagination from '../../../../components/Pagination/Pagination';
import dateFormat from '../../../../hooks/dateFormat';
import axios from 'axios';
import ShowEntry from '../../../../components/Pagination/ShowEntry';
import AdminDetails from '../../components/Modal/AdminDetails'
import ExportAdmin from '../../components/Export/ExportAdmin';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_View_Admins() {

  const [admins, setAdmins] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  useEffect(() => {
    setLoadingPage(true)
    getAdmins()
  }, [])

  const getAdmins = () => {
    try {
      axios.get(`/api/admin/getAll`).then(res => {
        setAdmins(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportAdmin data={admins} filename='AdminInfo.csv' />
          <div className='Admin_View_Admins'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>VIEW ADMINS</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <table className='styled-table marginContent'>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  {/* <th style={{ textAlign: "center" }}>Contact No</th>
                  <th style={{ textAlign: "center" }}>Birthdate</th> */}
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {admins && admins.slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{`${item.fname} ${item.lname}`}</td>
                      <td>{item.email}</td>
                      {/* <td>{item.contactNumber}</td>
                      <td>{dateFormat(item.birthdate, 'short')}</td> */}
                      <td className='actionstatus'>
                        <button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={admins}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      {openModal && <AdminDetails closeModal={setOpenModal} data={item} />}
    </div>
  )
}

export default Admin_View_Admins