import React, { useEffect, useState } from 'react';
import '../styles/Banner.css'
import Logo from "../assets/bjmp_baliwag_logo.png";
import Timer from '../components/Timer';
import BJMP_Logo from "../assets/bjmp_logo.png";
import { Link } from 'react-router-dom';
import axios from 'axios';



function Banner() {
  const [img, setImg] = useState([])

  useEffect(() => {
    try {
      axios.get(`/api/admin/logo/getAll`).then(res => {
        setImg(res.data.data)
        // console.log(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }, [])


  return (
    <div className='ban_body'>
      <div className="banner">
        <div className='Banner_Logos'>
          <Link to="/">
            {img && img.map((item, i) => {
              if (i === 0) {
                return (<img key={i} src={item.image} alt="" className="inner-image" />)
              }
            })}
          </Link>
          <Link to="/">
            {img && img.map((item, i) => {
              if (i === 1) {
                return (<img key={i} src={item.image} alt="" className="inner-image_1" />)
              }
            })}
          </Link>
        </div>
        <div className='Banner_Text'>
          <label className='ROTP'> REPUBLIC OF THE PHILIPPINES</label>
          <label className='BJMP-BAL'> BUREAU OF JAIL MANAGEMENT AND PENOLOGY - BALIWAG</label>
          <label className='Tag'> "Changing Lives, Building a Safer Nation"</label>
        </div>



        <div>

        </div>


        <div>


        </div>

      </div>
    </div>
  )
}

export default Banner