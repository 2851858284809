import React from 'react'
import dateFormat from './dateFormat'

function compareDate(requestVisits, selectedDate, itemValue) {
  let isSelected = false;
  let startTime = itemValue.split(',')
  requestVisits.map((item) => {
    if(item.status === 'Pending' || item.status === 'Accepted'){
        if(dateFormat(item.date) == dateFormat(selectedDate)){
          if(startTime[0] === item.start_time) {
            isSelected = true
          }
          //startTime[0] === item.start_time ? isSelected = true : isSelected = false
        }
      }
  })
  
  return isSelected
}

export default compareDate


// "@emotion/react": "^11.10.0",
    // "@emotion/styled": "^11.10.0",
    // "@mui/icons-material": "^5.8.4",
    // "@mui/material": "^5.10.0",