import axios from 'axios';
import React, { useState } from 'react'
import * as IoIcons from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import swal from 'sweetalert';

const SuperAdmin = ({ closeModal, type }) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const setField = (field, value) => {
        setForm({
            ...form, [field]: value
        })
    }

    const [showPass, setShowPass] = useState(false)
    const handleShow = () => {
        setShowPass(!showPass)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData();
        formData.append('email', form.email);
        formData.append('password', form.password);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/admin/superAdmin`, formData).then(res => {
                if (res.data.success) {
                    setLoading(false)
                    swal("Success", res.data.msg, "success");
                    closeModal(false)
                }
                else {
                    setLoading(false)
                    swal("Warning", res.data.msg, "warning");
                }
            });
        });

    }

    return (
        <div className='modalBackground' style={type === 'forgotpass' ? { top: '0', left: '0', width: '100%', height:'100%' } : { top: 'unset', left: 'unset', width: '80%', height:'91%' }}>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Super Admin Access</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='modalBody'>
                        <div className='reasonForm' style={{ marginTop: '10px' }}>
                            <label style={{ fontSize: '18px' }}>Super Admin Email: </label>
                            <input name='name' type='email' value={form.email} onChange={e => setField('email', e.target.value)} placeholder="Enter Email" required />
                        </div>
                        <div className='reasonForm' style={{ marginTop: '10px', position: 'relative' }}>
                            <label style={{ fontSize: '18px' }}>Super Admin Password: </label>
                            <input name='password' type={showPass ? 'text' : 'password'} value={form.password} onChange={e => setField('password', e.target.value)} placeholder="Enter Password" required />
                            <label className='loginShowPass' style={{ right: '4%', top: '50%' }} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
                        </div>
                    </div>
                    <div className='modalFooter'>
                        {loading ? (
                            <button
                                className='submitbtn'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                                <ClipLoader color={'white'} loading={loading} size={25} />
                            </button>
                        ) : (
                            <button className='closebtnModal' type='submit'>Submit</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SuperAdmin