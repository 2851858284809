import React from 'react'
import './detailsModal.css'

const DetailsModal = ({closeModal, data}) => {
  const status_class = (status) => {
    if (status === 'Pending') {
      return (<p className='pendingStat'>{status}</p>)
    } else if (status === 'Accepted') {
      return (<p className='acceptedStat'>{status}</p>)
    } else if (status === 'Declined') {
      return (<p className='declinedStat'>{status}</p>)
    }
   }

  return (
    <div className='modalBackground'>
      <div className='modalContainer'>
        <div className='modalTitle'>
          <p className='modalP'>Details</p>
        </div>
        <div className='modalBody'>
          {data.status && (<span className='stat'>Status: {status_class(data.status)}</span>)}
          {data.fname && (<span>First Name: {data.fname}</span>)}
          {data.mname && (<span>Middle Name: {data.mname}</span>)}
          {data.lname && (<span>Last Name: {data.lname}</span>)}
          {data.gender && (<span>Gender: {data.gender}</span>)}
          {data.contactNo && (<span>Contact Number: {data.contactNo}</span>)}
          {data.email && (<span>Email: {data.email}</span>)}
          {data.address && (<span>Address: {data.address}</span>)}
          {data.pdl_name && (<span>PDL Name: {data.pdl_name}</span>)}
          {data.pdl_relationship && (<span>PDL Relationship: {data.pdl_relationship}</span>)}
          {data.date && (<span>Scheduled Date: {data.date}</span>)}
          {data.start_time && (<span>Scheduled Time: {data.start_time + " - " + data.end_time}</span>)}
          {data.meet_link && (<span>Meet Link: {data.meet_link}</span>)}
        </div>
        <div className='modalFooter'>
          <button className='closebtnModal' onClick={()=>closeModal(false)}>Close</button>
        </div>
      </div>
    </div>
  )
}


export default DetailsModal 