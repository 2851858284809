
import GetCookie from '../hooks/getCookie'

const CurrentUser = () => {

    let userin = GetCookie("userToken");
    const { userType } = JSON.parse(userin);

    return userType
}

export default CurrentUser
