import React, { useEffect, useState } from 'react'
import "../../css/Admin_Content_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import CarouselTemplate from '../../components/CarouselTemplate'
import axios from 'axios'
import CarouselModal from '../../components/Modal/CarouselModal'
import url from '../../../../hooks/url'
import loadingUI from '../../../../hooks/loadingUI'


function Admin_Edit_Carousel() {

  const [image, setImage] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [func, setFunc] = useState({});
  let port = url()
  useEffect(() => {
    try {
      setLoadingPage(true)
      axios.get(`/api/admin/carousel/getAll`).then(res => {
        setImage(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_Add_Post_Main'>
          <div><SQB /></div>
          <div className='Edit_Carousel_Con'>

            <div className='All_Car_Con'>
              {image.map((item, index) => {
                return (
                  <CarouselTemplate data={item} key={index} />
                )
              })}
              <div className='Add_Car_Con'>
                <button className='add_car' onClick={() => { setOpenModal(true); setFunc('add') }}> + <br></br>Add Image</button>
              </div>
            </div>
            {/* <div className='car_saveChanges'>
            <button className='car_change'>Save Changes</button>
          </div> */}
          </div>
        </div>
      )}
      {openModal && <CarouselModal closeModal={setOpenModal} data={null} />}
    </div>
  )
}

export default Admin_Edit_Carousel