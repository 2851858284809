import React from 'react'

const ShowEntry = ({ itemsPerPage, setItemsPerPage }) => {
    const handleChange = (e) => {
        e.persist();
        if(e.target.value > 0){
            setItemsPerPage(e.target.value)
        }
    }
    return (
        <form style={{ marginBottom: '10px', paddingLeft: '10px', fontSize:'16px', paddingTop:'10px' }}>
            <p style={{ display: 'inline' }}>Show </p>
            <input type="number" name="count" value={itemsPerPage} onChange={handleChange} style={{ display: 'inline', width: '50px' }} />
            <p style={{ display: 'inline' }}> entries </p>
        </form>
    )
}

export default ShowEntry