import { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes, FaAngleDown } from "react-icons/fa";
import "../styles/Navbar.css";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from "axios";
import RemoveCookie from "../hooks/removeCookie";
import swal from "sweetalert";
import GetCookie from "../hooks/getCookie";
import IsLoggedIn from "../auth/isLoggedIn";
import isAdmin from '../auth/isAdmin'
import { ClipLoader } from "react-spinners";
import DefaultUser from '../assets/default_user.png'

function Navbar() {

	// const navigate = useNavigate();

	const [userInfo, setUserInfo] = useState({})
	const [loading, setLoading] = useState(false)
	const [loadingBtn, setLoadingBtn] = useState(false)

	useEffect(() => {
		let cancel
		const userData = () => {
			setLoading(true)
			axios.get('/api/user/data', {
				cancelToken: new axios.CancelToken((c) => cancel = c),
			}).then(res => {
				setUserInfo(res.data.data)
				setLoading(false)
			})
				.catch((e) => {
					if (axios.isCancel(e)) return console.log("Request Cancelled")
				});
		}
		if (IsLoggedIn()) userData()

		return () => {
			if (cancel) {
			  cancel()
			}
		  }
	}, [])

	const logoutSubmit = (e) => {
		e.preventDefault();
		setLoadingBtn(true)
		axios.post(`api/user/logout`).then(res => {
			if (res.data.success) {
				setLoadingBtn(false)
				RemoveCookie("userToken");
				swal("Success", res.data.msg, "success");
				setTimeout((_) => window.location.assign("/"), 2500);
			}
		})
	}
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	var AuthBtn = '';
	const userCookie = GetCookie("userToken");
	if (!userCookie) {
		AuthBtn = (
			<button className="menus"><div className="Log"><Link to="/Login"> Login </Link></div></button>
		);
	} else {
		if (isAdmin()) {
			AuthBtn = (
				<div className="dropdown">
					{/* <button className="menus">Profile <FaAngleDown size="15" /></button> */}
					<div className="prof_but"><button className="menus">Hi! Admin</button></div>

					<div className="dropdown-content">
						{/* <button className="menus" type="button" onClick={logoutSubmit}> */}
						{loadingBtn ? (<Link to=""><ClipLoader color={'blue'} loading={loadingBtn} size={25} /></Link>) : (<Link to="" onClick={logoutSubmit}>Logout</Link>)}
						{/* </button> */}
						<Link to="/Edit_Profile"> Edit Profile </Link>
					</div>

				</div>

				// <button className="menus" type="button" onClick={logoutSubmit}><Link to="">Logout</Link></button>
			);
		} else {
			if (loading) {
				AuthBtn = (
					<div className="dropdown">
						{/* <button className="menus">Profile <FaAngleDown size="15" /></button> */}
						<div className="prof_but">
							<button className="menus" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<ClipLoader color={'blue'} loading={loading} size={25} />
							</button>
						</div>
					</div>
				);
			} else {
				AuthBtn = (
					<div className="dropdown">
						{/* <button className="menus">Profile <FaAngleDown size="15" /></button> */}
						<div className="prof_but">
							<button className="menus" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								{userInfo.profilePic !== null ? (
									<>
										<img src={`${userInfo.profilePic}`} />
										<p>{"Hi! " + userInfo.fname}</p>
									</>
								) : (
									<>
										<img src={DefaultUser} />
										<p>{"Hi! " + userInfo.fname}</p>
									</>
								)}
								<FaAngleDown size="15" />
							</button>
						</div>

						<div className="dropdown-content">
							{/* <button className="menus" type="button" onClick={logoutSubmit}> */}
							{loadingBtn ? (<Link to=""><ClipLoader color={'blue'} loading={loadingBtn} size={25} /></Link>) : (<Link to="" onClick={logoutSubmit}>Logout</Link>)}
							{/* </button> */}
							<Link to="/Edit_Profile"> Edit Profile </Link>
						</div>

					</div>

					// <button className="menus" type="button" onClick={logoutSubmit}><Link to="">Logout</Link></button>
				);
			}
		}

	}

	return (
		<div className="nav_Body">
			<header>
				<h3><a href="http://www.gov.ph/" target="_blank">GOVPH</a></h3>
				<nav ref={navRef}>
					<div className="leftSide">
						<button className="menus"><div><NavLink activeclassname="active" to='/Home' >Home</NavLink></div></button>
						<button className="menus"><div><NavLink activeclassname="active" to='/News' >News</NavLink></div></button>
						<button className="menus"><div><NavLink activeclassname="active" to='/Announcement' >Announcement</NavLink></div></button>
						<button className="menus"><div><NavLink activeclassname="active" to='/About_Us' > About Us </NavLink></div></button>
						<button className="menus"><div><NavLink activeclassname="active" to='/Geo_Tag' > Geo Tag </NavLink></div></button>
						<div className="dropdown">
							<button className="menus">  Visitation <FaAngleDown size="15" /></button>
							<div className="dropdown-content">
								<Link to="/Face_To_Face"> Face To Face </Link>
								<Link to="/Online_Visitation"> E-Dalaw </Link>
							</div>
						</div>
						{/* <button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button> */}
					</div>

					<div className="rightSide">
						<div className="dropdown">
							<div className="svg_hide">
								<button className="menus"><Link to="/AgencyLinks">Agency Links <FaAngleDown size="15" /></Link></button>
							</div>
							{/* <div className="hide"> */}
							<div className="dropdown-content" id="hide">
								<a href="http://r1.bjmp.gov.ph/" target="_blank"> Region 1 </a>
								<a href="http://bjmpr2.org/" target="_blank"> Region 2 </a>
								<a href="http://r3.bjmp.gov.ph/" target="_blank"> Region 3 </a>
								<a href="http://bjmpro4a.com/" target="_blank"> CALABARZON  </a>
								<a href="http://mimaropa.bjmp.gov.ph/" target="_blank"> MIMAROPA  </a>
								<a href="http://r5.bjmp.gov.ph/" target="_blank"> Region 5 </a>
								<a href="http://www.bjmpr6.com/" target="_blank"> Region 6 </a>
								<a href="http://r7.bjmp.gov.ph/" target="_blank"> Region 7 </a>
								<a href="http://www.bjmp8.com/" target="_blank"> Region 8 </a>
								<a href="http://bjmp9.info/" target="_blank"> Region 9 </a>
								<a href="http://r10.bjmp.gov.ph/" target="_blank"> Region 10 </a>
								<a href="http://r11.bjmp.gov.ph/" target="_blank"> Region 11 </a>
								<a href="http://r12.bjmp.gov.ph/" target="_blank"> Region 12 </a>
								<a href="https://www.bjmpcaraga.com/" target="_blank"> CARAGA  </a>
								<a href="http://car.bjmp.gov.ph/" target="_blank"> CAR  </a>
								<a href="http://ncr.bjmp.gov.ph/" target="_blank"> NCR  </a>
								<a href="http://barmm.bjmp.gov.ph/" target="_blank"> BARMM  </a>

							</div>
							{/* </div> */}
						</div>

						{/* <input className="search" type="text" placeholder="Search"/> */}
						{/* <button><img className="vis" src={Vis} /></button> */}
						<button className="menus"><div className="nav_HelpDesk"><NavLink activeclassname="active" to='/HelpDesk' > Inquiries </NavLink></div></button>
						{AuthBtn}
					</div>

				</nav>
				<button className="nav-btn" onClick={showNavbar} >
					<FaBars />
				</button>
			</header>
		</div>
	);
}

export default Navbar;
