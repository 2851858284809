import React, { useState } from 'react'
import HelpDesk from "../components/HelpDesk";
import '../styles/SignUp.css'
import Banner from "../assets/banner.png";
import { Link } from 'react-router-dom';
import Head_Banner from "../components/Banner";
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import FormInput from "../components/forms/FormInput";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ContactFooter from "../components/Con_Footer";
import * as IoIcons from 'react-icons/io';

function Register() {

    const navigate = useNavigate();

    const [form, setForm] = useState({
        lname: '',
        fname: '',
        mname: '',
        email: '',
        gender: '',
        country: '',
        province: '',
        city: '',
        barangay: '',
        houseNo: '',
        street: '',
        birthdate: '',
        contactNo: '',
        PdlName: '',
        PdlRelation: '',
        password: '',
        password_confirmation: '',
    })
    const [errorPass, setErrorPass] = useState('')
    const [image, setImage] = useState([]);
    const [errors, setErrors] = useState({})

    const containsAnyLetters = (str) => {
        return /[a-zA-Z]/.test(str);
    }
    const containsAnyNumbers = (str) => {
        return /[0-9]/.test(str);
    }
    const containsAnySpecial = (str) => {
        return /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/.test(str);
    }

    const setField = (field, value, e) => {
        setForm({
            ...form, [field]: value
        })

        if (e.target.name === 'password') {
            if (!(e.target.value.length > 7 && e.target.value.length < 21)) {
                setErrorPass('Password length should be 8-20 characters.')
            } else if (!containsAnyLetters(e.target.value)) {
                setErrorPass('Password does not contain a letter.')
            } else if (!containsAnyNumbers(e.target.value)) {
                setErrorPass('Password does not contain a number.')
            } else if (!containsAnySpecial(e.target.value)) {
                setErrorPass('Password does not contain a special characters.')
            } else {
                setErrorPass('')
            }
        }

        if (!!errors[field])
            setErrors({
                ...errors, [field]: null
            })
    }

    const handleImage = (e) => {
        e.persist();
        setImage({ imgId: e.target.files[0] });
    }

    const registerSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('imgId', image.imgId);
        formData.append('lname', form.lname);
        formData.append('fname', form.fname);
        formData.append('mname', form.mname);
        formData.append('email', form.email);
        formData.append('gender', form.gender);
        formData.append('country', form.country);
        formData.append('province', form.province);
        formData.append('city', form.city);
        formData.append('barangay', form.barangay);
        formData.append('houseNo', form.houseNo);
        formData.append('street', form.street);
        formData.append('birthdate', form.birthdate);
        formData.append('contactNo', form.contactNo);
        formData.append('pdl_no', form.PdlNum);
        formData.append('PdlName', form.PdlName);
        formData.append('PdlRelation', form.PdlRelation);
        formData.append('password', form.password);
        formData.append('password_confirmation', form.password_confirmation);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/user/register`, formData).then(res => {
                if (res.data.success) {
                    swal("Success", res.data.msg, "success");
                    navigate('/');
                }
                else {
                    const newErrors = res.data.msg
                    swal("Warning", "Please check the forms again.", "warning");
                    setErrors(newErrors)
                }
            });
        });

    }

    const [showPass, setShowPass] = useState(false)
    const [showConPass, setShowConPass] = useState(false)
    const handleShow = () => {
      setShowPass(!showPass)
    }
    const handleShowConPass = () => {
      setShowConPass(!showConPass)
    }

    return (
        <div>
            <Navbar />
            <div className='Banner'><Head_Banner /></div>
            <div className='SignUp_Body' style={{ marginTop: '150px' }}>

                <div className='SignUp_title'><h2>Sign Up</h2></div>
                <div className='SignUp_Row'>
                    <div className='SignUp_left'>
                        <div className='SignUp_Intro'>
                            <p className='SignUp_SubTitle'>Get started with your account</p>
                            <p className='SignUp_sub'>Every journey begins with a single step, start yours by creating an account. </p>
                        </div>
                        <form onSubmit={registerSubmit}>
                            <div className='SignUp_Form'>
                                {/* <label className='item'> Full Name <span style={{ color: "red" }}>*</span></label> */}
                                <div className='Sign_Up_Name1'>
                                    <div className='SignUp_FullName1'>
                                        <p className='item'>Last Name <span style={{ color: "red" }}>*</span></p>
                                        <FormInput
                                            type="text"
                                            className="SignUp_LName"
                                            name='lname'
                                            onChange={e => setField('lname', e.target.value)}
                                            value={form.lname}
                                            placeholder='Last Name'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='SignUp_FullName1'>
                                        <p className='item'>First Name <span style={{ color: "red" }}>*</span></p>
                                        <FormInput
                                            type="text"
                                            className="SignUp_FName"
                                            name='fname'
                                            onChange={e => setField('fname', e.target.value)}
                                            value={form.fname}
                                            placeholder='First Name'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='SignUp_FullName1'>
                                        <p className='item'>Middle Name </p>
                                        <FormInput
                                            type="text"
                                            className="SignUp_MName"
                                            name='mname'
                                            placeholder='Middle Name '
                                            onChange={e => setField('mname', e.target.value)}
                                            value={form.mname === null ? '' : form.mname}
                                        />
                                    </div>
                                </div>

                                <div className='SignUp_Birth_Contact'>
                                    <div className='SignUp_PDL_Name'>
                                        <label className='item'> Email: <span style={{ color: "red" }}>*</span> </label>
                                        <FormInput
                                            type="email"
                                            className="SignUp_Pass_"
                                            name='email'
                                            onChange={e => setField('email', e.target.value)}
                                            value={form.email}
                                            placeholder='Email Address'
                                            required
                                            errorMessage="Input a valid email address."
                                        />
                                        <span className='errorMsg'>{errors.email}</span>
                                    </div>
                                    <div className='SignUp_PDL_Relation'>
                                        <label className="item">Gender: <span style={{ color: "red" }}>*</span></label>
                                        <select className='right' name="gender" required style={{ fontSize: '15px' }} value={form.gender} onChange={e => setField('gender', e.target.value)}>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male/Lalaki</option>
                                            <option value="Female">Female/Babae</option>
                                        </select>
                                        <span className='errorMsg'>{errors.gender}</span>
                                    </div>
                                </div>

                                <div className='SignUp_Address1'>
                                    <label className='SignUp_add_title'>Complete Address <span style={{ color: "red" }}>*</span> </label>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_province"
                                            name='country'
                                            onChange={e => setField('country', e.target.value)}
                                            value={form.country}
                                            placeholder='Country'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_province"
                                            name='province'
                                            onChange={e => setField('province', e.target.value)}
                                            value={form.province}
                                            placeholder='Province'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_province"
                                            name='city'
                                            onChange={e => setField('city', e.target.value)}
                                            value={form.city}
                                            placeholder='City'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_barangay"
                                            name='barangay'
                                            onChange={e => setField('barangay', e.target.value)}
                                            value={form.barangay}
                                            placeholder='Barangay'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_house_num"
                                            name='houseNo'
                                            onChange={e => setField('houseNo', e.target.value)}
                                            value={form.houseNo}
                                            placeholder='House No./Apt.No/Floor'
                                        />
                                    </div>
                                    <div className='Address_Wrap'>
                                        <FormInput
                                            type="text"
                                            className="SignUp_street"
                                            name='street'
                                            onChange={e => setField('street', e.target.value)}
                                            value={form.street}
                                            placeholder='Street Name/Sitio/Purok'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                </div>


                                <div className='SignUp_Birth_Contact'>
                                    <div className='SignUp_birth'>
                                        <label className='birth_title'>Birthdate <span style={{ color: "red" }}>*</span> </label>
                                        <input className='birthday' type="date" name="birthdate" style={{ fontSize: '15px' }} onChange={e => setField('birthdate', e.target.value)} value={form.birthdate} placeholder='Select Date' required ></input>
                                        <span className='errorMsg'>{errors.birthdate}</span>
                                    </div>
                                    <div className='SignUp_contact'>
                                        <label className='contact_title'>Contact No. <span style={{ color: "red" }}>*</span> </label>
                                        <FormInput
                                            type="tel"
                                            className="con_num"
                                            name='contactNo'
                                            onChange={e => setField('contactNo', e.target.value)}
                                            value={form.contactNo}
                                            placeholder='Contact Number - 09xx'
                                            pattern='[0-9]{11}'
                                            required
                                            errorMessage="Phone number format is invalid."
                                        />
                                        <span className='errorMsg'>{errors.contactNo}</span>
                                    </div>
                                </div>

                                <div className='PDL_Name_Relation'>
                                    <div className='SignUp_PDL_Num'>
                                        <label className="SignUp_pdl_num">PDL #: </label>
                                        <FormInput
                                            type="text"
                                            className="pdl_num"
                                            name='PdlNum'
                                            onChange={e => setField('PdlNum', e.target.value)}
                                            value={form.PdlNum}
                                            placeholder='PDL Number'
                                        />
                                    </div>
                                    <div className='SignUp_PDL_Name'>
                                        <label className="SignUp_pdl_name">Enter PDL Name: <span style={{ color: "red" }}>*</span></label>
                                        <FormInput
                                            type="text"
                                            className="pdl_name"
                                            name='PdlName'
                                            onChange={e => setField('PdlName', e.target.value)}
                                            value={form.PdlName}
                                            placeholder='Enter PDL Name'
                                            required
                                            errorMessage="This field is required."
                                        />
                                    </div>
                                    <div className='SignUp_PDL_Relation'>
                                        <label className="pdl_relation">Enter PDL Relation: <span style={{ color: "red" }}>*</span></label>
                                        <FormInput
                                            type="text"
                                            className="pdl_relation"
                                            name='PdlRelation'
                                            onChange={e => setField('PdlRelation', e.target.value)}
                                            value={form.PdlRelation}
                                            placeholder='Enter Relationship to the PDL'
                                            required
                                            errorMessage="This field is required."
                                        />
                                        <span className='errorMsg'>{errors.PdlRelation}</span>
                                    </div>
                                </div>

                                <div className='PDL_Name_Relation'>
                                    <div className='SignUp_PDL_Name' style={{ height: 'fit-content' }}>
                                        <label className="SignUp_pdl_name">Password <span style={{ color: "red" }}>*</span></label>
                                        <div style={{position:'relative'}}>
                                            <label className='loginShowPass' style={{right:'7%', paddingTop:'5px'}} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff/> : <IoIcons.IoMdEye/>}</label>
                                        </div>
                                        <FormInput
                                            type={showPass ? 'text' : 'password'}
                                            className="SignUp_Pass_"
                                            name='password'
                                            onChange={e => setField('password', e.target.value, e)}
                                            value={form.password}
                                            placeholder='Enter Password'
                                            pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$'
                                            required
                                            errorMessage={errorPass}
                                        // errorMessage="Password should be atleast 8-20 characters and include at least 1 letter, 1 number and 1 special character."
                                        />
                                        <span style={{ color: 'gray', fontSize: '12px', width: '95%' }}>Password should be atleast 8-20 characters and include at least 1 letter, 1 number and 1 special character.</span>
                                    </div>
                                    <div className='SignUp_PDL_Relation' style={{ height: 'fit-content' }}>
                                        <label className="SignUp_pdl_name">Confirm Password <span style={{ color: "red" }}>*</span></label>
                                        <div style={{position:'relative'}}>
                                            <label className='loginShowPass' style={{right:'7%', paddingTop:'5px'}} onClick={handleShowConPass}>{showConPass ? <IoIcons.IoMdEyeOff/> : <IoIcons.IoMdEye/>}</label>
                                        </div>
                                        <FormInput
                                            type={showConPass ? 'text' : 'password'}
                                            className="SignUp_Pass_"
                                            name='password_confirmation'
                                            onChange={e => setField('password_confirmation', e.target.value)}
                                            value={form.password_confirmation}
                                            placeholder='Confirm Password'
                                            pattern={form.password}
                                            required
                                            errorMessage="Passwords don't match!"
                                        />
                                    </div>
                                </div>

                                <div className='SignUp_Id_Upload'>
                                    <label className='SignUp_Upload_Label'>Upload Valid ID <span style={{ color: "red" }}>*</span></label>
                                    <div className='SignUp_Input_File'>
                                        <input className='SignUp_Upload' type="file" id="myFile" name="imgId" onChange={handleImage} />
                                        <span className='errorMsg'>{errors.imgId}</span>
                                    </div>
                                    <div className='Upload_Note'>Note: The purpose of IDs is to verify the user's identification</div>
                                </div>
                            </div>
                            <div className='SignUp_PDL_Relation' style={{ height: 'fit-content', textAlign: 'left',width:'100%', marginTop:'10px' }}>
                                {/* <Link to='https://www.termsfeed.com/live/a471a290-c269-4c7d-8a2f-5b48c565cebb' /> */}
                                <a href="https://www.termsfeed.com/live/a471a290-c269-4c7d-8a2f-5b48c565cebb" target="_blank" style={{ fontSize: '14px',color:'gray' }}>Privacy Policy for BJMP Baliwag</a>
                            </div>
                            <button className='SignUp_Button' type="submit">Register</button>
                        </form>
                    </div>


                    <div className='SignUp_right'>
                        <label className='Signup_Login_Title'>Already Have An Account?</label>
                        <p className='Signup_Login_SubTitle'>Sign in to view other features</p>
                        <button className='Signup_Login_button'><Link to="/Login"> Login</Link></button>
                    </div>


                </div>

                <div><HelpDesk /></div>
            </div >
            <div><ContactFooter /></div>
            <div><Footer /></div>
        </div>
    )
}

export default Register 