import React, { useEffect, useState } from 'react'
import "../../css/Admin_PDL_Management.css"
import { FaBars, FaAngleDown } from "react-icons/fa";
import styled from 'styled-components';
import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios';
import Default from '../../../../assets/default_user.png'
import Pagination from '../../../../components/Pagination/Pagination';
import ShowEntry from '../../../../components/Pagination/ShowEntry';
import url from '../../../../hooks/url';
import UserIdModal from '../../components/Modal/UserIdModal';
import swal from 'sweetalert';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';
import ExportPdlList from '../../components/Export/ExportPdlList';
import loadingUI from '../../../../hooks/loadingUI';
import { Link } from 'react-router-dom';

const Styles = styled.div`
height: 40px;
background: rgb(63,177,251);
background: radial-gradient(circle, rgba(63,177,251,1) 0%, rgba(70,74,252,1) 100%);
display:flex;
justify-content:center;
align-items: center;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 28px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_PDL_Info() {

  const [pdls, setPdls] = useState([])
  const [show, setShow] = useState(false)
  const [pdl, setPdl] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const port = url()

  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  //release button
  const [pdlId, setPdlId] = useState('')
  const [isRelease, setIsRelease] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    try {
      setLoadingPage(true)
      axios.get(`/api/admin/pdl/getAll`).then(res => {
        setPdls(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleRelease = () => {
    setLoading(true)
    axios.post(`/api/admin/pdl/archive/${pdlId}`).then(res => {
      setLoading(false)
      swal("Success", res.data.msg, "success");
      setTimeout((_) => window.location.assign("/Admin_PDL_Info"), 1000);
    });
  }

  const handleDeceased = () => {
    setLoading(true)
    axios.post(`/api/admin/pdl/deceased/${pdlId}`).then(res => {
      setLoading(false)
      swal("Success", res.data.msg, "success");
      setTimeout((_) => window.location.assign("/Admin_PDL_Info"), 1000);
    });
  }

  const agefromBday = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
  const [search, setSearch] = useState('')

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportPdlList data={pdls} filename='PDLInfo.csv' />
          {isRelease ? (
            <ModalConfirmation title="Release PDL" body="Are you sure you want to release this PDL?" setShow={setShowModal} show={showModal} yesFunc={handleRelease} loading={loading} />
          ) : (
            <ModalConfirmation title="Deceased PDL" body="Are you sure you want to continue this request?" setShow={setShowModal} show={showModal} yesFunc={handleDeceased} loading={loading} />
          )}
          <div className='pdl_info_container'>
            <div className='Admin_Users_Rep_Con'>
              <div className='Users_Report_Content'>
                <div className='UR_List' style={{ padding: '10px', position: 'relative' }}>
                  <Styles>
                    <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                      <p className='pageHeader'>PDL</p>
                    </div>
                  </Styles>
                  {/* <div className='Acc_Info'>PDL</div> */}
                  <div className='searchDiv' style={{ float: 'right', marginRight: '10px', marginTop: '45px' }}>
                    <input className='searchInput' type='text' placeholder='Search for pdl # or first name' onChange={(e) => setSearch(e.target.value)} />
                    <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                  </div>
                  <div style={{ overflowY: 'scroll', maxHeight: '450px', width: '100%' }}>
                    <table className='styled-table marginContent' style={{ marginTop: '0px' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>PDL #</th>
                          <th style={{ textAlign: "center" }}>Name</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdls && pdls.filter((val) => {
                          if (search == '') {
                            return val
                          } else if (val.fname.toLowerCase().includes(search.toLowerCase()) || val.pdl_no.toString().includes(search.toLowerCase())) {
                            return val
                          }
                        }).slice(start, end).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.pdl_no}</td>
                              <td>{`${item.fname} ${item.lname}`}</td>
                              <td className='actionstatus'>
                                <button className='detailsbtn' onClick={() => { setShow(true); setPdl(item) }}>Details</button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    data={pdls}
                    itemsPerPage={itemsPerPage}
                    currrentPage={currrentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>

                <div className='UR_Details'>
                  <div className='Acc_Info'>PDL INFORMATION</div>
                  {show ? (
                    <div className='Acc_Display'>
                      <div className='Main_Details'>
                        <img className='Acc_Pic' src={`${port}${pdl.img}`} />
                        {/* <div className='Acc_Pic'></div> */}
                        <div className='Main_Label_Con'>

                          <div className='Label_Con'>
                            <label className='Label_Name'>Name: </label>
                            {pdl.mname === null ? (
                              <label className='Acc_Dis_Name'>{`${pdl.fname} ${pdl.lname}`}</label>
                            ) : (
                              <label className='Acc_Dis_Name'>{`${pdl.fname} ${pdl.mname} ${pdl.lname}`}</label>
                            )}
                            {/* <label className='Acc_Dis_Name'>{`${pdl.fname} ${pdl.lname}`}</label> */}
                          </div>

                          {/* pdl number */}
                          <div className='Label_Con'>
                            <label className='Label_Email'>PDL Number: </label>
                            <label className='Acc_Dis_Email'>{pdl.pdl_no}</label>
                          </div>

                          {/* cases */}
                          <div className='Label_Con'>
                            <label className='Label_Phone'>Gender: </label>
                            <label className='Acc_Dis_Phone'>{pdl.gender}</label>
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <button className='viewMugshotbtn' onClick={() => { setOpenModal(true); setItem({ img: pdl.img }) }}>View Front Mugshot</button>
                          {pdl.right_side && <button className='viewMugshotbtn' onClick={() => { setOpenModal(true); setItem({ img: pdl.right_side }) }}>View Right Mugshot</button>}
                          {pdl.left_side && <button className='viewMugshotbtn' onClick={() => { setOpenModal(true); setItem({ img: pdl.left_side }) }}>View Left Mugshot</button>}
                        </div>
                        {/* <div className='Dis_Menu'> <FaBars /></div> */}
                      </div>
                      <div className='rem_Details'>
                        <div className='pt_one'>
                          <div className='Label_Add_Con'>
                            <label className='Label'>Address: </label>
                            {pdl.houseNo ? (
                              <label className='Acc_Dis_Add'>{`${pdl.houseNo} ${pdl.street}, ${pdl.barangay}, ${pdl.city}, ${pdl.province}, ${pdl.country}`}</label>
                            ) : (
                              <label className='Acc_Dis_Add'>{`${pdl.street}, ${pdl.barangay}, ${pdl.city}, ${pdl.province}, ${pdl.country}`}</label>
                            )}
                          </div>

                          <div className='Label_Add_Con'>
                            <label className='Label'>Age:  </label>
                            <label className='Acc_Dis_Gen'>{agefromBday(pdl.birthdate)}</label>
                          </div>

                          <div className='Label_Add_Con'>
                            <label className='Label'>Cases:  </label>
                            <label className='Acc_Dis_Gen'>{pdl.cases}</label>
                          </div>

                          {pdl.contactPerson && (
                            <div className='Label_Add_Con'>
                              <label className='Label'>Contact Person:  </label>
                              <label className='Acc_Dis_Gen'>{pdl.contactPerson}</label>
                            </div>
                          )}

                          {pdl.contactNumber && (
                            <div className='Label_Add_Con'>
                              <label className='Label'>Contact Number of Contact Person:  </label>
                              <label className='Acc_Dis_Gen'>{pdl.contactNumber}</label>
                            </div>
                          )}

                          <div className='pending_users_btn'>
                            <button className='detailsbtn' style={{ width: '100px' }} onClick={() => { setIsRelease(true); setShowModal(true); setPdlId(pdl.id) }}>Release</button>
                            <button className='cancelbtn' style={{ width: '100px' }} onClick={() => { setIsRelease(false); setShowModal(true); setPdlId(pdl.id) }}>Deceased</button>
                          </div>

                          <div className='pending_users_btn'>
                            <button className='detailsbtn' style={{ width: '150px' }}><Link to="/Admin_Edit_PDL" state={pdl}>Edit PDL</Link></button>
                          </div>

                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openModal && <UserIdModal closeModal={setOpenModal} data={item} port={port} />}
    </div>
  )
}

export default Admin_PDL_Info