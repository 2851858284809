import React from 'react'
import dateFormat from './dateFormat'

function compareDate(requestVisits, selectedDate, itemValue) {
  let isSelected = false;
  let ctr = 4;
  let startTime = itemValue.split(',')
  requestVisits.map((item) => {
    if(item.status === 'Pending' || item.status === 'Accepted'){
        if(dateFormat(item.date) == dateFormat(selectedDate)){
          if(startTime[0] === item.start_time) {
            // isSelected = true
            ctr -= 1;
            if(ctr === 0){
                isSelected = true
            }
          }
          //startTime[0] === item.start_time ? isSelected = true : isSelected = false
        }
      }
  })
  
  return isSelected
}

export default compareDate
