import React from 'react'
import url from '../hooks/url'

function NewsItem( {id, name, date, image, body, dateUp }) {
  const port = url()
  let str = body.substring(0, 400) + '...';
  // const port1 = 'http://localhost:8000/'
  return (
    <div className='newsItem' onClick={() => window.location.assign(`/Post/${id}`)}>
        <h1> {name}</h1>
        <p>{date === null ? dateUp : date}</p>
        {image && (
          <div style={{height:'50vh'}}>
            <div 
            className='newsImage'
            style={{
              backgroundImage: `url(${port}${image})`
            }}>
            </div>
          </div>
        )}
        <div dangerouslySetInnerHTML={{__html: str}} style={{overflow: 'hidden', height:'142px'}}></div>
        <p style={{marginTop:'20px'}}>{'Date Uploaded: ' + dateUp}</p>
    </div>
  )
}

export default NewsItem