import React from 'react'
import Head_Banner from "../components/Banner";
import "../styles/FTF.css"
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactFooter from "../components/Con_Footer";

function FaceToFace_NotIn() {
  return (
    <div>
      <Navbar />
      <div className='Censor'>
        <div className='Warn'><Head_Banner /></div>
        <div className='Place'>
          {/* <div className='Warn_Pic'><Link to="/Login"></Link></div> */}
          <Link to="/Login" className='Warn_Pic'>
            <div></div>
          </Link>
        </div>
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  )
}



export default FaceToFace_NotIn