import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import { userChats } from '../../api/ChatRequest'
import CurrentUser from '../../auth/CurrentUser'
import Avatar from './Avatar'
//import ChatBox from './Chatbox/ChatBox'
import ChatBox from '../../components/Chatbox/ChatBox'
import { styles } from './styles'

const SupportEngine = (props) => {
    const ref = useRef(null)
    const [visible, setVisible] = useState(false)
    const [user, setUser] = useState([])
    const socket = useRef()
    const [chat, setChat] = useState({})
    const [isNotNull, setIsNotNull] = useState(false)
    const [chatNotNull, setChatNotNull] = useState(false)
    const [sendMessages, setSendMessages] = useState(null);
    const [receiveMessages, setReceiveMessages] = useState(null);

    //socket
    useEffect(() => {
        //socket.current = io.connect('http://localhost:8800')
        socket.current = io.connect('https://bjmp-socket.onrender.com')
        //socket.current = io.connect('https://try-chat-bjmp.herokuapp.com')
        if (CurrentUser() !== 'admin') {
            socket.current.emit("new-user-add", user.id)
        } else {
            if (user === 'admin')
                socket.current.emit("new-user-add", user)
        }
    }, [user])

    useEffect(() => {
        function handleClickOutside(e) {
            if (ref.current && !ref.current.contains(e.target)) {
                setVisible(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        };
    }, [ref])

    //get chat
    useEffect(() => {
        //get chats applicable only to admin?
        const getChats = async () => {
            try {
                const { data } = await userChats(user.id)
                setChat(data.data)
                setChatNotNull(true)
                //setLoading(false)

            } catch (error) {
                console.log(error)
            }
        }
        getChats()
    }, [user])

    // useEffect(() => {
    //     if (sendMessages !== null) {
    //         socket.current.emit("send_message", sendMessages)
    //     }
    // }, [sendMessages])

    // useEffect(() => {
    //     socket.current.on("receive_message", (data) => {
    //         console.log(data)
    //         setReceiveMessages(data)
    //     })
    // }, [socket])

    //get user
    useEffect(() => {
        //if admin id not needed set the user state to 'admin'
        if (CurrentUser() !== 'admin') {
            axios.get('/api/user/data').then(res => {
                setUser(res.data.data);
                setIsNotNull(true)
                // setLoading(false)
            })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setUser('admin');
        }
    }, [])


    const joinRoom = (chat) => {
        setVisible(true)
        if (user.id !== 'admin' && chat[0].id !== undefined) {
            socket.current.emit("join_room", chat[0].id)
        }
    }


    return (
        <div ref={ref}>
            <div
                className='transition-5 chatWindow '
                style={{
                    ...styles.supportWindow,
                    // ...{ opacity: visible ? '1' : '0' }
                    ...{display: visible ? 'block' : 'none'}
                }}>
                {visible ? (
                    <ChatBox
                        visible={visible}
                        socket={socket}
                        currentUser={CurrentUser() === 'admin' ? 'admin' : user.id}
                        chat={chat[0]}
                        setSendMessages={setSendMessages}
                        receiveMessages={receiveMessages}
                    />
                ) : <></>}
            </div>
            {/* <SupportWindow
                visible={visible}
            /> */}
            {/* <ChatBox 
                visible={visible}
                socket={socket}
                currentUser={CurrentUser() === 'admin' ? 'admin' : user.id}
                chat={chat[0]}
            /> */}

            {isNotNull && chatNotNull ? (
                <Avatar
                    //onClick={() => setVisible(true)}
                    onClick={() => joinRoom(chat)}
                    style={{
                        position: "fixed",
                        bottom: '24px',
                        right: '24px'
                    }}
                />
            ) : <></>}
        </div>
    )
}


export default SupportEngine