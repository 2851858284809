import React from "react";
import './LogoSearch.css'
const LogoSearch = (props) => {
  return (
    <div className="LogoSearch">
      <div className="Search">
        <input type="text" placeholder="Search User First Name" onChange={(e)=>props.setSearchUser(e.target.value)}/>
      </div>
    </div>
  );
};

export default LogoSearch;