import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import FacetoFace from '../FacetoFace'
import './Qr.css'

function Qr({ data, isPressed }) {
    const [qrcode, setQrcode] = useState('')
    const stringData = `${data.fname} ${data.lname} 
                        \nScheduled on \nDate: ${data.date} 
                        \nTime: ${data.start_time} - ${data.end_time} 
                        \nEmail: ${data.email} 
                        \nVisiting ${data.pdl_name}`
    useEffect(() => {
        GenerateQRCode()
    }, [data])
    const GenerateQRCode = () => {
        QRCode.toDataURL(stringData, {
            width: 800,
            margin: 2,
            color: {
                dark: '#00008888',
                light: '#ffffffff'
            }
        }, (err, stringData) => {
            if (err) return console.error(err)

            setQrcode(stringData)
        })
    }

    return (
        <div className="app_qr">
            <div className='qr_wrapper'>
                {isPressed ? (
                    <>
                        <h1 className='qr_h1'>BJMP QR Code Generator</h1>
                        {qrcode && <>
                            <img src={qrcode} className='qr_img' />
                            {console.log(isPressed)}
                            <a className='download' href={qrcode} download="BJMP_Face-To-Face QR Code.png">Download</a>
                            <p className='qr_text'> Note: DOWNLOAD & PRESENT this when entering BJMP Facility </p>
                            <button className='backbtn' onClick={e => {
                                isPressed = false
                                window.location.reload()
                            }}>Back</button>
                        </>}
                    </>
                ) : (<FacetoFace />)}
            </div>
        </div>
    )
}

export default Qr
