import React from 'react'
import dateFormat from '../../../../hooks/dateFormat'
import './detailsModal.css'

const AdminDetails = ({closeModal, data}) => {
    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Admin Details</p>
                </div>
                <div className='modalBody'>
                    {data.fname && (<span>First Name: {data.fname}</span>)}
                    {data.mname && (<span>Middle Name: {data.mname}</span>)}
                    {data.lname && (<span>Last Name: {data.lname}</span>)}
                    {data.gender && (<span>Gender: {data.gender}</span>)}
                    {data.birthdate && (<span>Birthday: {dateFormat(data.birthdate, 'long')}</span>)}
                    {data.contactNumber && (<span>Contact Number: {data.contactNumber}</span>)}
                    {data.email && (<span>Email: {data.email}</span>)}
                    {data.houseNo && (<span>House Number: {data.houseNo}</span>)}
                    {data.street && (<span>Street: {data.street}</span>)}
                    {data.barangay && (<span>Barangay: {data.barangay}</span>)}
                    {data.city && (<span>City: {data.city}</span>)}
                    {data.province && (<span>Province: {data.province}</span>)}
                    {data.country && (<span>Country: {data.country}</span>)}
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' onClick={() => closeModal(false)}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default AdminDetails