import React, { useEffect, useState } from 'react'
import "../../css/Admin_Content_Management.css"
import "../../css/table.css"
import styled from 'styled-components';
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import Pagination from '../../../../components/Pagination/Pagination';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import ShowEntry from '../../../../components/Pagination/ShowEntry';
import loadingUI from '../../../../hooks/loadingUI';
import ExportPost from '../../components/Export/ExportPost';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

const Container = styled.div`
margin-top: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
position: fixed !important;
left: 22%;
right: 2%;
border-radius: 25px;
font-size: 24px;
background: white;
height: 60%;
overflow: auto;
`

const Admin_Archive_Post = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)

    useEffect(() => {
        getPosts()
    }, [])

    const getPosts = () => {
        try {
            setLoadingPage(true)
            axios.get(`/api/admin/posts/archive`).then(res => {
                setPosts(res.data.data)
                setLoadingPage(false)
            });
        } catch (error) {
            console.log(error)
        }
    }

    //modal
    const [show, setShow] = useState(false)
    const [itemId, setItemId] = useState("")

    const handleRestore = (id) => {
        setShow(true)
        setItemId(id)
    }

    const handleRestoreItem = () => {
        setLoading(true)
        axios.post(`/api/admin/posts/archive/${itemId}`).then(res => {
            setLoading(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Admin_Archive_Post"), 1000);
        });
    }

    //test
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currrentPage, setCurrentPage] = useState(1);

    const end = currrentPage * itemsPerPage;
    const start = end - itemsPerPage;

    return (
        <div className='all_body'>
            <div><Top_Bar /></div>
            <div className='ad_nav_size'><Admin_Navbar /></div>
            {loadingPage ? loadingUI(loadingPage) : (
                <div className='Admin_Add_Post_Main'>
                    <div><SQB /></div>
                    <ExportPost data={posts} filename='ArchivedPosts.csv' />
                    <ModalConfirmation title="Restore Post" body="Are you sure you want to restore this post?" setShow={setShow} show={show} yesFunc={handleRestoreItem} loading={loading} />
                    <div className='Manage_Post_Con'>
                        <Styles>
                            <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                <p className='pageHeader'>MANAGE POST</p>
                                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                            </div>
                        </Styles>
                        <table className='styled-table marginContent'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>No.</th>
                                    <th style={{ textAlign: "center" }}>Post Type</th>
                                    <th style={{ textAlign: "center" }}>Title</th>
                                    <th style={{ textAlign: "center" }}>Date Posted</th>
                                    <th style={{ textAlign: "center" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts && posts.slice(start, end).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope='row'>{index + 1}</th>
                                            <td>{item.post_type}</td>
                                            <td>{item.title}</td>
                                            <td>{dateFormat(item.created_at, 'long')}</td>
                                            <td className='actionstatus'>
                                                <button className='acceptbtn' onClick={() => handleRestore(item.id)}>Retrieve</button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            data={posts}
                            itemsPerPage={itemsPerPage}
                            currrentPage={currrentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Admin_Archive_Post