import React, { useState, useEffect } from 'react'
import "../../css/Admin_GeoTag_Info.css"
import styled from 'styled-components'
import axios from 'axios'
import MapGeoTag from '../../components/GeoTag/MapGeoTag'
import GeoModal from '../../components/Modal/GeoModal'

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import loadingUI from '../../../../hooks/loadingUI'


const TableStyle = styled.div`
padding-left: 0px;
height: 600px;
overflow-y: scroll;
overflow-x: hidden;
padding: 10px;
`

const CardsContainer = styled.div`
margin-top: 140px;
`

const GeoMapWrapper = styled.div`
width: 60%;
height:650px;
padding: 10px;
`

const GeoMapContainer = styled.div`
margin-top:20px;
display:flex;
justify-content:space-around;
`

const Header = styled.div`
width:100%;
background: #054068;
margin:0;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`


function GeoTag_UserInfo() {

  const [markers, setMarkers] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const type = 'user'
  useEffect(() => {
    try {
      setLoadingPage(true)
      axios.get('/api/user/geoTag/getAllPin').then(res => {
        setMarkers(res.data.data)
        setLoadingPage(false)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});


  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          {/* <div><SQB /></div> */}
          <div className='Admin_GeoTag_Info'>
            <Header><p className='geoPdlHeader'>Geotagging of Users</p></Header>
            <GeoMapContainer>
              <GeoMapWrapper>
                <MapGeoTag markers={markers} type={type} />
              </GeoMapWrapper>
              <TableStyle>
                <table className='styled-table-map'>
                  <thead>
                    <tr style={{ top: '0' }}>
                      <th style={{ textAlign: "center" }}>Barangay</th>
                      <th style={{ textAlign: "center" }}>No. of Users</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {markers && markers.map((item, index) => {
                      return (
                        <tr key={index} className={index % 2 == 0 ? "odd" : "even"}>
                          <td>{item.barangay}</td>
                          <td>{item.user_count}</td>
                          <td><button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </TableStyle>
            </GeoMapContainer>
            <button className='add_admin_submit' style={{ marginBottom: '20px', float: 'right', marginRight: '30px', opacity: 1 }} onClick={() => window.location.assign("/Admin_GeoTag_Management")}>PDL Geo Tag</button>
          </div>
        </div>
      )}
      {openModal && <GeoModal closeModal={setOpenModal} data={item} type={type} />}
    </div>
  )
}

export default GeoTag_UserInfo