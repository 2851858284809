import React, { useEffect, useState } from 'react'
import "../styles/cont_foot.css"
import { FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import axios from 'axios';

function Con_Footer() {
  const [contact, setContact] = useState([])
  useEffect(() => {
    let cancel
    try {
      axios.get(`/api/admin/contact/get`, {
        cancelToken: new axios.CancelToken((c) => cancel = c),
      }).then(res => {
        setContact(res.data.data[0])
      }).catch((e) => {
        if (axios.isCancel(e)) return console.log("Request Cancelled")
      });
    } catch (error) {
      console.log(error)
    }

    return () => {
      if (cancel) {
        cancel()
      }
    }
  }, [])

  return (
    <div className='con_foot_container'>
      <div className='contact_details'>
        <label className='con_title'>Contact us</label>
        <div className='cont_email'>
          <label className='iden'>Email: </label>
          {contact && (<label className='iden_info'>{contact.email}</label>)}
        </div>
        <div className='cont_trunk'>
          <label className='iden'>Trunkline: </label>
          {contact && (<label className='iden_info'>{contact.trunkline}</label>)}
        </div>
        <div className='cont_address'>
          <label className='iden'>Address: </label>
          {contact && (<label className='iden_info'>{contact.address}</label>)}
        </div>
        <div className='cont_phone'>
          <label className='iden'>Phone: </label>
          {contact && (<label className='iden_info'>{contact.phone}</label>)}
        </div>
        <label className='iden'>© 2010 BJMP All Rights Reserved</label>
        <div>
          <a href="https://www.facebook.com/baliwagbjmp" target='_blank'><FacebookIcon size={40} ></FacebookIcon></a>
        </div>
      </div>
    </div>
  )
}

export default Con_Footer