import React, { useEffect, useState } from 'react'
import "../../css/Admin_Report_Management.css"
import styled from 'styled-components';
import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios';
import Default from '../../../../assets/default_user.png'
import url from "../../../../hooks/url";
import Pagination from '../../../../components/Pagination/Pagination';
import UserIdModal from '../../components/Modal/UserIdModal';
import { ClipLoader } from 'react-spinners';
import swal from 'sweetalert';
import DeclineVerification from '../../components/Modal/DeclineVerification';
import UsersReport from '../../components/Export/UsersReport';
import loadingUI from '../../../../hooks/loadingUI';
import emailjs from 'emailjs-com'

const Styles = styled.div`
height: 40px;
background: rgb(63,177,251);
background: radial-gradient(circle, rgba(63,177,251,1) 0%, rgba(70,74,252,1) 100%);
display:flex;
justify-content:center;
align-items: center;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 24px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_Pending_Users() {
  const [users, setUsers] = useState([])
  const [show, setShow] = useState(false)
  const [user, setUser] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const port = url()

  useEffect(() => {
    setLoadingPage(true)
    getUserNv()
    getPdl()
  }, [])

  const getUserNv = () => {
    try {
      axios.get(`/api/admin/user/getNotVerified`).then(res => {
        setUsers(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const [pdls, setPdls] = useState([])
  const [pdl, setPdl] = useState([])

  const getPdl = () => {
    try {
      axios.get(`/api/admin/pdl/getAll`).then(res => {
        setPdls(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const [itemsPerPagePdl, setItemsPerPagePdl] = useState(5);
  const [currrentPagePdl, setCurrentPagePdl] = useState(1);

  const endPdl = currrentPagePdl * itemsPerPagePdl;
  const startPdl = endPdl - itemsPerPagePdl;


  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const [loading, setLoading] = useState(false)
  const [loadingDec, setLoadingDec] = useState(false)

  //decline modal
  const [openDeclineModal, setOpenDeclineModal] = useState(false)
  const [declineId, setDeclineId] = useState([])

  const handleVerify = ($item) => {
    setLoading(true)
    let emailTemp = {
      header:`Your account has been verified!`,
      header_body: `Greetings! We would like to inform you that your account request has been approved! You can now use this email and the password you entered to further access BJMP Baliwag's website features.`,
      email: $item.email
    }
    axios.post(`/api/admin/user/verify/${$item.id}`).then(res => {
      emailjs.send('service_bqoeool', 'template_flrmaaf', emailTemp, '3EVvUb1f8h3EPOnhU').then(response => {
        swal("Success", res.data.msg, "success");
        setLoading(false)
        setTimeout((_) => window.location.assign('/Admin_Pending_Users'), 1000);
      }).catch(err => {
        console.log(err)
        swal("Error", "Please try again!", "error");
        setLoading(false)
      });
      // setLoading(false)
      // swal("Success", res.data.msg, "success");
      // setTimeout((_) => window.location.assign("/Admin_Pending_Users"), 1000);
    });
  }

  const [searchUser, setSearchUser] = useState('')
  const [searchPdl, setSearchPdl] = useState('')
  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <UsersReport data={users} filename='UnregisteredUsers.csv' />
          <div className='Admin_Users_Rep_Con'>
            <div className='Users_Report_Content'>
              <div className='UR_List' style={{ width: '30%', padding: '10px', position: 'relative' }}>
                <Styles>
                  <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <p className='pageHeader'>UNREGISTERED USERS</p>
                  </div>
                </Styles>
                <div className='searchDiv' style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}>
                  <input id='unregisteredSearch' style={{width: '100%'}} className='searchInput' type='text' placeholder='Search email or first name' onChange={(e) => setSearchUser(e.target.value)} />
                </div>
                <div style={{ overflowY: 'scroll', maxHeight: '390px', width: '100%' }}>
                  <table className='styled-table marginContent' style={{ marginTop: '0' }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Name</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users && users.filter((val) => {
                        if (searchUser == '') {
                          return val
                        } else if (val.fname.toLowerCase().includes(searchUser.toLowerCase()) || val.email.toLowerCase().includes(searchUser.toLowerCase())) {
                          return val
                        }
                      }).slice(start, end).map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{`${item.fname} ${item.lname}`}</td>
                            <td className='actionstatus'>
                              <button className='detailsbtn' onClick={() => { setShow(true); setUser(item) }}>Details</button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  data={users}
                  itemsPerPage={itemsPerPage}
                  currrentPage={currrentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>

              <div className='UR_Details' style={{ width: '40%' }}>
                <div className='Acc_Info'>ACCOUNT INFORMATION</div>
                {show ? (
                  <div className='Acc_Display'>
                    <div className='Main_Details'>
                      {user.profilePic === null ? (<img className='Acc_Pic' src={Default} />) : (<img className='Acc_Pic' src={user.profilePic} />)}
                      {/* <div className='Acc_Pic'></div> */}
                      <div className='Main_Label_Con'>

                        <div className='Label_Con'>
                          <label className='Label_Name'>Name: </label>
                          <label className='Acc_Dis_Name'>{`${user.fname} ${user.lname}`}</label>
                        </div>

                        <div className='Label_Con'>
                          <label className='Label_Email'>Gender: </label>
                          <label className='Acc_Dis_Email'>{user.gender}</label>
                        </div>

                        <div className='Label_Con'>
                          <label className='Label_Phone'>Contact #:  </label>
                          <label className='Acc_Dis_Phone'>{user.contactNo}</label>
                        </div>
                      </div>
                    </div>
                    <div className='rem_Details'>
                      <div className='pt_one'>
                        <div className='Label_Add_Con'>
                          <label className='Label'>Address: </label>
                          <label className='Acc_Dis_Add'>{`${user.street}, ${user.barangay}, ${user.city}, ${user.province}, ${user.country}`}</label>
                        </div>

                        <div className='Label_Add_Con'>
                          <label className='Label'>Email:  </label>
                          <label className='Acc_Dis_Gen'>{user.email}</label>
                        </div>
                        {user.pdl_no && (
                          <div className='Label_Add_Con'>
                            <label className='Label'>PDL #:  </label>
                            <label className='Acc_Dis_Gen'>{user.pdl_no}</label>
                          </div>
                        )}

                        <div className='pt_two'>
                          <div className='Label_Add_Con'>
                            <label className='Label'>Linking To PDL: <span style={{ color: "red" }}>*</span></label>
                            <label className='Acc_Dis_Gen'>{user.PdlName}</label>
                            {/* <div className='PDL_Dropdown'>
                            <div className='pdl_select'><label>{user.PdlName}</label></div>
                          </div> */}
                          </div>

                          <div className='Label_Add_Con'>
                            <label className='Label'>Relationship:  </label>
                            <label className='Acc_Dis_Gen' style={{ marginBottom: '5px' }}>{user.PdlRelation}</label>
                          </div>

                          <div className='Label_Add_Con'>
                            <label className='Label'>User ID:  </label>
                            <button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(user) }}>View ID</button>
                          </div>

                          <div className='pending_users_btn'>
                            {loading ? (
                              <button className='detailsbtn' style={{ width: '100px' }}><ClipLoader color={'white'} loading={loading} size={25} /></button>
                            ) : (
                              <button className='detailsbtn' style={{ width: '100px' }} onClick={() => handleVerify(user)}>Verify</button>
                            )}
                            {/* <button className='detailsbtn' style={{ width: '100px' }} onClick={() => handleVerify(user.id)}>Verify</button> */}
                            <button className='cancelbtn' style={{ width: '100px' }} onClick={() => { setOpenDeclineModal(true); setDeclineId(user) }}>Decline</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {openModal && <UserIdModal closeModal={setOpenModal} data={item} port={port} />}
                {openDeclineModal && <DeclineVerification closeModal={setOpenDeclineModal} data={declineId} />}
              </div>
              <div className='UR_List' style={{ width: '30%', padding: '10px', position: 'relative' }}>
                <Styles>
                  <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <p className='pageHeader'>PDL</p>
                  </div>
                </Styles>
                <div className='searchDiv' style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}>
                  <input id='unregisteredSearch' style={{width: '100%'}} className='searchInput' type='text' placeholder='Search pdl# or first name' onChange={(e) => setSearchPdl(e.target.value)} />
                </div>
                <div style={{ overflowY: 'scroll', maxHeight: '390px', width: '100%' }}>
                  <table className='styled-table marginContent' style={{ marginTop: '0' }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Pdl Number</th>
                        <th style={{ textAlign: "center" }}>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pdls && pdls.filter((val) => {
                        if (searchPdl == '') {
                          return val
                        } else if (val.fname.toLowerCase().includes(searchPdl.toLowerCase()) || val.pdl_no.toLowerCase().includes(searchPdl.toLowerCase())) {
                          return val
                        }
                      }).slice(startPdl, endPdl).map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.pdl_no}</td>
                            <td style={{ padding: '20px' }}>{`${item.fname} ${item.lname}`}</td>
                            {/* <td className='actionstatus'>
                          <button className='detailsbtn' onClick={() => { setShow(true); setPdl(item) }}>Details</button>
                        </td> */}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  data={pdls}
                  itemsPerPage={itemsPerPagePdl}
                  currrentPage={currrentPagePdl}
                  setCurrentPage={setCurrentPagePdl}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div >
  )
}

export default Admin_Pending_Users