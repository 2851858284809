import React from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const ExportPost = ({data, filename}) => {
  const headers = [
    {label: 'Post Type', key: 'post_type'},
    {label: 'Title', key: 'title'},
    {label: 'Date of Event', key: 'date_posted'},
    {label: 'Body', key: 'body'},
  ]

  const csvReport = {
    filename: filename,
    headers: headers,
    data: data
  }
  return (
    <div className='al_exportbtn'>
        <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>
  )
}

export default ExportPost