import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com'
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';
import InputGroup from 'react-bootstrap/InputGroup';

const HelpdeskDetails = ({ closeModal, data }) => {

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const handleInput = (e) => {
    e.persist();
    setResponse(e.target.value);
  }

  const sendEmail = (e) => {
    e.preventDefault()
    setLoading(true)

    let formData = {
      name: data.name,
      from_name: 'BJMP',
      response: response,
      email: data.email
    }

    emailjs.send('service_l0qpn9e', 'template_6nw2qjb', formData, 'uwAmRC_Wd-V1u9SXo').then(res => {
      swal("Success", "Message has been sent!", "success");
      setLoading(false)
      setTimeout((_) => window.location.assign("/Admin_Inquiries"), 1000);
    }).catch(err => {
      swal("Error", "Please try again!", "error");
      setLoading(false)
    });
  }

  return (
    <div className='modalBackground'>
      <div className='modalContainer' style={{ height: 'fit-content' }}>
        <div className='modalTitle'>
          <p className='modalP'>Details</p>
        </div>
        <div className='modalBody'>
          {data.name && (<span>Name: {data.name}</span>)}
          {data.email && (<span>Email: {data.email}</span>)}
          {data.contactNumber && (<span>Contact Number: {data.contactNumber}</span>)}
          {data.message && (
            <>
              <span>Message:</span>
              <span className='messageHelpdesk'>{data.message}</span>
            </>
          )}
          <Form style={{textAlign:'left'}}>
            <Form.Group className="mb-3" controlId="formBasicEmail" style={{display:'flex', flexDirection:'column'}}>
              <Form.Label>Answer Inquiry</Form.Label>
              <Form.Control as="textarea" name="response" style={{padding:'8px'}} onChange={handleInput} value={response} aria-label="With textarea" rows={3} placeholder="Write your response" />
              <Form.Text style={{color: 'gray'}}>
                The message will be sent to the user's inputted email.
              </Form.Text>
            </Form.Group>
          </Form>
        </div>
        <div className='modalFooter'>
          <button style={{ backgroundColor: 'gray' }} onClick={() => closeModal(false)}>Close</button>
          {loading ? (
            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ClipLoader color={'white'} loading={loading} size={25} />
            </button>
          ) : (
            <button onClick={sendEmail}>Send</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default HelpdeskDetails