import React, { useState } from 'react'
import "../css/Dash_Login.css"
import BJMP_Logo from "../../../assets/bjmp_logo.png";
import axios from 'axios';
import swal from 'sweetalert';
import SetCookie from '../../../hooks/setCookie';
import RemoveCookie from '../../../hooks/removeCookie';
import { ClipLoader } from 'react-spinners';
import * as IoIcons from 'react-icons/io';

function Dash_Login() {

  const [loading, setLoading] = useState(false);
  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    error_list: [],
  })

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  }

  const loginSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      email: loginInput.email,
      password: loginInput.password
    }

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/login`, data).then(res => {
        if (res.data.success) {
          RemoveCookie("userToken");
          //Set Cookie
          SetCookie("userToken", JSON.stringify({
            userType: "admin",
            token: res.data.token,
          }));
          setLoading(false);
          // SetCookie("userToken", JSON.stringify(res.data.token));
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Dashboard"), 2000);
        }
        else if (res.data.success === false) {
          setLoading(false);
          swal("Warning", res.data.msg, "warning");
          setLogin({ ...loginInput, error_list: [] })
        }
        else {
          setLogin({ ...loginInput, error_list: res.data.msg })
          setLoading(false);
        }
      });
    });

  }
  
  const [showPass, setShowPass] = useState(false)
  const handleShow = () => {
    setShowPass(!showPass)
  }

  return (
    <div className='ad_log_main'>
      <div className='ad_log_body'>
        <div className='ad_welcome'>
          <div className='ad_logo'><img src={BJMP_Logo} alt="" className="inner-image_1" /></div>
          <label>BUREAU OF JAIL MANAGEMENT AND PENOLOGY - BALIWAG</label>
        </div>
        <div className='ad_input_log'>
          <label style={{fontSize: '26px',fontFamily:'Tw Cen MT'}}>ADMINISTRATOR LOGIN</label>
          <form onSubmit={loginSubmit}>
            <div className='ad_Login_Inputs'>
              <input className='ad_Login_Email' type="email" name="email" onChange={handleInput} value={loginInput.email} placeholder='Email Address' required/>
              <span>{loginInput.error_list.email}</span>
            </div>
            <div className='Login_Inputs' style={{position:'relative'}}>
              <input className='ad_Login_Pass' type={showPass ? 'text' : 'password'} name="password" onChange={handleInput} value={loginInput.password} placeholder='Password' required/>
              <label className='loginShowPass' style={{right: '13%', top:'35%'}} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff/> : <IoIcons.IoMdEye/>}</label>
              <span>{loginInput.error_list.password}</span>
            </div>
            <div className='ad_Login_RememberMe'>
              <div className='ad_Login_Forgot' style={{cursor: 'pointer'}} onClick={() => window.location.assign('/Admin_ForgotPassword')}>Forgot Password?</div>
              {loading ? (
                <button className='ad_Login_Signin' type="submit"><ClipLoader color={'white'} loading={loading} size={25} /></button>
              ) : (
                <button className='ad_Login_Signin' type="submit"> Login</button>
              )}
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Dash_Login