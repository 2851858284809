import React, { useState, useEffect } from 'react'
import "../../css/Admin_Admin_Management.css"

import DetailsModal from '../../components/Modal/DetailsModal'
import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';
import timeFormat from '../../../../hooks/timeFormat';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import ExportAdminLog from '../../components/Export/ExportAdminLog';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_View_Logs() {
  const [logs, setLogs] = useState([])
  const [show, setShow] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  useEffect(() => {
    setLoadingPage(true)
    getLogs()
  }, [])

  const getLogs = () => {
    try {
      axios.get(`/api/admin/Logs/getAll`).then(res => {
        setLogs(res.data.msg)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  //archive log
  const handleDelete = (id) => {
    setShow(true)
    setDeleteId(id)
  }
  const handleDeleteItem = () => {
    setLoading(true)
    axios.post(`/api/admin/Logs/delete/${deleteId}`).then(res => {
      setLoading(false)
      swal("Success", res.data.msg, "success");
      setTimeout((_) => window.location.assign("/Admin_View_Logs"), 1000);
    });
  }


  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  const [filterBy, setFilterBy] = useState('')
  const handleChange = (e) => {
    e.persist();
    setFilterBy(e.target.value)
}

  logs.sort((a, b) => b.created_at.localeCompare(a.created_at));
  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportAdminLog data={logs} filename='AdminLogs.csv' />
          <ModalConfirmation title="Archive Logs" body="Are you sure you want to archive this log?" setShow={setShow} show={show} yesFunc={handleDeleteItem} loading={loading} />
          <div className='Admin_View_Logs'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>VIEW ADMIN LOGS</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <div className='reasonForm' style={{flexDirection: 'row', marginTop:'40px', alignItems:'center', justifyContent:'end'}}>
              <label className="item">Filter By: </label>
              <select className='right' name="gender" required style={{ fontSize: '15px', width:'25%', marginLeft:'5px' }} value={filterBy} onChange={handleChange}>
                <option value="">None</option>
                <option value="Add image in Carousel">Add image in Carousel</option>
                <option value="Update image in Carousel">Update image in Carousel</option>
                <option value="Delete Image in Carousel">Delete Image in Carousel</option>
                <option value="Accept E-Dalaw Request">Accept E-Dalaw Request</option>
                <option value="Decline E-Dalaw Request">Decline E-Dalaw Request</option>
                <option value="Archived E-Dalaw Request">Archived E-Dalaw Request</option>
                <option value="Accept Face to Face Request">Accept Face to Face Request</option>
                <option value="Decline Face to Face Request">Decline Face to Face Request</option>
                <option value="Archived Face to Face Request">Archived Face to Face Request</option>
                <option value="Added PDL">Added PDL</option>
                <option value="Edit PDL">Edit PDL</option>
                <option value="Archived PDL">Archived PDL</option>
                <option value="Added Post">Added Post</option>
                <option value="Edited Post">Edited Post</option>
                <option value="Archived Post">Archived Post</option>
                <option value="Restored Post">Restored Post</option>
                <option value="Verified a User">Verified a User</option>
                <option value="Declined a User">Declined a User</option>
                <option value="Linked User to PDL">Linked User to PDL</option>
                <option value="Restored Helpdesk">Restored Helpdesk</option>
                <option value="Archived Helpdesk">Archived Helpdesk</option>
                <option value="Updated VMGO">Updated VMGO</option>
                <option value="Update Logo Image">Update Logo Image</option>
                <option value="Updated Contact Us">Updated Contact Us</option>
              </select>
            </div>
            <table className='styled-table marginContent' style={{marginTop:'20px'}}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Location</th>
                  <th style={{ textAlign: "center" }}>Date of Activity</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {logs && logs.filter((val) => {
                  if (filterBy == '') {
                    return val
                  } else if (val.location.toLowerCase().includes(filterBy.toLowerCase())) {
                    return val
                  }
                }).slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.location}</td>
                      <td>{dateFormat(item.created_at, 'short') + " " + timeFormat(item.created_at)}</td>
                      <td className='actionstatus'>
                        <button className='cancelbtn' onClick={() => handleDelete(item.id)}>Archive</button></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={logs.filter((val) => {
                if (filterBy == '') {
                  return val
                } else if (val.location.toLowerCase().includes(filterBy.toLowerCase())) {
                  return val
                }
              })}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
            <a className='archivesbtn' href='/Admin_View_Logs_Archived'>View Archives</a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Admin_View_Logs