import React from 'react'

const RadioData = [
    {
        key: 1,
        value: "8:00 AM, 9:00 AM",
        view: "8:00 - 9:00 AM"
    },
    {
        key: 2,
        value: "9:00 AM, 10:00 AM",
        view: "9:00 - 10:00 AM"
    },
    {
        key: 3,
        value: "10:00 AM, 11:00 AM",
        view: "10:00 - 11:00 AM"
    },
    {
        key: 4,
        value: "12:00 PM, 1:00 PM",
        view: "12:00 - 1:00 PM"
    },
    {
        key: 5,
        value: "1:00 PM, 2:00 PM",
        view: "1:00 - 2:00 PM"
    },
    {
        key: 6,
        value: "2:00 PM, 3:00 PM",
        view: "2:00 - 3:00 PM"
    },
    {
        key: 7,
        value: "3:00 PM, 4:00 PM",
        view: "3:00 - 4:00 PM"
    },
    {
        key: 8,
        value: "4:00 PM, 5:00 PM",
        view: "4:00 - 5:00 PM"
    },
    {
        key: 9,
        value: "5:00 PM, 6:00 PM",
        view: "5:00 - 6:00 PM"
    },
]

export default RadioData