import React, { useEffect, useState } from 'react'
import "../../css/Admin_PDL_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios'
import Pagination from '../../../../components/Pagination/Pagination'
import dateFormat from '../../../../hooks/dateFormat'
import swal from 'sweetalert'
import styled from 'styled-components';
import ShowEntry from '../../../../components/Pagination/ShowEntry'
import ExportPdlList from '../../components/Export/ExportPdlList'
import loadingUI from '../../../../hooks/loadingUI'

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_PDL_Archived() {

  const [request, setRequest] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  useEffect(() => {
    setLoadingPage(true)
    getPosts()
  }, [])

  const getPosts = () => {
    try {
      axios.get(`/api/admin/pdl/getArchive`).then(res => {
        setRequest(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  //modal
  const [show, setShow] = useState(false)
  const [itemId, setItemId] = useState("")

  const handleRestore = (id) => {
    setShow(true)
    setItemId(id)
  }

  const handleRestoreItem = () => {
    setLoading(true)
    axios.post(`/api/admin/helpdesk/restore/${itemId}`).then(res => {
      setLoading(false)
      swal("Success", res.data.msg, "success");
      setTimeout((_) => window.location.assign("/Admin_Inquiries_Archive"), 1000);
    });
  }

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;
  const [search, setSearch] = useState('')

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportPdlList data={request} filename='PDLInfoArchived.csv' />
          <div className='Admin_PDL_Archived'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>PDL Archive</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <div className='searchDiv' style={{ width: '100%', marginRight: '10px', marginTop: '55px' }}>
              <input className='searchInput' style={{ marginLeft: '0', width: '25%', padding: '10px' }} type='text' placeholder='Search for pdl # or first name' onChange={(e) => setSearch(e.target.value)} />
            </div>
            <table className='styled-table marginContent' style={{ marginTop: '10px' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>PDL No.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Gender</th>
                  <th style={{ textAlign: "center" }}>Cases</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {request && request.filter((val) => {
                  if (search == '') {
                    return val
                  } else if ((val.fname).toLowerCase().includes(search.toLowerCase()) || val.pdl_no.toString().includes(search.toLowerCase())) {
                    return val
                  }
                }).slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      {console.log(item)}
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.pdl_no}</td>
                      <td>{item.mname === null ? `${item.fname} ${item.lname}` : `${item.fname} ${item.mname} ${item.lname}`}</td>
                      <td>{item.gender}</td>
                      <td>{item.cases}</td>
                      <td>{item.status === 1 ? (
                        <button className='acceptbtn' style={{ width: '100px' }}>Released</button>
                      ) : (
                        <button className='cancelbtn' style={{ width: '100px' }}>Deceased</button>
                      )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={request}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Admin_PDL_Archived