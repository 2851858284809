import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getUser } from '../../api/ChatRequest';
import { addMessage, getMessages } from '../../api/MessageRequest';
import Default from '../../../../assets/default_user.png'
import { format } from "timeago.js"
import "./ChatBox.css";
import InputEmoji from "react-input-emoji"
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { io } from 'socket.io-client'
import CurrentUser from '../../../../auth/CurrentUser';

const ChatBox = ({ socket, chat, currentUser, setSendMessages, receiveMessages }) => {
    const [userData, setUserData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true)
    // const [sendMessages, setSendMessages] = useState(null);
    const scroll = useRef();
    const [isSend, setIsSend] = useState([])

    const handleChange = (newMessage) => {
        setNewMessage(newMessage)
    }


    //fetch data for header
    useEffect(() => {
        const userId = chat?.members?.find((id) => id !== currentUser)
        const getUserData = async () => {
            try {
                if (userId !== 'admin') {
                    const { data } = await getUser(userId)
                    setUserData(data.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (chat !== null) getUserData()
    }, [chat, currentUser])

    //fetch data for messages
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const { data } = await getMessages(chat.id);
                setMessages(data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        };
        if (chat !== null) fetchMessages();
    }, [chat])



    const handleSend = async (e) => {
        e.preventDefault()

        if (newMessage !== "") {
            const messageData = {
                chatId: chat.id,
                senderId: currentUser,
                text: newMessage,
            }

            await socket.current.emit("send_message", messageData);
            setIsSend(messageData)
            // const receiverId = chat.members.find((id) => id !== currentUser)
            // setSendMessages({ ...messageData, receiverId })

            try {
                await axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post(`/api/message`, messageData).then(res => {
                        setMessages([...messages, res.data.data]);
                        setNewMessage("")
                    });
                });
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        socket.current.on("receive_message", (data) => {
            setMessages((list) => [...list, data]);
        })
    }, [socket])


    // Always scroll to last Message
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages])

    if (loading) return <ClipLoader color={'#6e9afaef'} loading={loading} size={100} />
    return (
        <>
            <div className={currentUser === 'admin' ? "ChatBox-container" : "ChatBox-container-user"}>
                {Object.keys(chat).length !== 0 ? (
                    <>
                        <div className="chat-header">
                            <div className="follower">
                                <div className="conversation">
                                    {/* <img
                                        src={Default}
                                        alt=""
                                        className='conversationImg'
                                        style={{ width: '50px', height: '50px' }}
                                    /> */}
                                    {userData !== null && userData.profilePic !== null ? (
                                        <img
                                            src={userData.profilePic}
                                            alt=""
                                            className='conversationImg'
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    ) : (
                                        <img
                                            src={Default}
                                            alt=""
                                            className='conversationImg'
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    )}
                                    <div className="conversationHeaderName">
                                        {/* <span>{userData?.fname} {userData?.lname}</span> */}
                                        <span>{userData ? userData.fname + ' ' + userData.lname : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ width: "90%", marginLeft: '5%', border: "0.1px solid #ececec" }} />
                        </div>

                        {/* chatbox body */}
                        {messages.length > 0 ?
                            (<div className="chat-body">
                                {messages?.map((message, index) => (
                                    // <div key={message.id}>
                                    <div key={index} ref={scroll} className={message?.senderId == currentUser ? "message own" : "message"}>
                                        <span>{message?.text}</span>
                                        <span>{format(message?.created_at)}</span>
                                    </div>
                                    // </div>
                                ))}
                            </div>) : (<span className="chatbox-empty-message" style={{ color: 'gray' }}>Start a Conversation...</span>)
                        }
                        {/* <div className="chat-body">
                            {messages?.map((message) => (
                                     // <div key={message.id}>
                                         <div key={message.id} ref={scroll} className={message?.senderId == currentUser ? "message own" : "message"}>
                                             <span>{message?.text}</span>
                                             <span>{format(message?.created_at)}</span>
                                         </div>
                                     // </div>
                                 ))}
                            </div> */}

                        {/* chat-sender */}
                        < div className={currentUser === 'admin' ? "chat-sender" : "chat-sender-user"} >
                            {/* <div>+</div> */}
                            < InputEmoji value={newMessage} onChange={handleChange} />
                            <div className="send-button buttonchat" onClick={handleSend} style={{ padding: '20px' }}>Send</div>
                        </div>
                    </>
                ) : (
                    <span className="chatbox-empty-message">Tap on a Chat to start Conversation</span>
                )}

            </div>
        </>
    )
}

export default ChatBox
