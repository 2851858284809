import React from 'react'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='Footer'>
      <div className='foot_body'>
        <div className='col_I'>
          <label className='ROTP'>REPUBLIC OF THE PHILIPPINES<br></br></label>
          <label className='ROTP_Con'><br></br>All content is in the public domain unless otherwise stated.</label>
          <div>

          </div>

        </div>
        <div className='col_II'>
          <label className='ROTP'>ABOUT GOVPH<br></br> </label>
          <label className='ROTP_Con'><br></br>Learn more about the Philippine government, its structure, how government works and the people behind it.<br></br></label>
          <a href="http://www.gov.ph/" target="_blank"><br></br>GOV.PH</a>
          <a href="http://www.gov.ph/data" target="_blank"><br></br>Open Data Portal</a>
          <a href="http://www.officialgazette.gov.ph/" target="_blank"><br></br>Official Gazette</a>
          <div>

          </div>

        </div>
        <div className='col_III'>
          <label className='ROTP'>GOVERNMENT LINKS<br></br></label>
          
          <a href="http://president.gov.ph/" target="_blank"><br></br>Office of the President</a>
          <a href="http://ovp.gov.ph/" target="_blank"><br></br>Office of the Vice President</a>
          <a href="http://www.senate.gov.ph/" target="_blank"><br></br>Senate of the Philippines</a>
          <a href="http://www.congress.gov.ph/" target="_blank"><br></br>House of Representatives</a>
          <a href="http://sc.judiciary.gov.ph/" target="_blank"><br></br>Supreme Court</a>
          <a href="http://ca.judiciary.gov.ph/" target="_blank"><br></br>Court of Appeals</a>
          <a href="http://sb.judiciary.gov.ph/" target="_blank"><br></br>Sandiganbayan</a>
          <div>

          </div>

        </div>


      </div>
        
    </div>
  )
}

export default Footer