import React, { useEffect, useState } from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const ExportPdlGeoTag = ({pdlData, pinData, filename}) => {
  const [data, setData] = useState([])
  
//   useEffect(() => {
//     setData([])
//   },)
  const getData = () => {
    if(pdlData.length > 0 && pinData.length > 0 && pdlData !== undefined && pinData!== undefined) {
        for(let i = 0; i < pinData.length; i++){
            let barangay = {barangay: `${pinData[i]?.barangay}`}
            setData(current => [...current, barangay])
            if(pinData[i]?.barangay === 'Outside Bulacan'){
                for(let j = 0; j < pdlData.length; j++){
                    if(pdlData[j]?.province?.split(' ').join('').toLowerCase() === 'bulacan'){}
                    else{
                        let arr1 ={pdlno: `${pdlData[j]?.pdl_no}`, name:`${pdlData[j]?.fname} ${pdlData[j]?.lname}`, cases: `${pdlData[j]?.cases}`, address: `${pdlData[j]?.barangay}, ${pdlData[j].city}, ${pdlData[j]?.province}, ${pdlData[j]?.country}`}
                        setData(current => [...current, arr1])
                    }
                }
            }
            else if(pinData[i]?.barangay === 'Outside Baliwag'){
                for(let j = 0; j < pdlData.length; j++){
                    if (pdlData[j]?.province?.split(' ').join('').toLowerCase() !== 'bulacan'){}
                    else if(pdlData[j]?.city?.split(' ').join('').toLowerCase() === 'baliwag'){}
                    else if (pdlData[j]?.city?.split(' ').join('').toLowerCase() === 'baliuag'){}
                    else{
                        let arr1 ={pdlno: `${pdlData[j]?.pdl_no}`, name:`${pdlData[j]?.fname} ${pdlData[j]?.lname}`, cases: `${pdlData[j]?.cases}`, address: `${pdlData[j]?.barangay}, ${pdlData[j].city}, ${pdlData[j]?.province}, ${pdlData[j]?.country}`}
                        setData(current => [...current, arr1])
                    }
                }
            } else {
                for(let j = 0; j < pdlData.length; j++){
                    let pdlBarangay = pdlData[j]?.barangay;
                    if(pinData[i]?.barangay?.split(' ').join('').toLowerCase() === pdlBarangay?.split(' ').join('').toLowerCase()){
                        let arr1 ={pdlno: `${pdlData[j]?.pdl_no}`, name:`${pdlData[j]?.fname} ${pdlData[j]?.lname}`, cases: `${pdlData[j]?.cases}`, address: `${pdlData[j]?.barangay}, ${pdlData[j].city}, ${pdlData[j]?.province}, ${pdlData[j]?.country}`}
                        setData(current => [...current, arr1])
                    }
                }
            }
        };
      }
        setTimeout((_) => window.location.assign("/Admin_GeoTag_Management"), 500);
  }
  console.log(pdlData)
  const headers = [
    {label: 'Barangay', key: 'barangay'},
    {label: 'PDL #', key: 'pdlno'},
    {label: 'PDL Name', key: 'name'},
    {label: 'Cases', key: 'cases'},
    {label: 'PDL Address', key: 'address'},
  ]
  
  return (
    <>
        {pdlData.length > 0 && pinData.length > 0 && pdlData !== undefined && pinData!== undefined ? (
            <div className='al_exportbtn' onClick={getData}>
                <CSVLink headers={headers} data={data} filename={filename}>Export to CSV</CSVLink>
                {/* <a style={{cursor: 'pointer'}}>Export to CSV</a> */}
            </div>
        ): <div></div>}
    </>
  )
}

export default ExportPdlGeoTag