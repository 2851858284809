import axios from 'axios'
import React, { useState } from 'react'
import swal from 'sweetalert'
import './detailsModal.css'
import emailjs from 'emailjs-com'
import { ClipLoader } from 'react-spinners'

const AcceptRequestModal = ({ data, closeModal }) => {

    const [meetlink, setMeetlink] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        e.persist();
        setMeetlink(e.target.value);
    }

    const handleInput = (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('meet_link', meetlink);

        let emailTemp = {
            name: `${data.fname} ${data.lname}`,
            header: 'Your E-Dalaw Request has  been approved!',
            header_tagalog: 'Ang iyong kahilingan upang makipag-usap online ay naaprubahan na!',
            email: data.email,
            pdl_name: `${data.fname} ${data.lname}`,
            date: data.date,
            time: `${data.start_time} - ${data.end_time}`,
            type: 'Here is the meeting link:',
            type_tagalog: 'Ito ang meeting link:',
            body: meetlink,
            note: 'Note: Failure to join this meeting will result to cancelation of the request. Thank you.',
            note_tagalog: 'Paunawa: Ang hindi pagdalo sa nasabing pagkikita ay mag reresulta sa pagkansela ng iyong kahilingan. Maraming salamat.',
        }
        setLoading(true)
        axios.post(`/api/admin/EDalaw/accepted/${data.id}`, formData).then(res => {
            emailjs.send('service_l0qpn9e', 'template_1zl4ytw', emailTemp, 'uwAmRC_Wd-V1u9SXo').then(response => {
                swal("Success", res.data.msg, "success");
                setLoading(false)
                setTimeout((_) => window.location.assign("/Admin_E_Dalaw_Pending"), 1000);
            }).catch(err => {
                console.log(err)
                swal("Error", "Please try again!", "error");
                setLoading(false)
            });
        });
    }

    const status_class = (status) => {
        if (status === 'Pending') {
            return (<p className='pendingStat'>{status}</p>)
        } else if (status === 'Accepted') {
            return (<p className='acceptedStat'>{status}</p>)
        } else if (status === 'Declined') {
            return (<p className='declinedStat'>{status}</p>)
        }
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Details</p>
                </div>
                <form onSubmit={handleInput}>
                    <div className='modalBody'>
                        {data.status && (<span className='stat'>Status: {status_class(data.status)}</span>)}
                        {data.fname && (<span>First Name: {data.fname}</span>)}
                        {data.mname && (<span>Middle Name: {data.mname}</span>)}
                        {data.lname && (<span>Last Name: {data.lname}</span>)}
                        {data.gender && (<span>Gender: {data.gender}</span>)}
                        {data.contactNo && (<span>Contact Number: {data.contactNo}</span>)}
                        {data.email && (<span>Email: {data.email}</span>)}
                        {data.address && (<span>Address: {data.address}</span>)}
                        {data.pdl_name && (<span>PDL Name: {data.pdl_name}</span>)}
                        {data.pdl_relationship && (<span>PDL Relationship: {data.pdl_relationship}</span>)}
                        {data.date && (<span>Scheduled Date: {data.date}</span>)}
                        {data.start_time && (<span>Scheduled Time: {data.start_time + " - " + data.end_time}</span>)}
                        <div className='meetlinkForm'>
                            <label>Meet Link:</label>
                            <input type='text' name='meet_link' onChange={handleChange} value={meetlink} placeholder='Meet Link' required />
                        </div>
                        <span style={{ color: 'gray', fontSize: '16px' }}>Please click the google logo on the bottom of your screen. It will generate a google meet link, copy it and paste it on the input field.</span>
                        <div className='Google_Logo_Holder' >
                            <div className='Google_Logo' >

                            </div>
                            <div className='gen_link'><a href="http://meet.google.com/new" target="_blank"> <label>Generate Meeting Link</label></a></div>
                        </div>

                    </div>
                    <div className='modalFooter'>
                        <button className='closebtnModal' style={{ backgroundColor: 'crimson' }} onClick={() => closeModal(false)}>Close</button>
                        {loading ? (
                            <button
                                className='submitbtn'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                                <ClipLoader color={'white'} loading={loading} size={25} />
                            </button>
                        ) : (
                            <button className='closebtnModal' type='submit'>Accept</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AcceptRequestModal