import React, { useState, useEffect } from 'react'
import "../../css/Admin_GeoTag_Info.css"
import styled from 'styled-components'
import axios from 'axios'
import MapGeoTag from '../../components/GeoTag/MapGeoTag'
import GeoModal from '../../components/Modal/GeoModal'

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import loadingUI from '../../../../hooks/loadingUI'
import swal from 'sweetalert'
import ExportPdlGeoTag from '../../components/Export/ExportPdlGeoTag'


const TableStyle = styled.div`
padding-left: 0px;
height: 600px;
overflow-y: scroll;
overflow-x: hidden;
padding: 10px;
`

const GeoMapWrapper = styled.div`
width: 60%;
height:650px;
padding: 10px;
`

const GeoMapContainer = styled.div`
margin-top:20px;
display:flex;
justify-content:space-around;
`

const Header = styled.div`
width:100%;
background: #054068;
margin:0;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`


function GeoTag_Info() {

  const [markers, setMarkers] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const type = 'pdl'
  useEffect(() => {
    pdlAll()
    try {
      setLoadingPage(true)
      axios.get('/api/user/geoTag/getAllPin').then(res => {
        setMarkers(res.data.data)
        setLoadingPage(false)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const [pdls, setPdls] = useState([])
  const pdlAll = () => {
    try {
      axios.get(`/api/admin/pdl/getAll`).then(res => {
        setPdls(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const [editGeoUser, setEditGeoUser] = useState(true)
  const [isActive, setIsActive] = useState("0")
  useEffect(() => {
    try {
      axios.get(`/api/admin/vmgo/get`).then(res => {
        if (res.data.data !== null) {
          setIsActive(res.data.data[0].isActive)
        }
      });
    } catch (error) {
      console.log(error)
    }
  }, [])
  const editSubmit = (e) => {
    e.preventDefault();
    setLoadingPage(true)

    const formData = new FormData();
    formData.append('isActive', isActive);

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/geotag/update/active`, formData).then(res => {
        if (res.data.success) {
          setLoadingPage(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_GeoTag_Management"), 1000);
        }
        else {
          setLoadingPage(false)
          swal("Warning", "Changes not saved! Try Again.", "warning");
        }
      });
    });
  }

  return (
    <div className='all_body' style={{overflowX:'hidden'}}>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          {/* <div><SQB /></div> */}
          <ExportPdlGeoTag pdlData={pdls} pinData={markers} filename='PdlGeoTag.csv' />
          <div className='Admin_GeoTag_Info' style={{display:'flex', marginTop:'20px', justifyContent:'space-between', padding:'10px', width:'90%', marginLeft:'5%', alignItems:'center'}}>
            {editGeoUser ? (
              <>
                <div style={{display: 'flex', alignItems:'center'}}>
                  <p style={{marginRight:'10px',paddingLeft:'15px'}}>Enable user to see GeoTagging: </p>
                  <select className="contenttype" disabled style={{padding:'5px', fontSize:'15px', borderRadius:'10px'}} value={isActive} name="post_type">
                    <option value="0">YES</option>
                    <option value="1">NO</option>
                  </select><br />
                </div>
                <button className='add_admin_submit' style={{width:'15%', margin:'0', opacity: 1, marginRight:'15px' }} onClick={() => setEditGeoUser(false)}>Edit</button>
              </>
              ) : (
              <form onSubmit={editSubmit} style={{width:'100%',display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                <div style={{display: 'flex', alignItems:'center'}}>
                  <p style={{marginRight:'10px',paddingLeft:'15px'}}>Enable user to see GeoTagging: </p>
                  <select className="contenttype" style={{padding:'5px', fontSize:'15px', borderRadius:'10px'}} name="isActive" value={isActive} onChange={e => setIsActive(e.target.value)} required>
                    <option value="0">YES</option>
                    <option value="1">NO</option>
                  </select><br />
                </div>
                <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between', width:'30%'}}>
                  <button className='archivesbtn' style={{width:'45%', opacity: 1, margin:'0', marginLeft:'5%', borderRadius:'15px', fontSize:'18px', fontWeight:'normal' }} onClick={() => setEditGeoUser(true)}>Cancel</button>
                  <button className='add_admin_submit' style={{width:'45%', opacity: 1,margin:'0' }} type="submit">Submit</button>
                </div>
                
              </form>
              )
            }
          </div>
          <div className='Admin_GeoTag_Info'>
            <Header><p className='geoPdlHeader'>Geotagging of PDL</p></Header>
            <GeoMapContainer>
              <GeoMapWrapper>
                <MapGeoTag markers={markers} type={type} />
              </GeoMapWrapper>
              <TableStyle>
                <table className='styled-table-map'>
                  <thead>
                    <tr style={{ top: '0' }}>
                      <th style={{ textAlign: "center" }}>Barangay</th>
                      <th style={{ textAlign: "center" }}>No. of PDL</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {markers && markers.map((item, index) => {
                      return (
                        <tr key={index} className={index % 2 == 0 ? "odd" : "even"}>
                          <td>{item.barangay}</td>
                          <td>{item.pdl_count}</td>
                          <td><button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </TableStyle>
            </GeoMapContainer>
            <button className='add_admin_submit' style={{ marginBottom: '20px', float: 'right', marginRight: '30px', opacity: 1 }} onClick={() => window.location.assign("/Admin_GeoTag_UserInfo")}>User Geo Tag</button>
          </div>
        </div>
      )}
      {openModal && <GeoModal closeModal={setOpenModal} data={item} type={type} />}
    </div>
  )
}

export default GeoTag_Info