import axios from 'axios';
import React, { useState } from 'react'
import swal from 'sweetalert';
import '../styles/HelpDesk.css'

function HelpDesk() {

  const [helpdeskInput, setHelpdesk] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  const handleInput = (e) => {
    e.persist();
    setHelpdesk({ ...helpdeskInput, [e.target.name]: e.target.value });
  }

  const helpdeskSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: helpdeskInput.name,
      email: helpdeskInput.email,
      contactNumber: helpdeskInput.contactNumber,
      message: helpdeskInput.message,
    }

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/user/helpdesk', data).then(res => {
        if (res.data.success) {
          swal("Success", res.data.msg, "success");
        }
        else {
          swal("Warning", res.data.msg, "warning");
        }
      });
    });
  }




  return (
    <div className='Helpdesk_Body'>
      <div className='HelpDesk_div'>
        <div className='Helpdesk_Img'></div>
        <div className='Helpdesk_Text'>
          <label className='Helpdesk_Title_Main'>Inquiries</label>
          <label className='Helpdesk_SubTitle_Main'>Get in touch</label>

          <form onSubmit={helpdeskSubmit}>
            <div className='HelpDeskBody'>
              <input className="name" type="text" name="name" onChange={handleInput} value={helpdeskInput.name} placeholder='Name' />
              <input className="email_HD" type="text" name="email" onChange={handleInput} value={helpdeskInput.email} placeholder='Email Address' />
              <input className="contact" type="text" name="contactNumber" onChange={handleInput} value={helpdeskInput.contactNumber} placeholder='Contact' />
              <textarea className="message" name="message" onChange={handleInput} value={helpdeskInput.message} placeholder="Write Something..."></textarea>
              {/* <button className='robot'>I'm not a robot</button> */}
              <button className='submit' type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default HelpDesk