import React, { useEffect, useState } from 'react'
import "../../css/Admin_Content_Management.css"
import "../../css/table.css"
import styled from 'styled-components';
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import Pagination from '../../../../components/Pagination/Pagination';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import ShowEntry from '../../../../components/Pagination/ShowEntry';
import { Link } from 'react-router-dom';
import loadingUI from '../../../../hooks/loadingUI'
import ExportPost from '../../components/Export/ExportPost';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

const Container = styled.div`
margin-top: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
position: fixed !important;
left: 22%;
right: 2%;
border-radius: 25px;
font-size: 24px;
background: white;
height: 60%;
overflow: auto;

`

const TableStyle = styled.div`
padding-left: 0px;
position: absolute !important;
left: 2%;
right: 2%;
`

function Admin_Manage_Post() {
    const [posts, setPosts] = useState([])
    const [deleteId, setDeleteId] = useState("")
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)

    useEffect(() => {
        getPosts()
    }, [])

    const getPosts = () => {
        try {
            setLoadingPage(true)
            //posts
            axios.get(`/api/admin/posts/getAll`).then(res => {
                setPosts(res.data.data)
                setLoadingPage(false)
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = (id) => {
        setShow(true)
        setDeleteId(id)
    }

    const handleDeleteItem = () => {
        setLoading(true)
        axios.post(`/api/admin/posts/delete/${deleteId}`).then(res => {
            setLoading(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Admin_Manage_Post"), 1000);
        });
    }

    const [search, setSearch] = useState('')

    //test
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currrentPage, setCurrentPage] = useState(1);

    const end = currrentPage * itemsPerPage;
    const start = end - itemsPerPage;

    // if (loadingPage) return loadingUI(loadingPage)
    posts.sort((a, b) => b.created_at.localeCompare(a.created_at));

    return (
        <div className='all_body'>
            <div><Top_Bar /></div>
            <div className='ad_nav_size'><Admin_Navbar /></div>
            {loadingPage ? loadingUI(loadingPage) : (
                <div className='Admin_Add_Post_Main'>
                    <div><SQB /></div>
                    <ExportPost data={posts} filename='Posts.csv' />
                    <ModalConfirmation title="Archive Post" body="Are you sure you want to archive this post?" setShow={setShow} show={show} yesFunc={handleDeleteItem} loading={loading} />
                    <div className='Manage_Post_Con'>
                        <div className='req_List'>
                            <Styles>
                                <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                    <p className='pageHeader'>MANAGE POST</p>
                                    <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                                </div>
                            </Styles>
                            <div className='searchDiv' style={{ width: '40%', float: 'right', marginRight: '10px', marginTop: '45px' }}>
                                <input className='searchInput' style={{ width: '100%' }} type='text' placeholder='Search for title' onChange={(e) => setSearch(e.target.value)} />
                            </div>
                            <table className='styled-table marginContent'>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>No.</th>
                                        <th style={{ textAlign: "center" }}>Post Type</th>
                                        <th style={{ textAlign: "center" }}>Title</th>
                                        <th style={{ textAlign: "center" }}>Date Posted</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {posts && posts.filter((val) => {
                                        if (search == '') {
                                            return val
                                        } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                    }).slice(start, end).map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row'>{index + 1}</th>
                                                <td>{item.post_type}</td>
                                                <td>{item.title}</td>
                                                <td>{dateFormat(item.created_at, 'long')}</td>
                                                <td className='actionstatus'>
                                                    <button className='detailsbtn'><Link to="/Admin_Edit_Post" state={item}>Edit</Link></button>
                                                    <button className='cancelbtn' onClick={() => handleDelete(item.id)}>Archive</button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Pagination
                                data={posts.filter((val) => {
                                    if (search == '') {
                                        return val
                                    } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                        return val
                                    }
                                })}
                                itemsPerPage={itemsPerPage}
                                currrentPage={currrentPage}
                                setCurrentPage={setCurrentPage}
                            />
                            <a className='archivesbtn' href='/Admin_Archive_Post'>View Archives</a>
                        </div></div>
                </div>
            )}
        </div>
    )
}

export default Admin_Manage_Post