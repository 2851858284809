import React, { useState, useEffect } from 'react'
import "../../css/Admin_EDalaw.css"

import DetailsModal from '../../components/Modal/DetailsModal'
import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import DeclineRequestModal from '../../components/Modal/DeclineRequestModal';
import emailjs from 'emailjs-com'
import ExportRequest from '../../components/Export/ExportRequest';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function E_Dalaw_Pending() {
  const [request, setRequest] = useState([])
  const [isAccept, setIsAccept] = useState(false);
  const [show, setShow] = useState(false)
  const [itemReq, setItemReq] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const port = '/api/admin/F2f/declined/'
  const link = "/Admin_FTF_Pending"
  const type = 'f2f'


  useEffect(() => {
    setLoadingPage(true)
    getRequest()
  }, [])

  const getRequest = () => {
    try {
      axios.get(`/api/admin/F2f/getPending`).then(res => {
        setRequest(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  //accept request
  const handleAccept = (item) => {
    setIsAccept(true);
    setShow(true)
    setItemReq(item)
  }
  const handleAcceptItem = () => {
    let emailTemp = {
      name: `${itemReq.fname} ${itemReq.lname}`,
      header: 'Your Face-To-Face Request has been approved!',
      header_tagalog: 'Ang iyong kahilingan upang makipag-usap personal ay naaprubahan na!',
      email: itemReq.email,
      pdl_name: `${itemReq.fname} ${itemReq.lname}`,
      date: itemReq.date,
      time: `${itemReq.start_time} - ${itemReq.end_time}`,
      type: 'Kindly download or take a screenshot of your QR Code in Face-To-Face Page',
      type_tagalog: 'I-download o kuhanan ng larawan ang iyong QR Code na matatagpuan sa Face-To-Face Page',
      note: 'Note: Failure to show your QR Code may result to cancellation of the request. Thank you.',
      note_tagalog: 'Paunawa: Ang hindi pagdalo at hindi pagpapakita ng QR Code sa nasabing pagkikita ay maaaring magresulta sa pagkansela ng iyong kahilingan. Maraming salamat.',
    }

    setLoading(true)
    axios.patch(`/api/admin/F2f/accepted/${itemReq.id}`).then(res => {
      emailjs.send('service_l0qpn9e', 'template_1zl4ytw', emailTemp, 'uwAmRC_Wd-V1u9SXo').then(response => {
        swal("Success", res.data.msg, "success");
        setLoading(false)
        setTimeout((_) => window.location.assign("/Admin_FTF_Pending"), 1000);
      }).catch(err => {
        console.log(err)
        swal("Error", "Please try again!", "error");
        setLoading(false)
      });
    });
  }

  //decline request
  const handleDecline = (item) => {
    setIsAccept(false);
    setOpenAcceptModal(true)
    setItemReq(item)
  }

  //request
  const [openAcceptModal, setOpenAcceptModal] = useState(false)

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)

  const [item, setItem] = useState({});

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;


  request.sort((a, b) => (a.date + a.start_time).localeCompare(b.date + b.start_time));

  return (
    <div className='all_body'>
      {isAccept ? (
        <ModalConfirmation title="Accept Request" body="Are you sure you want to accept this request?" setShow={setShow} show={show} yesFunc={handleAcceptItem} loading={loading} />
      ) : (
        <>
          {openAcceptModal && <DeclineRequestModal data={itemReq} closeModal={setOpenAcceptModal} type={type} port={port} link={link} />}
        </>
        // <ModalConfirmation title="Decline Request" body="Are you sure you want to decline this request?" setShow={setShow} show={show} yesFunc={handleDeclineItem} loading={loading} />
      )}
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportRequest data={request} filename='F2fPending.csv' />
          <div className='Admin_EDalaw_Pending'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>FACE-TO-FACE / PENDING REQUESTS</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <table className='styled-table marginContent'>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Requested By</th>
                  <th style={{ textAlign: "center" }}>PDL</th>
                  <th style={{ textAlign: "center" }}>Start of Schedule</th>
                  <th style={{ textAlign: "center" }}>End of Schedule</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {request && request.slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.fname + " " + item.lname}</td>
                      <td>{item.pdl_name}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.start_time}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.end_time}</td>
                      <td className='actionstatus'>
                        <button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button>
                        <button className='acceptbtn' onClick={() => handleAccept(item)}>Accept</button>
                        <button className='cancelbtn' onClick={() => handleDecline(item)}>Decline</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={request}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      {openModal && <DetailsModal closeModal={setOpenModal} data={item} />}
    </div>
  )
}

export default E_Dalaw_Pending