import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import RemoveCookie from '../../../hooks/removeCookie'
import "../css/Admin_Navbar.css"
import Default from '../../../assets/default_user.png'
import { ClipLoader } from 'react-spinners'

function Top_Bar() {

  const [adminInfo, setAdminInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  useEffect(() => {
    getAdmin()
  }, [])

  const getAdmin = () => {
    setLoading(true)
    axios.get('/api/admin/profile').then(res => {
      setAdminInfo(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err);
    })
  }

  const logoutSubmit = (e) => {
    e.preventDefault();
    setLoadingBtn(true)
    axios.post(`api/user/logout`).then(res => {
      if (res.data.success) {
        setLoadingBtn(false)
        RemoveCookie("userToken");
        swal("Success", res.data.msg, "success");
        setTimeout((_) => window.location.assign("/Admin_Login"), 1000);
      }
    })
  }

  return (
    <div className='Top_Bar_Body'><div className='admin_top'></div>
      <div className='admin_prof'>
        {/* <div className='admin_prof_img'></div> */}
        {loading ? (
          <div className='prof_text' style={{width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <label>Welcome!<br /></label>
            <label>Admin</label> */}
            <ClipLoader color={'white'} loading={loading} size={25} />
          </div>
        ) : (
          <>
            {adminInfo.adminImg === null ? (
              <img className="admin_prof_img" src={`${Default}`} />
            ) : (
              <img className="admin_prof_img" src={`${adminInfo.adminImg}`} />
            )}
            <div className='prof_text' style={{ display: 'flex', flexDirection: 'column' }}>
              <label>Welcome!<br /></label>
              <label>{`Admin ${adminInfo.fname}!`}</label>
            </div>
            <div className="profile_dropdown_content">
              {/* <a href="/Admin_Login">Logout</a> */}
              {/* <Link to="" onClick={logoutSubmit}>Logout</Link> */}
              {loadingBtn ? (<Link to=""><ClipLoader color={'blue'} loading={loadingBtn} size={25} /></Link>) : (<Link to="" onClick={logoutSubmit}>Logout</Link>)}
            </div>
          </>
        )}

      </div></div>
  )
}

export default Top_Bar