import React, { useEffect, useRef } from 'react'
import './Chat.css'
import LogoSearch from '../../components/LogoSearch/LogoSearch'
import { useState } from 'react'
import CurrentUser from '../../../../auth/CurrentUser'
import axios from 'axios'
import { userChats } from '../../api/ChatRequest'
import { ClipLoader } from 'react-spinners';
import Conversation from '../../components/Conversation/Conversation'
import ChatBox from '../../components/Chatbox/ChatBox'
import { io } from 'socket.io-client'
import loadingUI from '../../../../hooks/loadingUI'

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"

const Chat = () => {

    const [user, setUser] = useState({})
    const [currentChat, setCurrentChat] = useState({})
    const [loading, setLoading] = useState(true)
    const [chats, setChats] = useState([])
    const [sendMessages, setSendMessages] = useState(null);
    const [receiveMessages, setReceiveMessages] = useState(null);
    const socket = useRef()


    //get chat in chat
    useEffect(() => {
        //get chats applicable only to admin?
        const getChats = async () => {
            try {
                if (CurrentUser() === 'admin') {
                    const { data } = await userChats(CurrentUser())
                    setChats(data.data)
                    setLoading(false)
                } else {
                    const { data } = await userChats(user.id)
                    setChats(data.data)
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getChats()
    }, [user])

    //connect to socket io
    useEffect(() => {
        // socket.current = io.connect('http://localhost:8800')
        socket.current = io.connect('https://bjmp-socket.onrender.com')
        // socket.current = io.connect('https://try-chat-bjmp.herokuapp.com')
        if (CurrentUser() !== 'admin') {
            socket.current.emit("new-user-add", user.id)
        } else {
            if (user === 'admin')
                socket.current.emit("new-user-add", user)
        }
    }, [user])

    //send message to socket server
    // useEffect(() => {
    //     if (sendMessages !== null) {
    //         socket.current.emit('send-message', sendMessages)     
    //     }
    // }, [sendMessages])

    // Get the message from socket server
    // useEffect(() => {
    //     socket.current.on("receive-message", (data) => {
    //         console.log(data)
    //         setReceiveMessages(data)
    //     });
    // }, []);

    // useEffect(() => {
    //     if (sendMessages !== null) {
    //         socket.current.emit("send_message", sendMessages)
    //     }
    // }, [sendMessages])

    // useEffect(() => {
    //     socket.current.on("receive_message", (data) => {
    //         console.log(data)
    //         setReceiveMessages(data)
    //     })
    // }, [socket])

    useEffect(() => {
        //if admin id not needed set the user state to 'admin'
        if (CurrentUser() !== 'admin') {
            axios.get('/api/user/data').then(res => {
                setUser(res.data.data);
                // setLoading(false)
            })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setUser('admin');
        }
    }, [])

    const joinRoom = (chat) => {
        setCurrentChat(chat)
        if (user === 'admin' && chat.id !== undefined) {
            socket.current.emit("join_room", chat.id)
        }
    }

    const [searchUser, setSearchUser] = useState('')

    if (loading) return loadingUI(loading)

    return (
        <div className='all_body'>
            <div><Top_Bar /></div>
            <div className='ad_nav_size'><Admin_Navbar /></div>
            <div className='Chat_Admin'>
            <div className='Admin_E_Dalaw_Main'>
                <div className='Chat' style={{ marginLeft: '30px', backgroundColor: 'white' }}>
                    {/* Left Side */}
                    <div className="Left-side-chat">
                        <LogoSearch setSearchUser={setSearchUser}/>
                        <div className="Chat-container">
                            <h2>Chats</h2>
                            <div className="Chat-list">
                                {chats.map((chat) => (
                                    // <div onClick={() => {
                                    //     setCurrentChat(chat)
                                    //     joinRoom(chat)
                                    // }} key={chat.id}>
                                    <div onClick={() => joinRoom(chat)} key={chat.id}>
                                        <Conversation
                                            data={chat}
                                            currentUserId={CurrentUser() === 'admin' ? 'admin' : user.id}
                                            search={searchUser}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right Side */}
                    <div className="Right-side-chat">
                        {/* Chat body */}
                        <ChatBox
                            socket={socket}
                            chat={currentChat}
                            currentUser={CurrentUser() === 'admin' ? 'admin' : user.id}
                            setSendMessages={setSendMessages}
                            receiveMessages={receiveMessages}
                        />
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Chat
