import React, { useState } from 'react'

const geoModalFunc = (data, pdlData) => {
    const province = 'Bulacan'
    const city = 'Baliwag'
    const city1 = 'Baliuag'
    let ctr = 0
    let temp = []

    // pdlData && pdlData.map((item) => {
    //     if (data.barangay === 'Outside Bulacan') {
    //         if (item.province.toLowerCase() !== province.toLowerCase()) {
    //             temp.push(item)
    //         }
    //     } else if (data.barangay.replaceAll(' ', '').toLowerCase() === item.barangay.replaceAll(' ', '').toLowerCase()) {
    //         temp.push(item)
    //         ctr += 1
    //         console.log('111')
    //     } else if (data.barangay === 'Outside Baliwag') {
    //         if (item.province.toLowerCase() === province.toLowerCase()) {
    //             if (item.city.toLowerCase() !== city.toLowerCase()) {
    //                 ctr += 1
    //                 console.log('11')
    //             } else if (item.city.toLowerCase() !== city1.toLowerCase()) {
    //                 ctr += 1
    //                 console.log(ctr)
    //             }
    //         }
    //         if (ctr === 1) {
    //             temp.push(item)
    //         }
    //     }
    // })

    pdlData && pdlData.map((item) => {
        if (data.barangay === 'Outside Bulacan') {
            if (item.province.toLowerCase() !== province.toLowerCase()) {
                temp.push(item)
            }
        } else if (data.barangay.replaceAll(' ', '').toLowerCase() === item.barangay.replaceAll(' ', '').toLowerCase()) {
            temp.push(item)
        } else if (data.barangay === 'Outside Baliwag') {
            if (item.province.toLowerCase() === province.toLowerCase()) {
                if (item.city.toLowerCase() === city.toLowerCase()) {
                    ctr += 1
                } else if (item.city.toLowerCase() === city1.toLowerCase()) {
                    ctr += 1 
                }
                if (ctr === 0) {
                    temp.push(item)
                }
            }
        }
    })

    return temp
}

export default geoModalFunc