
import axios from 'axios'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'

const CarouselModal = ({ data, closeModal }) => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [preview, setPreview] = useState([])
    const [errors, setErrors] = useState({})

    const handleImage = (e) => {
        //setSelectedFiles(e.target.value[0])
        e.persist();
        setSelectedFiles({ image: e.target.files[0] });
        if(e.target.files) {
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setPreview(filesArray);
            Array.from(e.target.files).map((file) => URL.revokeObjectURL(file))
        }
    }

    const renderPhoto = (src) => {
        // console.log(src.image.name)
        // return <img src={src.image.name} />
        return src.map((photo) => {
            // console.log(photo)
            return <img src={photo} key={photo} style={{width: '100%'}}/>
        })
    }

    const handleAdd = (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData();
        formData.append('image', selectedFiles.image);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/admin/carousel/add`, formData).then(res => {
                if (res.data.success) {
                    setLoading(false)
                    swal("Success", res.data.msg, "success");
                    setTimeout((_) => window.location.assign("/Admin_Edit_Carousel"), 1000);
                }
                else {
                    setLoading(false)
                    console.log(res.data.msg)
                    const newErrors = res.data.msg
                    swal("Warning", "Invalid Credentials", "warning");
                    setErrors(newErrors)
                }
            });
        });
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData();
        formData.append('image', selectedFiles.image);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/admin/carousel/update/${data.id}`, formData).then(res => {
                if (res.data.success) {
                    setLoading(false)
                    swal("Success", res.data.msg, "success");
                    setTimeout((_) => window.location.assign("/Admin_Edit_Carousel"), 1000);
                }
                else {
                    setLoading(false)

                    const newErrors = res.data.msg
                    swal("Warning", "Invalid Credentials", "warning");
                    setErrors(newErrors)
                }
            });
        });
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Details</p>
                </div>
                <div className='modalBody'>
                    <div style={{ overflowY: 'scroll', maxHeight: '400px'}}>{Object.keys(preview).length > 0 && renderPhoto(preview)}</div>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload Image: </Form.Label>
                        <Form.Control type="file" name="image" required onChange={handleImage} />
                    </Form.Group>
                    <span className='errorMsg'>{errors.image}</span>
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' onClick={() => closeModal(false)} style={{ backgroundColor: 'crimson' }}>Cancel</button>
                    {loading ? (
                        <button
                            className='submitbtn'
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                            <ClipLoader color={'white'} loading={loading} size={25} />
                        </button>
                    ) : (
                        <button className='closebtnModal' onClick={data === null ? handleAdd : handleUpdate}>Save</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CarouselModal