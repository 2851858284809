import React, {Component} from 'react';

class Timer extends Component {
    state = {};
    render(){
  return (
    <div className='App'>
      <h1>TIMER</h1>
        <h2>{this.props.date.toLocalTimeString()} </h2>
    </div>
  )
}
}

export default Timer;