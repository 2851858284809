import React, { useState } from 'react'
import HelpDesk from "../components/HelpDesk";
import '../styles/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import Head_Banner from "../components/Banner";
import axios from 'axios';
import swal from 'sweetalert';
import SetCookie from '../hooks/setCookie';
import RemoveCookie from '../hooks/removeCookie';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ContactFooter from "../components/Con_Footer";
import ForgotPass from '../components/Modal/ForgotPass';
import { ClipLoader } from 'react-spinners';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';

function Login() {

  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false)
  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    error_list: [],
  })

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  }

  const loginSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      email: loginInput.email,
      password: loginInput.password
    }
    // console.log(isChecked)

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/user/login`, data).then(res => {
        if (res.data.success) {
          RemoveCookie("userToken");
          //Set Cookie
          SetCookie("userToken", JSON.stringify({
            userType: "customer",
            token: res.data.token,
          }), isChecked);
          setLoading(false);
          // SetCookie("userToken", JSON.stringify(res.data.token));
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/"), 2000);
        }
        else if (res.data.success === false) {
          setLoading(false);
          swal("Warning", res.data.msg, "warning");
          setLogin({ ...loginInput, error_list: [] })
        }
        else {
          setLogin({ ...loginInput, error_list: res.data.msg })
          setLoading(false);
        }
      });
    });

  }

  const [showPass, setShowPass] = useState(false)
  const handleShow = () => {
    setShowPass(!showPass)
  }

  // if (loading) return loadingUI(loading)

  return (
    <div className={openModal ? 'loginCrop' : 'loginNotCrop'}>
      <Navbar />
      <div className='Banner'><Head_Banner /></div>
      <div className='Login_Body'>

        <div className='Login_title'><h2>Sign In</h2></div>

        <div className='Login_Row'>
          <div className='Login_left'>
            <h2 className='Login_Title_Welcome'> Welcome to Baliwag Municipal Jail </h2>
            <p className='Login_SubTitle'> Keep in touch with us by entering your email address and password </p>
            <form onSubmit={loginSubmit}>
              <div className='Login_Inputs'>
                <input className='Login_Email' style={{ padding: '5px' }} type="text" name="email" onChange={handleInput} value={loginInput.email} placeholder='Email Address' />
                <span>{loginInput.error_list.email}</span>
              </div>
              <div className='Login_Inputs' style={{position:'relative'}}>
                <input className='Login_Pass' style={{ padding: '5px' }} type={showPass ? 'text' : 'password'} name="password" onChange={handleInput} value={loginInput.password} placeholder='Password' />
                <label className='loginShowPass' onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff/> : <IoIcons.IoMdEye/>}</label>
                <span>{loginInput.error_list.password}</span>
              </div>
              <div className='Login_RememberMe'>
                <div className='Login_chk_box'>
                  <input type="checkbox" className='Remember1' id="Remember1" name="Remember1" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                  <label className="Login_Remember"> Remember me</label>
                </div>
                <div className='Login_Forgot'><div onClick={() => setOpenModal(true)}>Forgot Password?</div></div>
              </div>
              {loading ? (
                <button
                  className='Login_Signin'>
                  <ClipLoader color={'white'} loading={loading} size={25} />
                </button>
              ) : (
                <button className='Login_Signin' type="submit"> Login</button>
              )}
              {/* <button className='Login_Signin' type="submit"> Login</button> */}
            </form>
          </div>

          <div className='Login_right'>
            <label className='Login_Signup_Title'>New Here?</label>
            <p className='Login_Signup_SubTitle'>Sign up to unlock other features</p>
            <button className='Login_Signup_button'><Link to="/Sign_Up"> Sign Up</Link></button>
          </div>

        </div>
        <div><HelpDesk /></div>
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
      {openModal && <ForgotPass closeModal={setOpenModal} />}
    </div>
  )
}

export default Login