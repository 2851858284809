import React, { useState, useEffect } from 'react'
import "../../css/Admin_Report_Management.css"

import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';
import swal from 'sweetalert';
import ModalConfirmation from '../../components/Modal/ModalConfirmation';
import HelpdeskDetails from '../../components/Modal/HelpdeskDetails'

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import HelpDeskList from '../../components/Export/HelpDeskList';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

const Admin_Inquiries_Archive = () => {
    const [request, setRequest] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    useEffect(() => {
        setLoadingPage(true)
        getPosts()
    }, [])

    const getPosts = () => {
        try {
            axios.get(`/api/admin/helpdesk/archive`).then(res => {
                setRequest(res.data.data)
                setLoadingPage(false)
            });
        } catch (error) {
            console.log(error)
        }
    }

    //modal
    const [show, setShow] = useState(false)
    const [itemId, setItemId] = useState("")

    const handleRestore = (id) => {
        setShow(true)
        setItemId(id)
    }

    const handleRestoreItem = () => {
        setLoading(true)
        axios.post(`/api/admin/helpdesk/restore/${itemId}`).then(res => {
            setLoading(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Admin_Inquiries_Archive"), 1000);
        });
    }

    //test
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currrentPage, setCurrentPage] = useState(1);

    const end = currrentPage * itemsPerPage;
    const start = end - itemsPerPage;


    return (
        <div className='all_body'>
            <div><Top_Bar /></div>
            <div className='ad_nav_size'><Admin_Navbar /></div>
            {loadingPage ? loadingUI(loadingPage) : (
                <div className='Admin_E_Dalaw_Main'>
                    <div><SQB /></div>
                    <HelpDeskList data={request} filename='InquiriesArchived.csv' />
                    <ModalConfirmation title="Restore Request" body="Are you sure you want to restore this request?" setShow={setShow} show={show} yesFunc={handleRestoreItem} loading={loading} />
                    <div className='Admin_Inqui_Con'>
                        <Styles>
                            <div className='profile' style={{ width: '90%', display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                <p className='pageHeader'>HELPDESK ARCHIVE</p>
                                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                            </div>
                        </Styles>
                        <table className='styled-table marginContent'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>No.</th>
                                    <th style={{ textAlign: "center" }}>Name</th>
                                    <th style={{ textAlign: "center" }}>Contact #</th>
                                    <th style={{ textAlign: "center" }}>Email</th>
                                    <th style={{ textAlign: "center" }}>Date Submitted</th>
                                    <th style={{ textAlign: "center" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {request && request.slice(start, end).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope='row'>{start + index + 1}</th>
                                            <td>{item.name}</td>
                                            <td>{item.contactNumber}</td>
                                            <td>{item.email}</td>
                                            <td>{dateFormat(item.created_at, 'short')}</td>
                                            <td className='actionstatus'>
                                                <button className='acceptbtn' onClick={() => handleRestore(item.id)}>Retrieve</button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            data={request}
                            itemsPerPage={itemsPerPage}
                            currrentPage={currrentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Admin_Inquiries_Archive