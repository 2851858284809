import axios from 'axios'
import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import emailjs from 'emailjs-com'

const DeclineVerification = ({ closeModal, data }) => {

    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [specificReason, setSpecificReason] = useState('')

    const handleChange = (e) => {
        e.persist();
        setReason(e.target.value);
        // if (e.target.value === 'Others') {
        //     setDisabled(false)
        //     setReason(e.target.value);
        // } else {
        //     setReason(e.target.value);
        //     setDisabled(true)
        // }
    }

    const handleDeclineItem = (e) => {
        e.preventDefault()

        // let reasonTemp = ''
        // if(reason === '' && specificReason === ''){
        //     reasonTemp = 'Unable to process your request due to some circumstances.'
        // } else if(reason === 'Others' && specificReason !== ''){
        //     reasonTemp = specificReason
        // } else if(reason === 'Others' && specificReason === ''){
        //     reasonTemp = 'Unable to process your request due to some circumstances.'
        // } else {
        //     reasonTemp = reason
        // }

        let emailTemp = {
            // name: data.fname,
            header: `Your account verification has been declined!`,
            header_body: `Greetings! We are sorry to inform you that your account request has been declined due to various reasons.`,
            email: data.email,
            reason: reason === '' ? 'Unable to process your request due to some circumstances.' : reason,
            specificReason: specificReason
        }

        setLoading(true)
        axios.post(`/api/admin/user/decline/${data.id}`).then(res => {
            emailjs.send('service_bqoeool', 'template_flrmaaf', emailTemp, '3EVvUb1f8h3EPOnhU').then(response => {
                swal("Success", res.data.msg, "success");
                setLoading(false)
                setTimeout((_) => window.location.assign('/Admin_Pending_Users'), 1000);
            }).catch(err => {
                console.log(err)
                swal("Error", "Please try again!", "error");
                setLoading(false)
            });
        });
        // console.log(emailTemp)
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Decline</p>
                </div>
                <div className='modalBody'>
                    <form>
                        {/* <div className='reasonForm'>
                            <label>Reason: <span style={{ color: 'gray' }}>Optional</span></label>
                            <textarea name='objective' value={reason} onChange={handleChange} placeholder="Write your reason..." />
                        </div> */}
                        <div className='reasonForm'>
                            <label className="item">Select Reason: <span style={{ color: "red" }}>*</span></label>
                            <select className='right' name="gender" required style={{ fontSize: '15px' }} value={reason} onChange={handleChange}>
                                <option value="">None</option>
                                <option value="ID is not a valid ID">ID is not a valid ID</option>
                                <option value="Information in the ID does not match with the information in your request">Information in the ID does not match with the information in your request</option>
                                <option value="Information is not readable or blurry, Try to take a clearer picture.">Information is not readable or blurry, Try to take a clearer picture.</option>
                                <option value="PDL does not exist">PDL does not exist</option>
                                {/* <option value="Others">Others</option> */}
                            </select>
                        </div>
                        <div className='reasonForm' style={{marginTop:'10px'}}>
                            <label>Specific Reason: <span style={{ color: 'gray' }}>Optional</span></label>
                            <textarea name='objective' value={specificReason} onChange={(e) => setSpecificReason(e.target.value)} placeholder="Write your message here..." />
                        </div>

                    </form>
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' style={{ backgroundColor: 'crimson' }} onClick={() => closeModal(false)}>Close</button>
                    {loading ? (
                        <button
                            className='submitbtn'
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                            <ClipLoader color={'white'} loading={loading} size={25} />
                        </button>
                    ) : (
                        <button className='closebtnModal' onClick={handleDeclineItem}>Send</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DeclineVerification