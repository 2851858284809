import React, {useState} from "react";

import {styles} from './styles'
import './chatUser.css'
import * as IoIcons from 'react-icons/io';

const Avatar = props => {
    const [hovered, setHovered] = useState(false)
    return (
        <div style={ props.style }>
            {/* <div
              className="transition-3"
              style={{
                ...styles.avatarHello,
                ...{opacity: hovered ? '1' : '0'},
                ...{fontFamily: 'Times New Roman'}
              }}
            >
                Chat with BJMP
            </div>
            <div
              className="transition-3"
              id="logoBjmp"
              onClick={() => props.onClick && props.onClick()}
              onMouseEnter={()=>setHovered(true)} 
              onMouseLeave={()=>setHovered(false)} 
              style={{
                ...styles.chatWithMeButton,
                ...{border: hovered ? '1px solid #3569c9d3' : '4px solid #1950c7ef'} 
            }}>

            </div> */}
            {/* <div
              className="transition-3"
              style={{
                ...styles.avatarHello,
                ...{opacity: hovered ? '1' : '0'},
                ...{fontFamily: 'Times New Roman'}
              }}
            >
                Chat with BJMP
            </div> */}
            <div
              className="transition-3"
              onClick={() => props.onClick && props.onClick()}
              onMouseEnter={()=>setHovered(true)} 
              onMouseLeave={()=>setHovered(false)} 
              style={{
                ...styles.chatWithMeButton,
                ...{width: hovered ? '240px' : '200px'} 
            }}>
              <IoIcons.IoMdChatbubbles style={{ marginRight: '10px' }} />
              Let's Chat!
            </div>
        </div>
    )
}

export default Avatar