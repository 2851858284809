import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Head_Banner from "../components/Banner";
import Footer from '../components/Footer';
import MapGeoTag from '../components/GeoTag/MapGeoTag';
import Navbar from '../components/Navbar';
import loadingUI from '../hooks/loadingUI';
import "../styles/Geotag.css";

function GeoTag() {

  const [loading, setLoading] = useState(true)
  const [markers, setMarkers] = useState({})
  const [isActive, setIsActive] = useState("")

  useEffect(() => {
    try {
      getVmgo()
      axios.get('/api/user/geoTag/getAllPin').then(res => {
        setMarkers(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getVmgo = () => {
    try {
      axios.get(`/api/admin/vmgo/get`).then(res => {
        if (res.data.data !== null) {
          setIsActive(res.data.data[0].isActive)
        }
      });
    } catch (error) {
      console.log(error)
    }
  }


  if (loading) return loadingUI(loading)

  return (
    <div>
      <Navbar />
      <div className='Banner'><Head_Banner /></div>
      <div className="geo_body">
        <div className='geo_title'><h2>GEO-TAGGING</h2></div>
        <div className='geo_sub_title'> Geo-Tagging of PDLs</div>
        {isActive == '0' ? (
          <div className='geo_container'>
          <div className='geo_map'>
            <MapGeoTag markers={markers} />
          </div>
          <div className='geo_info'>
            <table className='styled-table-map'>
              <thead>
                <tr style={{ position: 'sticky', top: '0' }}>
                  <th style={{ textAlign: "center" }}>Barangay</th>
                  <th style={{ textAlign: "center" }}>No. of PDL</th>
                </tr>
              </thead>
              <tbody>
                {markers && markers.map((item, index) => {
                  return (
                    <tr key={index} className={index % 2 == 0 ? "odd" : "even"}>
                      <td>{item.barangay}</td>
                      <td>{item.pdl_count}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        ) : (
          <div className='geo_container'><h1 style={{padding:'10px', height:'400px', display:'flex', alignItems:'center'}}>The Admin disabled the viewing of GeoTagging</h1></div>
        )}
      </div>
      <div><Footer /></div>
    </div>
  )
}

export default GeoTag