import React from 'react'
import { useRef, useState, useEffect } from "react";
import "../css/Admin_Navbar.css"
import BJMP_Logo from "../../../assets/bjmp_logo.png";
import { Link, NavLink } from 'react-router-dom';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';



function Admin_Navbar() {
    const [img, setImg] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        try {
            axios.get(`/api/admin/logo/getAll`).then(res => {
                setImg(res.data.data)
                setLoading(false)
                // console.log(res.data.data)
            });
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }, [])

    return (
        <div className='Admin_Nav_Body'>
            {/* --------TESTING--------- */}

            <div className='Admin_Nav_Left'>
                <div className='Admin_Title'>
                    <div className='Dash_logo'>
                        {/* <img src={BJMP_Logo} alt="" /> */}
                        {/* {img && img.map((item, i) => {
                            if (i === 0) {
                                return (<img key={i} src={item.image} alt="" className="inner-image" />)
                            } 
                        })} */}
                        {img.length !== 0 ? <img src={img[0].image} alt="" /> : <div><ClipLoader color={'white'} loading={loading} size={25} /></div>}
                    </div>
                    <label className='Admin_Header'>Bureau of Jail Management and Penology</label>
                </div>
                <div className='supBody'>
                    <div className='ad_dash_messenger'>
                        <div className='ad_dash'>
                            {/* <MdIcons.MdOutlineWindow style={{ marginRight: '5px' }} />
                            <Link to="/Admin_Dashboard">
                                <label> DASHBOARD </label>
                            </Link> */}
                            <NavLink className={(nav) => "adminActiveTop_" + nav.isActive} to='/Admin_Dashboard' > 
                                <MdIcons.MdOutlineWindow style={{ marginRight: '5px' }} />
                                <label> DASHBOARD </label> 
                            </NavLink>
                        </div>

                        <div className='ad_messenger'>
                            {/* <IoIcons.IoMdChatbubbles style={{ marginRight: '5px' }} />
                            <Link to="/Admin_Messenger_Application">
                                <label style={{ fontSize: '16px' }}> BJMP MESSENGER </label>
                            </Link> */}
                            <NavLink className={(nav) => "adminActiveTop_" + nav.isActive} to='/Admin_Messenger_Application' > 
                                <IoIcons.IoMdChatbubbles style={{ marginRight: '5px' }} />
                                <label> BJMP MESSENGER </label> 
                            </NavLink>

                        </div>

                    </div>
                    <div className='Admin_Nav_buttons'>

                        <div className='Con_Man'>
                            <label> E-DALAW MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus' ><div className='navWrapflex'><MdIcons.MdDone style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_E_Dalaw_Accepted' > ACCEPTED REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPending style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_E_Dalaw_Pending' > PENDING REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdOutlineClose style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_E_Dalaw_Declined' > DECLINED REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdHourglassFull style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_E_Dalaw_History' > E-DALAW HISTORY </NavLink></div> </button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> FACE-TO-FACE <br></br>
                                VISITATION MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdDone style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_FTF_Accepted' > ACCEPTED REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPending style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_FTF_Pending' > PENDING REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdOutlineClose style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_FTF_Declined' > DECLINED REQUEST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdHourglassFull style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_FTF_History' > FACE TO FACE HISTORY </NavLink></div> </button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> CONTENT MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdAddToQueue style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Add_Post' > ADD POST </NavLink></div></button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdEdit style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Manage_Post' > MANAGE POST </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdSettings style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Edit_VMGO' > EDIT VMGO </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdWebAsset style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Edit_Carousel' > EDIT CAROUSEL </NavLink></div> </button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> PDL MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPersonAddAlt1 style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Add_PDL' > ADD PDL </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><IoIcons.IoMdPeople style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_PDL_Info' > PDL INFORMATION </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPersonRemoveAlt1 style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_PDL_Archive' > PDL ARCHIVE </NavLink></div> </button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> REPORT MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><IoIcons.IoMdPeople style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Users_Report' > USERS REPORT </NavLink></div></button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPending style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Pending_Users' > PENDING USERS </NavLink></div></button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdHelp style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Inquiries' > INQUIRIES </NavLink></div></button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> GEOTAG MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><IoIcons.IoMdPin style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_GeoTag_Management' > GEO TAG INFORMATION </NavLink></div> </button>
                            </div>
                        </div>

                        <div className='Con_Man'>
                            <label> ADMIN MANAGEMENT</label>
                            <div className='menu_buttons'>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPersonAddAlt1 style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Add_Admin' > ADD ADMIN </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdPersonAddAlt1 style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_Edit_Admin' > EDIT ADMIN </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdAdminPanelSettings style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_View_Admins' > VIEW ADMINS </NavLink></div> </button>
                                <button className='admin_menus'><div className='navWrapflex'><MdIcons.MdRecentActors style={{ marginRight: '5px' }} /><NavLink className={(nav) => "adminActive_" + nav.isActive} to='/Admin_View_Logs' > VIEW LOGS </NavLink></div> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Admin_Navbar