import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Timer from "./components/Timer";
// import { ContextProvider } from './pages/VideoCall/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* <ContextProvider> */}
     <App />
    {/* </ContextProvider> */}
  </React.StrictMode>

  
);
// ReactDOM.render(<Timer date={new Date()}/>,document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
