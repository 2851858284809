import React from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const ExportRequest = ({data, filename}) => {
  const headers = [
    {label: 'Status', key: 'status'},
    {label: 'First Name', key: 'fname'},
    {label: 'Middle Name', key: 'mname'},
    {label: 'Last Name', key: 'lname'},
    {label: 'Gender', key: 'gender'},
    {label: 'Contact Number', key: 'contactNo'},
    {label: 'Email', key: 'email'},
    {label: 'Address', key: 'address'},
    {label: 'PDL Name', key: 'pdl_name'},
    {label: 'PDL Relationship', key: 'pdl_relationship'},
    {label: 'Scheduled Date', key: 'date'},
    {label: 'Start Time', key: 'start_time'},
    {label: 'End Time', key: 'end_time'},
  ]

  const csvReport = {
    filename: filename,
    headers: headers,
    data: data
  }
  return (
    <div className='al_exportbtn'>
        <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>
  )
}

export default ExportRequest