import React, { useEffect, useState } from 'react'
import "../../css/Admin_Report_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios';
import Default from '../../../../assets/default_user.png'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';
import UsersReport from '../../components/Export/UsersReport';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
height: 40px;
background: rgb(63,177,251);
background: radial-gradient(circle, rgba(63,177,251,1) 0%, rgba(70,74,252,1) 100%);
display:flex;
justify-content:center;
align-items: center;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 24px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_Users_Report() {
  const [users, setUsers] = useState([])
  const [show, setShow] = useState(false)
  const [user, setUser] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const [pdlno, setPdlno] = useState('')

  const [userpdl, setUserpdl] = useState([])
  const [loading, setLoading] = useState(false)
  const [pdls, setPdls] = useState([])
  useEffect(() => {
    setLoadingPage(true)
    getUsers()
    getPdl()
  }, [])

  useEffect(() => {
    if (user) {
      getUserPdl()
    }
  }, [user])

  const getPdl = () => {
    try {
      axios.get(`/api/admin/pdl/getAll`).then(res => {
        setPdls(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getUsers = () => {
    try {
      axios.get(`/api/admin/user/getVerified`).then(res => {
        setUsers(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }
  const getUserPdl = () => {
    try {
      axios.get(`/api/admin/userpdl/getPdl/${user.id}`).then(res => {
        setUserpdl(res.data.data)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  const [searchPdl, setSearchPdl] = useState('')

  //user pdl
  const [itemsPerPagePdl, setItemsPerPagePdl] = useState(5);
  const [currentPagePdl, setCurrentPagePdl] = useState(1);

  const endPdl = currentPagePdl * itemsPerPagePdl;
  const startPdl = endPdl - itemsPerPagePdl;

  const handleSubmit = (e) => {
    e.preventDefault()
    ///admin/userpdl/store

    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('pdl_no', pdlno);

    setLoading(true)
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/userpdl/store`, formData).then(res => {
        if (res.data.success) {
          setLoading(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Users_Report"), 1500);
        }
        else {
          setLoading(false)
          swal("Warning", res.data.msg, "warning");
        }
      });
    });
  }

  const [search, setSearch] = useState('')

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <UsersReport data={users} filename='RegisteredUsers.csv' />
          <div className='pdl_info_container'>
            <div className='Admin_Users_Rep_Con'>
              <div className='Users_Report_Content'>
                <div className='UR_List' style={{ width: '30%', padding: '10px', position: 'relative' }}>
                  <Styles>
                    <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                      <p className='pageHeader'>REGISTERED USERS</p>
                    </div>
                  </Styles>
                  <div className='searchDiv' style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}>
                    <input className='searchInput' style={{ width: '100%' }} type='text' placeholder='Search for email or first name' onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div style={{ overflowY: 'scroll', maxHeight: '390px', width: '100%' }}>
                    <table className='styled-table marginContent' style={{ marginTop: '0px' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>Name</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users && users.filter((val) => {
                          if (search == '') {
                            return val
                          } else if (val.fname.toLowerCase().includes(search.toLowerCase()) || val.email.toLowerCase().includes(search.toLowerCase())) {
                            return val
                          }
                        }).slice(start, end).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{`${item.fname} ${item.lname}`}</td>
                              <td className='actionstatus'>
                                <button className='detailsbtn' onClick={() => { setShow(true); setUser(item) }}>Details</button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    data={users}
                    itemsPerPage={itemsPerPage}
                    currrentPage={currrentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>

                <div className='UR_Details' style={{ width: '40%' }}>
                  <div className='Acc_Info'>ACCOUNT INFORMATION</div>
                  {show ? (
                    <div className='Acc_Display'>
                      <div className='Main_Details'>
                        {user.profilePic === null ? (<img className='Acc_Pic' src={Default} />) : (<img className='Acc_Pic' src={user.profilePic} />)}
                        {/* <div className='Acc_Pic'></div> */}
                        <div className='Main_Label_Con'>

                          <div className='Label_Con'>
                            <label className='Label_Name'>Name: </label>
                            <label className='Acc_Dis_Name'>{`${user.fname} ${user.lname}`}</label>
                          </div>

                          <div className='Label_Con'>
                            <label className='Label_Email'>Gender: </label>
                            <label className='Acc_Dis_Email'>{user.gender}</label>
                          </div>

                          <div className='Label_Con'>
                            <label className='Label_Phone'>Contact #:  </label>
                            <label className='Acc_Dis_Phone'>{user.contactNo}</label>
                          </div>
                        </div>
                      </div>
                      <div className='rem_Details'>
                        <div className='pt_one'>
                          <div className='Label_Add_Con'>
                            <label className='Label'>Address: </label>
                            <label className='Acc_Dis_Add'>{`${user.street}, ${user.barangay}, ${user.city}, ${user.province}, ${user.country}`}</label>
                          </div>

                          <div className='Label_Add_Con'>
                            <label className='Label'>Email:  </label>
                            <label className='Acc_Dis_Gen'>{user.email}</label>
                          </div>
                          <div className='pt_two'>
                            <div className='Label_Add_Con'>
                              <label className='Label'>Linked PDL: </label>
                              <label className='Acc_Dis_Gen'>{user.PdlName}</label>
                            </div>

                            <div className='Label_Add_Con'>
                              <label className='Label'>Relationship:  </label>
                              <label className='Acc_Dis_Gen'>{user.PdlRelation}</label>
                            </div>

                            <div className='Label_Add_Con'>
                              <label className='Label'>Link more PDL here:  </label>
                              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <div>
                                  <input className='linkPdlInput' type='text' name='pdl_no' value={pdlno} onChange={(e) => setPdlno(e.target.value)} placeholder='Input PDL # here' required />
                                  {loading ? (
                                    <button className='linkPdlBtn' type='submit'><ClipLoader color={'white'} loading={loading} size={25} /></button>
                                  ) : (
                                    <button className='linkPdlBtn' type='submit'>ADD</button>
                                  )}

                                </div>
                              </form>
                            </div>
                            {/* <div style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}>
                              <ShowEntry itemsPerPage={itemsPerPagePdl} setItemsPerPage={setItemsPerPagePdl} />
                            </div> */}
                            <table className='styled-table marginContent' style={{ marginTop: '30px' }}>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "center" }}>PDL #</th>
                                  <th style={{ textAlign: "center" }}>Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userpdl && userpdl.slice(startPdl, endPdl).map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.pdlId}</td>
                                      <td>{item.pdl_name}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                            <Pagination
                              data={userpdl}
                              itemsPerPage={itemsPerPagePdl}
                              currentPage={currentPagePdl}
                              setCurrentPage={setCurrentPagePdl}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                </div>

                <div className='UR_List' style={{ width: '30%', padding: '10px', position: 'relative' }}>
                  <Styles>
                    <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                      <p className='pageHeader'>PDL</p>
                    </div>
                  </Styles>
                  <div className='searchDiv' style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}>
                    <input id='unregisteredSearch' style={{ width: '100%' }} className='searchInput' type='text' placeholder='Search pdl# or first name' onChange={(e) => setSearchPdl(e.target.value)} />
                  </div>
                  <div style={{ overflowY: 'scroll', maxHeight: '390px', width: '100%' }}>
                    <table className='styled-table marginContent' style={{ marginTop: '0' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>Pdl Number</th>
                          <th style={{ textAlign: "center" }}>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdls && pdls.filter((val) => {
                          if (searchPdl == '') {
                            return val
                          } else if (val.fname.toLowerCase().includes(searchPdl.toLowerCase()) || val.pdl_no.toLowerCase().includes(searchPdl.toLowerCase())) {
                            return val
                          }
                        }).slice(startPdl, endPdl).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.pdl_no}</td>
                              <td style={{ padding: '20px' }}>{`${item.fname} ${item.lname}`}</td>
                              {/* <td className='actionstatus'>
                          <button className='detailsbtn' onClick={() => { setShow(true); setPdl(item) }}>Details</button>
                        </td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    data={pdls}
                    itemsPerPage={itemsPerPagePdl}
                    currrentPage={currentPagePdl}
                    setCurrentPage={setCurrentPagePdl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Admin_Users_Report