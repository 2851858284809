import React from 'react'
import './detailsModal.css'

const UserIdModal = ({data, closeModal, port}) => {
  return (
    <div className='modalBackground'>
      <div className='modalContainer'>
        <div className='modalTitle'>
          <p className='modalP'>User ID</p>
        </div>
        <div className='modalBody'>
          {data.imgId && <img src={`${port}${data.imgId}`} style={{width: '100%'}}/>}
          {data.img && <img src={`${port}${data.img}`} style={{width: '100%'}}/>}
        </div>
        <div className='modalFooter'>
          <button className='closebtnModal' onClick={()=>closeModal(false)}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default UserIdModal