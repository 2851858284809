import React, { useState } from 'react'
import "../../css/Admin_Content_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import axios from 'axios'
import swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'
import LogoModal from '../../components/Modal/LogoModal'
import styled from 'styled-components';
import loadingUI from '../../../../hooks/loadingUI'

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function Admin_Edit_VMGO() {
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const [image, setImage] = useState([])
  const [form, setForm] = useState({
    mission: '',
    vision: '',
    goal: '',
    objective: '',
  })

  const [formCon, setFormCon] = useState({
    email: '',
    address: '',
    trunkline: '',
    phone: '',
  })

  const [selectedItem, setSelectedItem] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const getVmgo = () => {
    try {
      axios.get(`/api/admin/vmgo/get`).then(res => {
        if (res.data.data !== null) {
          setForm(res.data.data[0])
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getContactUs = () => {
    try {
      axios.get(`/api/admin/contact/get`).then(res => {
        if (res.data.data !== null) {
          setFormCon(res.data.data[0])
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getLogo = () => {
    try {
      axios.get(`/api/admin/logo/getAll`).then(res => {
        setLoadingPage(false)
        setImage(res.data.data)
        //console.log(res.data.data)

      });
    } catch (error) {
      console.log(error)
    }
  }

  useState(() => {
    setLoadingPage(true)
    getVmgo()
    getContactUs()
    getLogo()
  }, [])

  const setField = (field, value) => {
    setForm({
      ...form, [field]: value
    })
  }

  const setFieldCon = (field, value) => {
    setFormCon({
      ...formCon, [field]: value
    })
  }

  const handleContact = (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData();
    formData.append('email', formCon.email);
    formData.append('address', formCon.address);
    formData.append('phone', formCon.phone);
    formData.append('trunkline', formCon.trunkline);

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/contact/update`, formData).then(res => {
        if (res.data.success) {
          setLoading(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Edit_VMGO"), 1000);
        }
        else {
          setLoading(false)
          swal("Warning", "Invalid Credentials", "warning");
        }
      });
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData();
    formData.append('mission', form.mission);
    formData.append('vision', form.vision);
    formData.append('goal', form.goal);
    formData.append('objective', form.objective);

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/vmgo/update`, formData).then(res => {
        if (res.data.success) {
          setLoading(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Edit_VMGO"), 1000);
        }
        else {
          setLoading(false)
          swal("Warning", "Invalid Credentials", "warning");
        }
      });
    });
  }

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_Add_Post_Main'>
          <div><SQB /></div>
          <div className='Edit_VMGO_Con'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>Edit VMGO</p>
              </div>
            </Styles>
            <div className='VMGO_wrapper'>
              <form onSubmit={handleSubmit}>
                <div className='Edit_VM'>
                  <div className='Edit_Vision'>
                    <h2 className='Admin_VMGO_Title'>Vision</h2>
                    <div>
                      <textarea name='vision' value={form.vision} onChange={e => setField('vision', e.target.value)} required/>
                    </div>
                  </div>

                  <div className='Edit_Mission'>
                    <h2 className='Admin_VMGO_Title'>Mission</h2>
                    <div>
                      <textarea name='mission' value={form.mission} onChange={e => setField('mission', e.target.value)} required/>
                    </div>
                  </div>
                </div>
                <div className='Edit_GO'>
                  <div className='Edit_Goal'>
                    <h2 className='Admin_VMGO_Title'>Goal</h2>
                    <div>
                      <textarea name='goal' value={form.goal} onChange={e => setField('goal', e.target.value)} required/>
                    </div>
                  </div>

                  <div className='Edit_Objective'>
                    <h2 className='Admin_VMGO_Title'>Objectives</h2>
                    <div>
                      <textarea name='objective' value={form.objective} onChange={e => setField('objective', e.target.value)} required/>
                    </div>
                  </div>
                </div>
                <div className='vmgo_buttons'>
                  {loading ? (
                    <button
                      className='VMGO_Submit'
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                      <ClipLoader color={'white'} loading={loading} size={25} />
                    </button>
                  ) : (
                    <button className='VMGO_Submit' type='submit'>Apply Changes</button>
                  )}
                </div>
              </form>

              <div className='all_edit_con'>
                <div className='edit_img'>
                  <div className='edit_pic'><label>EDIT BANNER PICTURES</label></div>
                  <div className='edit_pictures'>
                    {image && image.map((item, index) => {
                      return (
                        <div className='picture_one_con' key={index}>
                          <img className="edit_picture_one" src={`${item.image}`} />
                          <div className='edit_pics_button'>
                            <button className='apply_edit_pic' onClick={() => { setOpenModal(true); setSelectedItem(item) }}>Change</button>
                            {/* <button className='cancel_edit_pic'>Cancel</button> */}
                          </div>
                        </div>
                      )
                    })}
                    {/* <div className='picture_two_con'>
                  <div className='edit_picture_two'></div>
                  <div className='edit_pics_button'>
                    <button className='apply_edit_pic'> Apply</button>
                    <button className='cancel_edit_pic'> Cancel</button>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
              <form onSubmit={handleContact}>
                <div className='footer_edit_con'>
                  <label className='edit_footer'>EDIT CONTACT US FOOTER </label>
                  <div className='edit_contact_footer'>
                    <div className='first'>
                      <div className='info'>
                        <label className='info_title'>Email: </label>
                        <input name='email' type='email' value={formCon.email} onChange={e => setFieldCon('email', e.target.value)} required className='info_desc'></input>
                      </div>
                      <div className='info'>
                        <label className='info_title'>Trunkline: </label>
                        <input name='trunkline' type='text' value={formCon.trunkline} onChange={e => setFieldCon('trunkline', e.target.value)} required className='info_desc'></input>
                      </div>
                    </div>
                    <div className='second'>
                      <div className='info'>
                        <label className='info_title'>Address: </label>
                        <input name='address' type='text' value={formCon.address} onChange={e => setFieldCon('address', e.target.value)} required className='info_desc'></input>
                      </div>
                      <div className='info'>
                        <label className='info_title'>Phone: </label>
                        <input name='phone' type='text' value={formCon.phone} onChange={e => setFieldCon('phone', e.target.value)} pattern="^[0-9 -()]*$" required className='info_desc'></input>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <button
                      className='VMGO_Submit'
                      style={{ width: '100px' }}>
                      <ClipLoader color={'white'} loading={loading} size={25} />
                    </button>
                  ) : (
                    <button className='VMGO_Submit' type='submit'>Apply Changes</button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {openModal && <LogoModal closeModal={setOpenModal} data={selectedItem} />}
    </div>
  )
}

export default Admin_Edit_VMGO
