import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getUser } from '../../api/ChatRequest';
import Default from '../../../../assets/default_user.png'
import './Conversation.css'
import { ClipLoader } from 'react-spinners';

const Conversation = ({ data, currentUserId, search }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const userId = data.members.find((id) => id !== currentUserId)
        const getUserData = async () => {
            try {
                if (userId !== 'admin') {
                    const { data } = await getUser(userId)
                    setUserData(data.data)
                    setLoading(false)
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getUserData()
    }, [])

    if (loading) return <ClipLoader color={'#6e9afaef'} loading={loading} size={100} />

    return (
        <>
        {userData.fname.toLowerCase().includes(search.toLowerCase()) && (
            <>
                <div className="conversation">
                {userData.profilePic === null ? (
                    <img
                        src={Default}
                        alt=""
                        className='conversationImg'
                        style={{ width: '50px', height: '50px' }}
                    />
                ) : (
                    <img
                        src={userData.profilePic}
                        alt=""
                        className='conversationImg'
                        style={{ width: '50px', height: '50px' }}
                    />
                )}
                
                <div className="conversationName">
                    {currentUserId !== 'admin' ? (<span>BJMP</span>) : (<span>{userData?.fname} {userData?.lname}</span>)}
                    {/* <span>{userData?.fname} {userData?.lname}</span> */}
                </div>
            </div>
            <hr style={{ width: "90%", marginLeft: '5%', border: "0.1px solid #ececec" }} />
            </>
        )}
            {/* <div className="conversation">
                {userData.profilePic === null ? (
                    <img
                        src={Default}
                        alt=""
                        className='conversationImg'
                        style={{ width: '50px', height: '50px' }}
                    />
                ) : (
                    <img
                        src={userData.profilePic}
                        alt=""
                        className='conversationImg'
                        style={{ width: '50px', height: '50px' }}
                    />
                )}
                
                <div className="conversationName">
                    {currentUserId !== 'admin' ? (<span>BJMP</span>) : (<span>{userData?.fname} {userData?.lname}</span>)} */}
                    {/* <span>{userData?.fname} {userData?.lname}</span>
                </div>
            </div>
            <hr style={{ width: "90%", marginLeft: '5%', border: "0.1px solid #ececec" }} /> */}
        </>
    )
}

export default Conversation



            // <div className="follower conversation">
            //     <div>
            //         <div className="online-dot"></div>
            //         <img
            //             src={Default}
            //             alt=""
            //             className='followerImage'
            //             style={{ width: '50px', height: '50px' }}
            //         />
            //         <div className="name" style={{fontSize: '0.8rem'}}>
            //             <span>{userData?.fname} {userData?.lname}</span>
            //             <span>Online</span>
            //         </div>
            //     </div>
            // </div>
            // <hr style={{ width: "85%", border: "0.1px solid #ececec"}}/>