import React, { useState } from 'react'
import "../css/Dash_Login.css"
import BJMP_Logo from "../../../assets/bjmp_logo.png";
import axios from 'axios';
import swal from 'sweetalert';
import SetCookie from '../../../hooks/setCookie';
import RemoveCookie from '../../../hooks/removeCookie';
import { ClipLoader } from 'react-spinners';
import * as IoIcons from 'react-icons/io';
import SuperAdmin from '../components/Modal/SuperAdmin';

function Admin_ForgotPassword() {

  const [loading, setLoading] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(true)
  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
    confirm_password: '',
    error_list: [],
  })

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  }

  const loginSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      email: loginInput.email,
      password: loginInput.password
    }

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/forgotPass`, data).then(res => {
        if (res.data.success) {
          setLoading(false);
          // SetCookie("userToken", JSON.stringify(res.data.token));
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Login"), 2000);
        }
        else if (res.data.success === false) {
          setLoading(false);
          swal("Warning", res.data.msg, "warning");
          setLogin({ ...loginInput, error_list: [] })
        }
        else {
          setLogin({ ...loginInput, error_list: res.data.msg })
          setLoading(false);
        }
      });
    });

  }

  const [showPass, setShowPass] = useState(false)
  const handleShow = () => {
    setShowPass(!showPass)
  }

  const [showConPass, setShowConPass] = useState(false)
  const handleShowCon = () => {
    setShowConPass(!showConPass)
  }

  return (
    <div className='ad_log_main'>
      {superAdmin ? <SuperAdmin closeModal={setSuperAdmin} type='forgotpass'/> : <></>}
      <div className='ad_log_body'>
        <div className='ad_welcome'>
          <div className='ad_logo'><img src={BJMP_Logo} alt="" className="inner-image_1" /></div>
          <label>BUREAU OF JAIL MANAGEMENT AND PENOLOGY - BALIWAG</label>
        </div>
        <div className='ad_input_log' style={{ paddingTop: '80px' }}>
          <label style={{ fontSize: '26px', fontFamily: 'Tw Cen MT' }}>Forgot Password</label>
          <form onSubmit={loginSubmit}>
            <div className='ad_Login_Inputs'>
              <input className='ad_Login_Email' type="email" name="email" onChange={handleInput} value={loginInput.email} placeholder='Email Address' required />
              <span>{loginInput.error_list.email}</span>
            </div>
            <div className='Login_Inputs' style={{ position: 'relative' }}>
              <input className='ad_Login_Pass' type={showPass ? 'text' : 'password'} name="password" onChange={handleInput} value={loginInput.password} required pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$' placeholder='Password' />
              <label className='loginShowPass' style={{ right: '13%', top: '35%' }} onClick={handleShow}>{showPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
              <span>{loginInput.error_list.password}</span>
            </div>
            <div className='Login_Inputs' style={{ position: 'relative' }}>
              <input className='ad_Login_Pass' type={showConPass ? 'text' : 'password'} name="confirm_password" onChange={handleInput} value={loginInput.confirm_password} placeholder='Confirm Password' required pattern={loginInput.password} />
              <label className='loginShowPass' style={{ right: '13%', top: '35%' }} onClick={handleShowCon}>{showConPass ? <IoIcons.IoMdEyeOff /> : <IoIcons.IoMdEye />}</label>
              <span>{loginInput.error_list.password}</span>
            </div>

            <div className='ad_Login_RememberMe'>
              {loading ? (
                <button className='ad_Login_Signin' type="submit"><ClipLoader color={'white'} loading={loading} size={25} /></button>
              ) : (
                <button className='ad_Login_Signin' type="submit"> Change Password</button>
              )}
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Admin_ForgotPassword