
import React, { useState, useRef, useEffect } from 'react'
import "../../css/Admin_Content_Management.css"
import { Editor } from '@tinymce/tinymce-react';
import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import styled from 'styled-components';
import axios from 'axios'
import swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'
import '../../css/contentadd.css'
import { useLocation } from 'react-router-dom';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

const Container = styled.div`
margin-top: 20px;
padding-top: 20px;
padding-left: 20px;
position: fixed !important;
left: 22%;
right: 2%;
border-radius: 25px;
font-size: 24px;
background: white;
height: 60%;
overflow: auto;
text-align: left;
`

function Admin_Edit_Post() {

  const [form, setForm] = useState({
    post_type: '',
    title: '',
    date_posted: '',
  })
  const [image, setImage] = useState([]);
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const data = location.state;
  
  const setField = (field, value) => {
    setForm({
      ...form, [field]: value
    })
  }
  const editorRef = useRef(null);

  useEffect(()=>{
    setForm(data)
    setImage({media_img: data.image})
  }, [])

  const handleImage = (e) => {
    e.persist();
    setImage({ media_img: e.target.files[0] });
  }

  const postSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    if (image.media_img === []) {
      setImage({ media_img: null })
    }

    const formData = new FormData();
    formData.append('media_img', image.media_img);
    formData.append('post_type', form.post_type);
    formData.append('title', form.title);
    formData.append('date_posted', form.date_posted);
    formData.append('body', editorRef.current.getContent());

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/admin/posts/edit/${data.id}`, formData).then(res => {
        if (res.data.success) {
          setLoading(false)
          swal("Success", res.data.msg, "success");
          setTimeout((_) => window.location.assign("/Admin_Manage_Post"), 1000);
        }
        else {
          setLoading(false)
          const newErrors = res.data.msg
          console.log(res.data)
          swal("Warning", "Invalid Credentials", "warning");
          setErrors(newErrors)
        }
      });
    });
  }

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      <div className='Admin_Add_Post_Main'>
        <div><SQB /></div>
        {/* <div className='Add_Post_Con'> */}

        <div className='Add_Post_Con'>
          <div className='add_post_wrapper'>
          <Styles>
            <div className='profile'>
              <p className='pageHeader'>EDIT POST</p>
            </div>
          </Styles>
            <form onSubmit={postSubmit} style={{marginTop:'60px'}} className='addPostForm marginContent'>
              <label>Type</label><br />
              <select className="contenttype" name="post_type" value={form.post_type} onChange={e => setField('post_type', e.target.value)} required>
                <option value="">Select Type</option>
                <option value="News">News</option>
                <option value="Announcement">Announcement</option>
              </select><br />
              <label>Title</label><br />
              <input type='text' className='contenttitle' name='title' value={form.title} onChange={e => setField('title', e.target.value)} required /><br />
              <label>Date (Month/Day/Year) </label><br />
              <input type='date' className='contentdate' name='date_posted' value={form.date_posted} onChange={e => setField('date_posted', e.target.value)} /><br /><br />
              <Editor
                apiKey='ijin9ekkez2e10rywn4kg7lc79p7wvf9da1p39l6i2lvik2t'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={data.body}
                init={{
                  height: 300,
                  menubar: true,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              /><br />
              <label className='SignUp_Upload_Label'>Upload Picture</label><br />
              <input type='file' name='media_img' onChange={handleImage} /><br />
              <span className='errorMsg'>{errors.media_img}</span>
              {loading ? (
                <button
                  className='submitbtn'
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                  <ClipLoader color={'white'} loading={loading} size={25} />
                </button>
              ) : (
                <input className='submitbtn' type='submit' value='Submit' style={{padding:'10px'}}/>
              )}
            </form>
          </div><br />
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default Admin_Edit_Post