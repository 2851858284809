import React, { useEffect, useState } from 'react'
import Carousel from "../components/Carousel";
import HelpDesk from "../components/HelpDesk";
import Head_Banner from "../components/Banner";
import '../styles/Announcement.css';
import AnnounceItem from "../components/AnnounceItems";
import { AnnounceList } from '../helpers/AnnounceList';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Pagination from '../components/Pagination/Pagination';
import axios from 'axios';
import AnnounceItems from '../components/AnnounceItems';
import dateFormat from '../hooks/dateFormat';
import loadingUI from '../hooks/loadingUI';
import ContactFooter from "../components/Con_Footer";



function Announcement() {

  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = () => {
    try {
      axios.get('/api/user/getAnnouncement').then(res => {
        // setForm(res.data.data[0]);
        // setLoading(false)
        setNews(res.data.announcement)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  //test
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  news.sort((a, b) => b.created_at.localeCompare(a.created_at));

  if (loading) return loadingUI(loading)

  return (
    <div>
      <Navbar />
      <div className='Ann_Body'>
        <div className='Banner'><Head_Banner /></div>

        <div className="ann_car"><Carousel /></div>
        <div className='ann_Title'><h2> ANNOUNCEMENT</h2></div>
        <div className="roww">
          <div className='announceList'>
          {news.length !== 0 ?
            news && news.slice(start, end).map((newsItem, key) => {
              return (
                <div key={key} className='column ann_leftt'>
                  <AnnounceItems
                    key={key}
                    id={newsItem.id}
                    name={newsItem.title}
                    date={newsItem.date_posted}
                    image={newsItem.media_img}
                    body={newsItem.body}
                    dateUp={dateFormat(newsItem.created_at, 'long')}
                  />
                </div>
              );
            })
            : (
              <p style={{ marginTop: "100px", marginBottom: "100px", fontSize: "30px", color: 'gray' }}>No Announcement Yet</p>
            )}
            <Pagination
              data={news}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        {/* <div className='Ann_Items'><AnnounceItem/></div> */}
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  )
}

export default Announcement