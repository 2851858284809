import React, { useEffect, useState } from 'react'
import Carousel from "../components/Carousel";
import '../styles/News.css'
import HelpDesk from "../components/HelpDesk";
import { NewsList } from '../helpers/NewsList';
import NewsItem from '../components/NewsItem';
import Head_Banner from "../components/Banner";
import axios from 'axios';
import loadingUI from '../hooks/loadingUI';
import dateFormat from '../hooks/dateFormat';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Pagination from '../components/Pagination/Pagination';
import ContactFooter from "../components/Con_Footer";

function News() {

  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let cancel
    const getUserData = () => {
      try {
        axios.get('/api/user/getNews', {
          cancelToken: new axios.CancelToken((c) => cancel=c),
        }).then(res => {
          // setForm(res.data.data[0]);
          // setLoading(false)
          setNews(res.data.news)
          setLoading(false)
        }).catch((e)=>{
          if(axios.isCancel(e))return console.log("Request Cancelled")
        });
      } catch (error) {
        console.log(error)
      }
    }
    getUserData()
    return () => {
      if(cancel){
          cancel()
      }
    }
  }, [])

  //test
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;
  
  news.sort((a, b) => b.created_at.localeCompare(a.created_at));

  if (loading) return loadingUI(loading)

  return (
    <div>
      <Navbar />
      <div className='news'>
        <div className='Banner'><Head_Banner /></div>
        <div className="news_car"><Carousel /></div>
        <div className='Title'> LATEST NEWS AND UPDATES</div>
        <div className='newsList'>
          {news.length !== 0 ?
            news && news.slice(start, end).map((newsItem, key) => {
              return (
                <NewsItem
                  key={key}
                  id={newsItem.id}
                  name={newsItem.title}
                  date={newsItem.date_posted}
                  image={newsItem.media_img}
                  body={newsItem.body}
                  dateUp={dateFormat(newsItem.created_at, 'long')}
                />
              );
            })
            : (
              <p style={{ marginTop: "100px", marginBottom: "100px", fontSize: "30px", color: 'gray' }}>No News Yet</p>
            )}
          <Pagination
            data={news}
            itemsPerPage={itemsPerPage}
            currrentPage={currrentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  );
}
<div><HelpDesk /></div>
export default News