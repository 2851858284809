import React from 'react'
import '../styles/AgencyLinks.css'
import Head_Banner from "../components/Banner";
import Navbar from '../components/Navbar';

function AgencyLinks() {
  return (
    <div>
        <Navbar />
        <Head_Banner />
        <div className='Agency_body'>
            <h2 className='Agency_Title'> Agency Links</h2>
                <div className='Agency_Content'>
                    <div className='columns_I'>
                        <a href="http://r1.bjmp.gov.ph/"> Region 1 <br></br><p>http://r1.bjmp.gov.ph</p></a>
						<a href="http://bjmpr2.org/"> Region 2 <br></br><p>http://bjmpr2.org</p></a>
						<a href="http://r3.bjmp.gov.ph/"> Region 3 <br></br><p>http://r3.bjmp.gov.ph</p></a>
						<a href="http://bjmpro4a.com/"> CALABARZON  <br></br><p>http://bjmpro4a.com</p></a>
						<a href="http://mimaropa.bjmp.gov.ph/"> MIMAROPA  <br></br><p>http://mimaropa.bjmp.gov.ph</p></a>
						<a href="http://r5.bjmp.gov.ph/"> Region 5 <br></br><p>http://r5.bjmp.gov.ph</p></a>
                    </div>
                    <div className='columns_II'>
                        <a href="http://www.bjmpr6.com/"> Region 6 <br></br><p>http://www.bjmpr6.com</p></a>
						<a href="http://r7.bjmp.gov.ph/"> Region 7 <br></br><p>http://r7.bjmp.gov.ph</p></a>
						<a href="http://www.bjmp8.com/"> Region 8 <br></br><p>http://www.bjmp8.com</p></a>
						<a href="http://bjmp9.info/"> Region 9 <br></br><p>http://bjmp9.info</p></a>
						<a href="http://r10.bjmp.gov.ph/"> Region 10 <br></br><p>http://r10.bjmp.gov.ph</p></a>
						<a href="http://r11.bjmp.gov.ph/"> Region 11 <br></br><p>http://r11.bjmp.gov.ph</p></a>
                    </div>
                    <div className='columns_III'>
                        <a href="http://r12.bjmp.gov.ph/"> Region 12 <br></br><p>http://r12.bjmp.gov.ph</p></a>
						<a href="https://www.bjmpcaraga.com/"> CARAGA  <br></br><p>https://www.bjmpcaraga.com</p></a>
						<a href="http://car.bjmp.gov.ph/"> CAR  <br></br><p>http://car.bjmp.gov.ph</p></a>
						<a href="http://ncr.bjmp.gov.ph/"> NCR  <br></br><p>http://ncr.bjmp.gov.ph</p></a>
						<a href="http://barmm.bjmp.gov.ph/"> BARMM  <br></br><p>http://barmm.bjmp.gov.ph</p></a>
                    </div>
                </div>
                <h2 className='Agency_Title'> Goverment Links</h2>
                <div className='Government_Content'>
                    <div className='gov_columns_I'>
                        <a href="http://www.gov.ph/"><br></br>GOV.PH <p>http://www.gov.ph</p></a>
                        <a href="http://www.gov.ph/data"><br></br>Open Data Portal <p>http://www.gov.ph/data</p></a>
                        <a href="http://www.officialgazette.gov.ph/"><br></br>Official Gazette <p>http://www.officialgazette.gov.ph</p></a>
                        <a href="http://www.senate.gov.ph/"><br></br>Senate of the Philippines <p>http://www.senate.gov.ph</p></a>
                        <a href="http://www.congress.gov.ph/"><br></br>House of Representatives <p>http://www.congress.gov.ph</p></a>
                    </div>
                    <div className='gov_columns_II'>
                        <a href="http://president.gov.ph/"><br></br>Office of the President <p>http://president.gov.ph</p></a>
                        <a href="http://ovp.gov.ph/"><br></br>Office of the Vice President <p>http://ovp.gov.ph</p></a>
                        <a href="http://sc.judiciary.gov.ph/"><br></br>Supreme Court <p>http://sc.judiciary.gov.ph</p></a>
                        <a href="http://ca.judiciary.gov.ph/"><br></br>Court of Appeals <p>http://ca.judiciary.gov.ph</p></a>
                        <a href="http://sb.judiciary.gov.ph/"><br></br>Sandiganbayan <p>http://sb.judiciary.gov.ph</p></a>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default AgencyLinks