import React, { useEffect, useState } from 'react'
import '../styles/Carousel.css'
// import { images } from "../components/CarouselData"
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import url from "../hooks/url";
import loadingUI from '../hooks/loadingUI';

const ImageSlider = () => {

  const [currImg, setCurrImg] = useState(0);
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const length = images.length;
  const port = url()

  useEffect(() => {
    let cancel
    setLoading(true)
    try {
      axios.get(`/api/admin/carousel/getAll`, {
        cancelToken: new axios.CancelToken((c) => cancel = c),
      }).then(res => {
        setImages(res.data.data)
        setLoading(false)
      }).catch((e) => {
        if (axios.isCancel(e)) return console.log("Request Cancelled")
      });
    } catch (error) {
      setLoading(false)
      console.log(error)
    }

    return () => {
      if (cancel) {
        cancel()
      }
    }
  }, [])

  const nextSlide = () => {
    setCurrImg(currImg === length - 1 ? 0 : currImg + 1);
  };

  const prevSlide = () => {
    setCurrImg(currImg === 0 ? length - 1 : currImg - 1);
  };

  if (loading) return loadingUI(loading)
  return (
    <div className="carousel">
      {images.length > 0 && (
        <div className="carouselWrapper">
          <div className="carouselInner"
            style={{ backgroundImage: `url(${port}${images[currImg].image})`, filter: 'blur(5px)' }}>
          </div>
          <div className="left" style={{ backgroundColor: 'rgba(0, 0, 0, 0.70)' }} onClick={prevSlide}>
            <ArrowBackIosIcon style={{ fontSize: 30, color: 'white' }} />
          </div>
          <div className="center" style={{ backgroundImage: `url(${port}${images[currImg].image})` }}>

          </div>
          <div className="right" style={{ backgroundColor: 'rgba(0, 0, 0, 0.70)' }} onClick={nextSlide}>
            <ArrowForwardIosIcon style={{ fontSize: 30, color: 'hite' }} />
          </div>
        </div>
      )}
    </div>
  );

}


export default ImageSlider
