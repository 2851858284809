import Avatar from 'react-avatar-edit';
import axios from 'axios'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'

const LogoModal = ({ data, closeModal }) => {
    // const [selectedFiles, setSelectedFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [src, setSrc] = useState(null)
    const [image, setImage] = useState(null);

    const onClose = () => {
        setImage(null);
    }
    const onCrop = view => {
        setImage(view)
        console.log(view)
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData();
        formData.append('image', image);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/admin/logo/update/${data.id}`, formData).then(res => {
                if (res.data.success) {
                    setLoading(false)
                    swal("Success", res.data.msg, "success");
                    setTimeout((_) => window.location.assign("/Admin_Edit_VMGO"), 1000);
                }
                else {
                    setLoading(false)
                    swal("Warning", "Invalid Credentials", "warning");
                }
            });
        });
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <div className='modalTitle'>
                    <p className='modalP'>Details</p>
                </div>
                <div className='modalBody'>
                    <Form.Group controlId="formFile" className="mb-3 formLogo">
                        <Form.Label>Upload Image: </Form.Label>
                        <Avatar
                            width={280}
                            height={280}
                            src={src}
                            onClose={onClose}
                            onCrop={onCrop}
                        />
                    </Form.Group>
                    {/* <span className='errorMsg'>{errors.image}</span> */}
                </div>
                <div className='modalFooter'>
                    <button className='closebtnModal' onClick={() => closeModal(false)} style={{ backgroundColor: 'crimson' }}>Cancel</button>
                    {loading ? (
                        <button
                            className='submitbtn'
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
                            <ClipLoader color={'white'} loading={loading} size={25} />
                        </button>
                    ) : (
                        <button className='closebtnModal' onClick={handleUpdate}>Save</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LogoModal