import axios from 'axios'
import React, { useState } from 'react'
import swal from 'sweetalert'
import url from '../../../hooks/url'
import "../css/Admin_Content_Management.css"
import CarouselModal from './Modal/CarouselModal'
import ModalConfirmation from './Modal/ModalConfirmation'

const CarouselTemplate = ({ data }) => {
    let port = url()
    const [openModal, setOpenModal] = useState(false)
    const [item, setItem] = useState({});
    const [show, setShow] = useState(false)
    const [itemId, setItemId] = useState("")
    const [loading, setLoading] = useState(false)

    //decline request
    const handleDecline = (id) => {
        setShow(true)
        setItemId(id)
    }

    const handleDeclineItem = () => {
        setLoading(true)
        axios.post(`/api/admin/carousel/remove/${itemId}`).then(res => {
            setLoading(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Admin_Edit_Carousel"), 1000);
        });
    }

    return (
        <div className='Car_Con'>
            <ModalConfirmation title="Delete Image" body="Are you sure you want to remove this image?" setShow={setShow} show={show} yesFunc={handleDeclineItem} loading={loading}/>
            <div className='Img_Con' style={{ backgroundImage: `url(${port}${data.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                {/* <img src={data.image} /> */}
            </div>
            <button className='car_change' onClick={() => { setOpenModal(true); setItem(data) }}> Change</button>
            <button className='car_remove' onClick={() => handleDecline(data.id)}> Remove</button>
            {openModal && <CarouselModal closeModal={setOpenModal} data={item} />}
        </div>
    )
}

export default CarouselTemplate