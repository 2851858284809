import React, { useState, useEffect } from 'react'
import "../../css/Admin_EDalaw.css"

import DetailsModal from '../../components/Modal/DetailsModal'
import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import swal from 'sweetalert';
import AcceptRequestModal from '../../components/Modal/AcceptRequestModal';
import DeclineRequestModal from '../../components/Modal/DeclineRequestModal';
import ExportRequest from '../../components/Export/ExportRequest';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

const Container = styled.div`
margin-top: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
position: fixed !important;
left: 22%;
right: 2%;
border-radius: 25px;
font-size: 24px;
background: white;
height: 60%;
overflow: auto;
`

function E_Dalaw_Pending() {
  const [request, setRequest] = useState([])
  const [isAccept, setIsAccept] = useState(false);
  const [itemId, setItemId] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const port = '/api/admin/EDalaw/declined/'
  const link = "/Admin_E_Dalaw_Pending"
  const type = 'edalaw'

  useEffect(() => {
    setLoadingPage(true)
    getRequest()
  }, [])

  const getRequest = () => {
    try {
      axios.get(`/api/admin/EDalaw/getPending`).then(res => {
        setRequest(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  //accept request
  const handleAccept = (item) => {
    setIsAccept(true);
    setOpenAcceptModal(true)
    setItemId(item)
  }

  //decline request
  const handleDecline = (item) => {
    setIsAccept(false);
    setOpenAcceptModal(true)
    setItemId(item)
  }

  //accept request
  const [openAcceptModal, setOpenAcceptModal] = useState(false)

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState({});

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  request.sort((a, b) => (a.date + a.start_time).localeCompare(b.date + b.start_time));

  return (
    <div className='all_body'>
      {isAccept ? (
        <>
          {openAcceptModal && <AcceptRequestModal data={itemId} closeModal={setOpenAcceptModal} />}
        </>
      ) : (
        <>
          {openAcceptModal && <DeclineRequestModal data={itemId} closeModal={setOpenAcceptModal} type={type} port={port} link={link} />}
        </>
      )}
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportRequest data={request} filename='EDalawPending.csv' />
          <div className='Admin_EDalaw_Pending'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>E-DALAW / PENDING REQUESTS</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <table className='styled-table marginContent'>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Requested By</th>
                  <th style={{ textAlign: "center" }}>PDL</th>
                  <th style={{ textAlign: "center" }}>Start of Schedule</th>
                  <th style={{ textAlign: "center" }}>End of Schedule</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {request && request.slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.fname + " " + item.lname}</td>
                      <td>{item.pdl_name}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.start_time}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.end_time}</td>
                      <td className='actionstatus'>
                        <button className='detailsbtn' onClick={() => { setOpenModal(true); setItem(item) }}>Details</button>
                        <button className='acceptbtn' onClick={() => handleAccept(item)}>Accept</button>
                        <button className='cancelbtn' onClick={() => handleDecline(item)}>Decline</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={request}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      {openModal && <DetailsModal closeModal={setOpenModal} data={item} />}
    </div>
  )
}

export default E_Dalaw_Pending