import React, { useEffect, useState } from 'react'
import "../styles/Edit_Profile.css"
import Head_Banner from "../components/Banner";
import HelpDesk from "../components/HelpDesk";
import axios from 'axios';
import dateFormat from '../hooks/dateFormat';
import swal from 'sweetalert';
import loadingUI from '../hooks/loadingUI';
import Avatar from 'react-avatar-edit';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';


function Edit_Profile() {
    // const [user, setUser] = useState({})
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [form, setForm] = useState({
        email: '',
        country: '',
        province: '',
        city: '',
        barangay: '',
        houseNo: '',
        street: '',
        contactNo: '',
    })

    const [src, setSrc] = useState(null)
    const [image, setImage] = useState(null);
    const onClose = () => {
        setImage(null);
    }
    const onCrop = view => {
        setImage(view)
        console.log(view)
    }

    const setField = (field, value) => {
        setForm({
            ...form, [field]: value
        })
    }

    useEffect(() => {
        userData()
    }, [])

    const userData = () => {
        axios.get('/api/user/data').then(res => {
            // setUser(res.data.data);
            setForm(res.data.data)
            setData(res.data.data)
            setLoading(false)
        })
            .catch(err => {
                console.log(err);
            })
    }

    const handleEditProfile = (e) => {
        e.preventDefault();

        if (form.email === '' || form.country === '' || form.province === '' || form.city === '' || form.barangay === '' || form.street === '' || form.contactNo === '') {
            swal("Error", 'Please fill out the forms!', "error");
        }
        else {
            let profile_picture = ''
            if (image === null && data.profilePic !== null) {
                profile_picture = data.profilePic
            } else if (image !== null) {
                profile_picture = image
            } else {
                profile_picture = null
            }
            const formData = new FormData();
            formData.append('email', form.email);
            formData.append('country', form.country);
            formData.append('province', form.province);
            formData.append('city', form.city);
            formData.append('barangay', form.barangay);
            formData.append('houseNo', form.houseNo);
            formData.append('street', form.street);
            formData.append('contactNo', form.contactNo);
            formData.append('profilePic', profile_picture);

            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post(`/api/user/profile/edit/${form.id}`, formData).then(res => {
                    if (res.data.success) {
                        swal("Success", res.data.msg, "success");
                        setTimeout((_) => window.location.reload(), 2000);
                    }
                    else {
                        // if(res.data.msg ===)
                        // swal("Error", 'Invalid Credentials', "error");
                        if (res.data.msg.email && res.data.msg.contactNo) {
                            swal("Error", 'The email and contact number have already been taken', "error");
                        } else if (res.data.msg.email) {
                            swal("Error", res.data.msg.email[0], "error");
                        }
                        else if (res.data.msg.contactNo) {
                            swal("Error", res.data.msg.contactNo[0], "error");
                        }
                    }
                });
            });
        }
    }

    if (loading) return loadingUI(loading)
    return (
        <div>
            <Navbar />
            <div className='Banner'><Head_Banner /></div>
            <div className='Edit_Prof_Body' style={{marginTop:'150px'}}>
                <div className='Edit_Prof'>
                    <form onSubmit={handleEditProfile}>
                        {disable ? (
                            <div className='Not_Editable'>
                                {data.profilePic === null ? (
                                    <div className='Edit_Img'>
                                        IMG
                                    </div>
                                ) : (
                                    <img className="prof_Pic" src={`${data.profilePic}`} />
                                )}
                                <label className='Edit_Name'> {`${form.fname} ${form.lname}`} </label>
                            </div>
                        ) : (
                            <div className='Not_Editable'>
                                <Avatar
                                    className='Edit_Img'
                                    width={200}
                                    height={200}
                                    src={src}
                                    onClose={onClose}
                                    onCrop={onCrop}
                                    
                                />
                                {console.log}
                                <label className='Edit_Name'> {`${form.fname} ${form.lname}`} </label>
                            </div>
                        )}

                        <div className='input_wrapper'>
                        <div className='parti_one'>
                        <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                            
                                <p className='input_p'>Birthdate:</p>
                                <input
                                    className='disabled_profileFormInput'
                                    type='email'
                                    disabled
                                    value={form.birthdate}
                                />
                            
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Gender:</p>
                                <input
                                    className='disabled_profileFormInput'
                                    type='email'
                                    disabled
                                    value={form.gender}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Email: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='email'
                                    name='email'
                                    onChange={e => setField('email', e.target.value)}
                                    value={form.email}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Contact Number: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='contactNo'
                                    onChange={e => setField('contactNo', e.target.value)}
                                    value={form.contactNo}
                                />
                            </div>
                            </div>
                            </div>
                            <div className='parti_two'>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>House Number: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='houseNo'
                                    onChange={e => setField('houseNo', e.target.value)}
                                    value={form.houseNo}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Street: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='street'
                                    onChange={e => setField('street', e.target.value)}
                                    value={form.street}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Barangay: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='barangay'
                                    onChange={e => setField('barangay', e.target.value)}
                                    value={form.barangay}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Municipality: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='city'
                                    onChange={e => setField('city', e.target.value)}
                                    value={form.city}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Province: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='province'
                                    onChange={e => setField('province', e.target.value)}
                                    value={form.province}
                                />
                            </div>
                            <hr style={{ width: "90%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} />
                            <div className='div_flex'>
                                <p className='input_p'>Country: </p>
                                <input
                                    className={disable ? 'disabled_profileFormInput' : 'profileFormInput'}
                                    disabled={disable}
                                    type='text'
                                    name='country'
                                    onChange={e => setField('country', e.target.value)}
                                    value={form.country}
                                />
                            </div>
                            </div>
                            
                            {/* <hr style={{ width: "100%", marginTop: '5px', marginBottom: '5px', border: "0.1px solid #9d9d9d" }} /> */}
                        </div>
                        {disable ? (
                            <button className="RequestEdit" onClick={() => setDisable(false)}>Edit Profile</button>
                        ) : (
                            <div className='div_flex_button'>
                                <button className="profile_cancelbtn" onClick={() => { setDisable(true); userData() }}>Cancel</button>
                                <button className="RequestEdit" type='submit'>Submit</button>
                            </div>
                        )}
                    </form>

                </div>
            </div>
            <div><Footer /></div>
        </div>
    )
}

export default Edit_Profile