import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';

const ModalConfirmation = ({ title, body, setShow, show, yesFunc, loading }) => {
    const handleClose = () => {
        setShow(false)
    }
    return (
        // <Modal show={show} onHide={handleClose} >
        //     <Modal.Header closeButton>
        //         <Modal.Title>{title}</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>{body}</Modal.Body>
        //     <Modal.Footer>
        //         <Button variant="secondary" onClick={handleClose}>
        //             No
        //         </Button>
        //         <Button variant="primary" onClick={yesFunc}>
        //             Yes
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
        <>
            {show === true && (
                <div className='modalBackground'>
                    <div className='modalContainer'>
                        <div className='modalTitle'>
                            <p className='modalP'>{title}</p>
                        </div>
                        <div className='modalBody'>
                            <span>{body}</span>
                        </div>
                        <div className='modalFooter'>
                            <button className='closebtnModal' onClick={handleClose} style={{ backgroundColor: 'crimson' }}>No</button>
                            {loading ? (
                                <button className='closebtnModal' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <ClipLoader color={'white'} loading={loading} size={25} />
                                </button>
                            ) : (
                                <button className='closebtnModal' onClick={yesFunc}>Yes</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ModalConfirmation