import React from 'react'
import url from '../hooks/url';
import "../styles/Announcement.css";

  function AnnounceItems( {id, name, date, image, body, dateUp }) {
  const port = url()
  let str = body.substring(0, 400) + '...';
  return (
    <div className='announcementItem' onClick={() => window.location.assign(`/Post/${id}`)}>
        <div className='announce_card_details'>
        <h1> {name}</h1>
        {date !== null ? (<p>{date}</p>) : <p></p>}
        {/* <p>{body}</p> */}
        <div dangerouslySetInnerHTML={{__html: str}} style={{overflow: 'hidden', height:'142px'}}></div>
        <p style={{marginTop: '50px'}}>{dateUp}</p>
        </div>
        <div className='announce_card_picture' style={{backgroundImage: `url(${port}${image})`}}></div>
    </div>
  )
}



export default AnnounceItems