import React, { useState, useRef, useEffect } from 'react'

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import './mapgeo.css'
import 'leaflet/dist/leaflet.css'
import mapProviders from './mapProviders'
import L from 'leaflet'

const MapGeoTag = (props) => {
  const [viewport, setViewport] = useState({ zoom: 12.5 });
  const [center, setCenter] = useState({ lat: 14.967203, lng: 120.884343 })
  const mapRef = useRef();

  const markerIcon = new L.Icon({
    iconUrl: require("../../../../assets/Map-Marker-PNG-HD.png"),
    //iconSize: 3 * viewport.zoom,
    iconSize: [20, 20],
    iconAnchor: [15, 30],
    popupAnchor: [-5, -30]
  })

  return (
    <div>
      <MapContainer
        center={center}
        zoom={viewport.zoom}
        ref={mapRef}
      >
        <TileLayer
          url={mapProviders.maptiler.url}
          attribution={mapProviders.maptiler.attribution}
        />
        {props.markers.map((marker, index) => {
          return (
            <Marker
              position={[marker.lat, marker.long]}
              icon={markerIcon}
              key={index}
            >
              {marker.province === null ? (
                <Popup>
                  {props.type === 'pdl' ? (
                    <>
                      <p>{marker.barangay}</p>
                      {marker.outside_city.length !== 0 ?
                        marker.outside_city.map((item, index) => {
                          return (
                            <p style={{ display: "block" }} key={index}>-{item}</p>
                          )
                        })
                        : (<></>)}
                      <p>Pdl Count: {marker.pdl_count}</p>
                    </>
                  ) : (
                    <>
                      <p>{marker.barangay}</p>
                      {marker.outside_city_user.length !== 0 ?
                        marker.outside_city_user.map((item, index) => {
                          return (
                            <p style={{ display: "block" }} key={index}>-{item}</p>
                          )
                        })
                        : (<></>)}
                      <p>User Count: {marker.user_count}</p>
                    </>
                  )}
                </Popup>
              ) : (
                <Popup>
                  {props.type === 'pdl' ? (
                    <>
                      <p>{marker.barangay}, {marker.city}, {marker.province}</p>
                      <p>Pdl Count: {marker.pdl_count}</p>
                    </>
                  ) : (
                    <>
                      <p>{marker.barangay}, {marker.city}, {marker.province}</p>
                      <p>User Count: {marker.user_count}</p>
                    </>
                  )}
                </Popup>
              )}
            </Marker>
          )
        })}
      </MapContainer>
    </div>
  )
}


export default MapGeoTag