
import { useEffect } from "react";
import GetCookie from "../hooks/getCookie";

const IsLoggedIn = () => {
  let isLogged = false;
  const usrtk = GetCookie("userToken");
  usrtk !== undefined ? isLogged = true : isLogged = false;
  // useEffect(() => {
  //   usrtk !== undefined ? isLogged = true : isLogged = false;
  // }, [usrtk])

  return isLogged;
}

export default IsLoggedIn;