import React, { useEffect, useState } from 'react'
import './pagination.css'
const Pagination = (props) => {
  const {data, itemsPerPage, currrentPage, setCurrentPage} = props
  // const [currrentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(1);

  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  }

  const pages = [];
  for(let i = 1; i<=Math.ceil(data.length/itemsPerPage); i++){
    pages.push(i);
  }


  const handleNext = () => {
    if(currrentPage+1 <= pages.length){
      setCurrentPage(currrentPage + 1)
      if(currrentPage+1 > maxPageNumberLimit){
        setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
        setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
      }
    } 
  }
  const handlePrev = () => {
    if(currrentPage-1 !== 0 && !isNaN(currrentPage)){
      setCurrentPage(currrentPage - 1)
      if((currrentPage-1)%pageNumberLimit == 0){
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
      }
    }
  }

  const renderPageNum = pages.map(number => {
    if(number < maxPageNumberLimit + 1 && number > minPageNumberLimit){
      return(
        <li 
          key={number} 
          id={number} 
          className={currrentPage == number ? "activePage" : null} 
          onClick={handleClick}>
            {number}
        </li>
      ) 
    } else {
      return null
    }
  })

  return (
    <>
      <ul className='pagination'>
        <li onClick={handlePrev}>Prev</li>
        {renderPageNum}
        <li onClick={handleNext}>Next</li>
      </ul>
    </>
  );
}

export default Pagination