import React, { useEffect, useState } from 'react'
import Head_Banner from "../components/Banner";
import "../styles/FTF.css"
import GetCookie from '../hooks/getCookie'
import axios from 'axios';
import radioData from '../helpers/RadioData';
import dateFormat from '../hooks/dateFormat';
import compareDate from '../hooks/compareDate';
import loadingUI from '../hooks/loadingUI';
import swal from 'sweetalert';
import Qr from './Qr/Qr';
import FormInput from "../components/forms/FormInput";
import ReactPaginate from "react-paginate";
import '../components/Pagination/pagination.css'
import Pagination from '../components/Pagination/Pagination';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { ClipLoader } from 'react-spinners';
import ContactFooter from "../components/Con_Footer";

function FacetoFace() {
  const userToken = GetCookie()

  const [form, setForm] = useState({
    lname: '',
    fname: '',
    mname: '',
    email: '',
    gender: '',
    address: '',
    contactNo: '',
    date: '',
    pdl_name: '',
    pdl_relationship: '',
    time: '',
  })

  const [data, setData] = useState([])
  const [disable, setDisable] = useState(true)
  const [loading, setLoading] = useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [errors, setErrors] = useState({})
  const [date, setDate] = useState(null)
  const [requestsDate, setRequestsDate] = useState([])
  const [userPdl, setUserPdl] = useState([])

  //qr
  const [isPressed, setIsPressed] = useState(false)
  const [qrData, setQrData] = useState([])


  const setField = (event, field, value) => {
    setForm({
      ...form, [field]: value
    })

    if (event.target.name === 'date' && event.target.value === '') {
      setDisable(true)
    } else if (event.target.name === 'date' && event.target.value !== '') {
      setDisable(false)
      setDate(event.target.value)
    }

    if (!!errors[field])
      setErrors({
        ...errors, [field]: null
      })

  }

  useEffect(() => {
    getRequests()
    getDates()
    getUserData()
    getUserPdl()
  }, [userToken])

  const getUserPdl = () => {
    try {
        axios.get('/api/admin/userpdl/getPdl').then(res => {
            setUserPdl(res.data.data)
            setLoading(false)
        })
    } catch (error) {
        console.log(error)
    }
}

  const getUserData = () => {
    try {
      axios.get('/api/user/visit/data').then(res => {
        setForm(res.data.data[0]);
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDates = () => {
    try {
      axios.get('/api/user/F2f/getAll').then(res => {
        setRequestsDate(res.data.data)
        // setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRequests = () => {
    try {
      axios.get('/api/user/F2f/index').then(res => {
        setData(res.data)
        // setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const submitRequest = (e) => {
    e.preventDefault();
    setLoadingBtn(true)
    if (compareDate(requestsDate, form.date, form.time)) {
      swal("Warning", 'Time selected is already taken.', "warning");
      setLoading(false)
    } else {
      const formData = new FormData();
      formData.append('lname', form.lname);
      formData.append('fname', form.fname);
      formData.append('mname', form.mname);
      formData.append('email', form.email);
      formData.append('gender', form.gender);
      formData.append('address', form.address);
      formData.append('contactNo', form.contactNo);
      formData.append('pdl_name', form.pdl_name);
      formData.append('pdl_relationship', form.pdl_relationship);
      formData.append('date', form.date);
      formData.append('start_time', form.time.split(',')[0]);
      formData.append('end_time', form.time.split(',')[1]);

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post(`/api/user/F2f/store`, formData).then(res => {
          if (res.data.success) {
            setLoadingBtn(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Face_to_Face"), 1500);
          }
          else {
            const newErrors = res.data.msg
            if (form.gender === 'select') {
              newErrors.gender = 'The gender field is required'
            }
            if (res.data.msg.start_time !== undefined) {
              swal("Warning", 'Select time of visitation.', "warning");
            }
            setErrors(newErrors)
            setLoadingBtn(false)
          }
        });
      });
    }
  }

  const status_class = (status) => {
    if (status === 'Pending') {
      return (<p className='pending'>{status}</p>)
    } else if (status === 'Accepted') {
      return (<p className='accepted'>{status}</p>)
    } else if (status === 'Declined') {
      return (<p className='declined'>{status}</p>)
    }
  }


  //test
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  data.sort((a, b) => b.created_at.localeCompare(a.created_at));

  if (loading) return loadingUI(loading)

  return (
    <div>
      <Navbar />
      <div className='Banner'><Head_Banner /></div>
      <div className='FTF_Body' style={{ paddingTop: '130px' }}>
        {isPressed ? <Qr data={qrData} isPressed={isPressed} /> :
          (
            <>
              <div className='FTF_title'><h2>FACE-TO-FACE VISITATION</h2></div>
              <div className='FTF_Form'>
                <div className='Form_Body'>
                  <form onSubmit={submitRequest}>
                    <div className='E_Dal_name'>
                      <div className='Visitation_formName fname'>
                        <label className='fname'>
                          <p>First Name: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="text"
                            className="F_name"
                            name='fname'
                            onChange={e => setField(e, 'fname', e.target.value)}
                            value={form.fname}
                            required
                            errorMessage="This field is required."
                          />
                        </label>
                      </div>
                      <div className='Visitation_formName mname'>
                        <label className='mname'>
                          <p>Middle Name:</p>
                          <FormInput type="text" className="M_name" name='mname' onChange={e => setField(e, 'mname', e.target.value)} value={form.mname === null ? '' : form.mname} />
                        </label>
                      </div>
                      <div className='Visitation_formName lastname'>
                        <label className='lname'>
                          <p>Last Name: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="text"
                            className="L_name"
                            name='lname'
                            onChange={e => setField(e, 'lname', e.target.value)}
                            value={form.lname}
                            required
                            errorMessage="This field is required."
                          />
                        </label>
                      </div>
                    </div>

                    <div className='Add_PDL_Email' align="left">
                      <div className='Visitation_FormFull'>
                        <label>
                          <p>Mobile Number: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="tel"
                            className="Con_Num"
                            name='contactNo'
                            onChange={e => setField(e, 'contactNo', e.target.value)}
                            value={form.contactNo}
                            placeholder='09xxxx'
                            pattern='[0-9]{11}'
                            required
                            errorMessage="Phone number format is invalid."
                          />
                        </label>
                      </div>
                      <div className='Visitation_FormFull'>
                        <label className="item">Gender: <span style={{ color: "red" }}>*</span></label>
                        <select className="Relation" name="gender" onChange={e => setField(e, 'gender', e.target.value)} value={form.gender} required>
                          <option value="">Select Gender</option>
                          <option value="Male">Male/Lalaki</option>
                          <option value="Female">Female/Babae</option>
                        </select>
                        <span className='errorMsg'>{errors.gender}</span>
                      </div>
                    </div>

                    <div className='Add_PDL_Email' align="left">
                      <div className='Visitation_FormFull'>
                        <label>
                          <p>Address: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="text"
                            className="Add_Form"
                            name='address'
                            onChange={e => setField(e, 'address', e.target.value)}
                            value={form.address === undefined ? '' : form.address}
                            placeholder="houseNo street, barangay, city, province, country"
                            required
                            errorMessage="This field is required."
                          />
                        </label>
                      </div>
                      {/* <div className='Visitation_FormFull'>
                        <label>
                          <p>PDL Name: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="text"
                            className="PDL_Name_Form"
                            name='pdl_name'
                            onChange={e => setField(e, 'pdl_name', e.target.value)}
                            value={form.pdl_name === undefined ? '' : form.pdl_name}
                            required
                            errorMessage="This field is required."
                          />
                        </label>
                      </div> */}
                      <div className='Visitation_FormFull'>
                        <label className="item">PDL Name: <span style={{ color: "red" }}>*</span></label>
                        <select className="PDL_Name_Form" name="pdl_name" onChange={e => setField(e, 'pdl_name', e.target.value)} value={form.pdl_name === undefined ? '' : form.pdl_name} required>
                          <option value="">Select PDL Name</option>
                          {userPdl.map((item, i) => {
                            return (
                              <option key={i} value={item.pdl_name}>{item.pdl_name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='Rela_Email_Form' align="left">
                      <div className='Visitation_FormFull'>
                        <label>
                          <p>Relationship: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="text"
                            className="PDL_Relation_Form"
                            name='pdl_relationship'
                            onChange={e => setField(e, 'pdl_relationship', e.target.value)}
                            value={form.pdl_relationship === undefined ? '' : form.pdl_relationship}
                            required
                            errorMessage="This field is required."
                          />
                        </label>
                      </div>
                      <div className='Visitation_FormFull'>
                        <label>
                          <p>Email Address: <span style={{ color: "red" }}>*</span></p>
                          <FormInput
                            type="email"
                            className="Email_Add_Form"
                            name='email'
                            onChange={e => setField(e, 'email', e.target.value)}
                            value={form.email}
                            required
                            errorMessage="Input a valid email address."
                          />
                        </label>
                      </div>
                    </div>
                    <div className='Visitation_FormFull' align="left">
                      <div className='form_date'>
                        <label>
                          <p>Date: <span style={{ color: "red" }}>*</span></p>
                          <input className='birthdate_Form' type="date" placeholder='Select Date' name='date' onChange={e => setField(e, 'date', e.target.value)} value={form.date} />
                          <span className='errorMsg'>{errors.date}</span>
                        </label>
                      </div>
                    </div>


                    <label>
                      {disable ? (<p>Set Date to see available time...</p>) : (<p>Available Time:</p>)}
                    </label>
                    <div className='radio_container'>
                      {radioData && radioData.map((item) => {
                        return (
                          <div key={item.key}>
                            {disable ? (<></>
                            ) : (
                              <div className={compareDate(requestsDate, date, item.value) ? 'disable_radio' : 'able_radio'}>
                                <input
                                  type="radio"
                                  disabled={compareDate(requestsDate, date, item.value) ? true : false}
                                  value={item.value}
                                  name="time"
                                  onChange={e => setField(e, 'time', e.target.value)}
                                />
                                <span>{item.view}</span>
                              </div>
                            )
                            }
                          </div>
                        )
                      })}
                    </div>
                    <div className='buttons'>
                      {/* <div className='but_cancel'><button type="submit" value="Cancel" />Cancel</div> */}
                      {loadingBtn ? (
                        <button className='but_submit' type="submit" value="Submit">
                          <ClipLoader color={'white'} loading={loadingBtn} size={25} />
                        </button>
                      ) : (<button className='but_submit' type="submit" value="Submit">Submit</button>)}
                    </div>
                  </form>
                </div>

                <div className='req_List'>
                  <p className='req_title'> Face to Face Request List</p>
                  <div style={{}}>
                    <table className='styled-table'>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>No.</th>
                          <th style={{ textAlign: "center" }}>Status</th>
                          <th style={{ textAlign: "center" }}>PDL Name</th>
                          <th style={{ textAlign: "center" }}>Start Time</th>
                          <th style={{ textAlign: "center" }}>End Time</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.slice(start, end).map((item, index) => {
                          return (
                            <tr key={index} className={index % 2 == 0 ? "odd" : "even"}>
                              <th scope='row'>{start + index + 1}</th>
                              <td className='status'>{status_class(item.status)}</td>
                              <td>{item.pdl_name}</td>
                              <td>{`${dateFormat(item.date, 'short')} ${item.start_time}`}</td>
                              <td>{`${dateFormat(item.date, 'short')} ${item.end_time}`}</td>
                              <td>
                                {item.status === 'Accepted' ?
                                  (<button className='btn btn-edit' onClick={e => { setIsPressed(true); setQrData(item) }}>Qr Code</button>) :
                                  (<h2 style={{ color: 'gray', textAlign: 'center' }}>N/A</h2>)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      data={data}
                      itemsPerPage={itemsPerPage}
                      currrentPage={currrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  )
}

export default FacetoFace