import React from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const ExportAdmin = ({data, filename}) => {
  const headers = [
    {label: 'First Name', key: 'fname'},
    {label: 'Middle Name', key: 'mname'},
    {label: 'Last Name', key: 'lname'},
    {label: 'Gender', key: 'gender'},
    {label: 'Contact Number', key: 'contactNo'},
    {label: 'Email', key: 'email'},
    {label: 'Birthday', key: 'birthdate'},
    {label: 'House #', key: 'houseNo'},
    {label: 'Street', key: 'street'},
    {label: 'Barangay', key: 'barangay'},
    {label: 'City', key: 'city'},
    {label: 'Province', key: 'province'},
    {label: 'Country', key: 'country'},
  ]

  const csvReport = {
    filename: filename,
    headers: headers,
    data: data
  }
  return (
    <div className='al_exportbtn'>
        <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>
  )
}

export default ExportAdmin