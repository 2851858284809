import React, { useEffect, useState } from 'react'
import "../css/Admin_Dashboard.css"
import axios from 'axios';
import Pagination from '../../../components/Pagination/Pagination';
import ShowEntry from '../../../components/Pagination/ShowEntry'

import Admin_Navbar from "../components/Admin_Navbar"
import Top_Bar from "../components/Top_Bar"
import QB from "../components/Quick_Buttons"
import loadingUI from '../../../hooks/loadingUI';

function Admin_Dashboard() {

    const [pdl, setPdl] = useState([])
    const [count, setCount] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getRequest()
        getCount()
    }, [])

    const getRequest = () => {
        try {
            axios.get(`/api/admin/pdl/getAll`).then(res => {
                setPdl(res.data.data)
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getCount = () => {
        try {
            axios.get(`/api/admin/dashboard/count`).then(res => {
                setCount(res.data.data[0])
                setLoading(false)
            });
        } catch (error) {
            console.log(error)
        }
    }

    const agefromBday = (date) => {
        const today = new Date();
        const birthDate = new Date(date);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    //test
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currrentPage, setCurrentPage] = useState(1);

    const end = currrentPage * itemsPerPage;
    const start = end - itemsPerPage;

    if (loading) return loadingUI(loading)

    return (
        <div className='Admin_Dash_Body'>
            <div><Top_Bar /></div>
            <div className='ad_nav_size'><Admin_Navbar /></div>
            <div className='Admin_Dash_Main'>
                <div><QB data={count} /></div>
                <div className='req_List'>
                    <div className='req_title'>
                        <p style={{ marginLeft: '40%' }}>PDL INFORMATION</p>
                        <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
                    </div>
                    <div style={{}}>
                        <table className='styled-table'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Name</th>
                                    <th style={{ textAlign: "center" }}>Age</th>
                                    <th style={{ textAlign: "center" }}>Contact Person</th>
                                    <th style={{ textAlign: "center" }}>Relationship</th>
                                    <th style={{ textAlign: "center" }}>Contact Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pdl && pdl.slice(start, end).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* <th scope='row'>{start + index + 1}</th> */}
                                            <td>{item.fname + " " + item.lname}</td>
                                            <td>{agefromBday(item.birthdate)}</td>
                                            <td>{item.contactPerson ? item.contactPerson : (<p style={{ textAlign: "center" }}> - </p>)}</td>
                                            <td>{item.relationship ? item.relationship : (<p style={{ textAlign: "center" }}> - </p>)}</td>
                                            <td>{item.contactNumber ? item.contactNumber : (<p style={{ textAlign: "center" }}> - </p>)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            data={pdl}
                            itemsPerPage={itemsPerPage}
                            currrentPage={currrentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Dashboard