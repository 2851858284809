import React, { useState, useEffect } from 'react'
import "../../css/Admin_EDalaw.css"

import ShowEntry from '../../../../components/Pagination/ShowEntry'
import styled from 'styled-components';
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios';
import dateFormat from '../../../../hooks/dateFormat';

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import ExportRequest from '../../components/Export/ExportRequest';
import loadingUI from '../../../../hooks/loadingUI';

const Styles = styled.div`
padding-left: 60px;
background: #064671;
text-align: left;
position: absolute !important;
left: 0%;
top:0;
right: 0%;
font-size: 36px;
color: white;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
`

function E_Dalaw_History() {

  const [request, setRequest] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  useEffect(() => {
    setLoadingPage(true)
    getRequest()
  }, [])

  const getRequest = () => {
    try {
      axios.get(`/api/admin/F2f/history`).then(res => {
        setRequest(res.data.data)
        setLoadingPage(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const status_class = (status) => {
    if (status === 'Pending') {
      return (<button className='pendingbtn' style={{ cursor: 'auto' }}>{status}</button>)
    } else if (status === 'Accepted' || status === 'Done') {
      return (<button className='acceptbtn' style={{ cursor: 'auto' }}>{status}</button>)
    } else if (status === 'Declined') {
      return (<button className='cancelbtn' style={{ cursor: 'auto' }}>{status}</button>)
    } else if (status === 'Archived') {
      return (<button className='archivedbtn' style={{ cursor: 'auto' }}>{status}</button>)
    }
  }

  //test
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currrentPage, setCurrentPage] = useState(1);

  const end = currrentPage * itemsPerPage;
  const start = end - itemsPerPage;

  request.sort((a, b) => b.date.localeCompare(a.date));


  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      {loadingPage ? loadingUI(loadingPage) : (
        <div className='Admin_E_Dalaw_Main'>
          <div><SQB /></div>
          <ExportRequest data={request} filename='F2fHistory.csv' />
          <div className='Admin_EDalaw_History'>
            <Styles>
              <div className='profile' style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <p className='pageHeader'>FACE-TO-FACE / REQUEST HISTORY</p>
                <ShowEntry itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
              </div>
            </Styles>
            <table className='styled-table marginContent'>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>No.</th>
                  <th style={{ textAlign: "center" }}>Requested By</th>
                  <th style={{ textAlign: "center" }}>PDL</th>
                  <th style={{ textAlign: "center" }}>Start of Schedule</th>
                  <th style={{ textAlign: "center" }}>End of Schedule</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {request && request.slice(start, end).map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope='row'>{start + index + 1}</th>
                      <td>{item.fname + " " + item.lname}</td>
                      <td>{item.pdl_name}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.start_time}</td>
                      <td>{dateFormat(item.date, 'short') + " - " + item.end_time}</td>
                      <td className='actionstatus'>{status_class(item.status)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              data={request}
              itemsPerPage={itemsPerPage}
              currrentPage={currrentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default E_Dalaw_History