import React from 'react'
import {CSVLink} from "react-csv"
import './export.css'

const HelpDeskList = ({data, filename}) => {
  const headers = [
    {label: 'Admin Name', key: 'name'},
    {label: 'Admin Email', key: 'email'},
    {label: 'Contact Number', key: 'contactNumber'},
    {label: 'Message', key: 'message'},
  ]

  const csvReport = {
    filename: filename,
    headers: headers,
    data: data
  }
  return (
    <div className='al_exportbtn'>
        <CSVLink {...csvReport}>Export to CSV</CSVLink>
    </div>
  )
}

export default HelpDeskList