import React, { useState } from 'react'
import "../../css/Admin_PDL_Management.css"

import Admin_Navbar from "../../components/Admin_Navbar"
import Top_Bar from "../../components/Top_Bar"
import SQB from "../../components/Short_Quick_Buttons"
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import axios from 'axios'
import swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'

function Admin_Add_PDL() {
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    pdl_no: '',
    lname: '',
    fname: '',
    mname: '',
    gender: '',
    birthdate: '',
    country: '',
    province: '',
    city: '',
    barangay: '',
    houseNo: '',
    street: '',
    contactPerson: '',
    relationship: '',
    contactNumber: '',
  })

  const [image, setImage] = useState([]);
  const [rightSide, setRightSide] = useState([]);
  const [leftSide, setLeftSide] = useState([]);

  const setField = (field, value) => {
    setForm({
      ...form, [field]: value
    })
  }

  const handleImage = (e) => {
    e.persist();
    setImage({ img: e.target.files[0] });
  }
  const handleImage1 = (e) => {
    e.persist();
    setRightSide({ right_side: e.target.files[0] });
  }
  const handleImage2 = (e) => {
    e.persist();
    setLeftSide({ left_side: e.target.files[0] });
  }

  const [inputField, setInputField] = useState([])
  const handleAdd = (e) => {
    e.preventDefault()
    const add = [...inputField, []]
    setInputField(add)
  }

  const handleChange = (e, i) => {
    const inputData = [...inputField]
    inputData[i] = e.target.value
    setInputField(inputData)
  }

  const handleDelete = (i) => {
    const deleteField = [...inputField]
    deleteField.splice(i, 1)
    setInputField(deleteField)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    if (inputField.length <= 0) {
      setLoading(false)
      swal("Warning", 'Cases is required', "warning");
    } else {
      const formData = new FormData();
      formData.append('img', image.img);
      formData.append('right_side', rightSide.right_side);
      formData.append('left_side', leftSide.left_side);
      formData.append('pdl_no', form.pdl_no);
      formData.append('lname', form.lname);
      formData.append('fname', form.fname);
      formData.append('mname', form.mname);
      formData.append('gender', form.gender);
      formData.append('country', form.country);
      formData.append('province', form.province);
      formData.append('city', form.city);
      formData.append('barangay', form.barangay);
      formData.append('houseNo', form.houseNo);
      formData.append('street', form.street);
      formData.append('birthdate', form.birthdate);
      formData.append('contactNumber', form.contactNumber);
      formData.append('contactPerson', form.contactPerson);
      formData.append('relationship', form.relationship);
      formData.append('cases', inputField);
  
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post(`/api/admin/pdl/add`, formData).then(res => {
          if (res.data.success) {
            setLoading(false)
            swal("Success", res.data.msg, "success");
            setTimeout((_) => window.location.assign("/Admin_Add_PDL"), 1500);
          }
          else {
            setLoading(false)
            const newErrors = res.data.msg
            swal("Warning", 'Invalid Credentials', "warning");
          }
        });
      });
    }

  }

  return (
    <div className='all_body'>
      <div><Top_Bar /></div>
      <div className='ad_nav_size'><Admin_Navbar /></div>
      <div className='Admin_E_Dalaw_Main'>
        <div><SQB /></div>
        <div className='Admin_Add_PDL'>
          <div className='Add_PDL_Title'><label>ADD PDL : Fill out the following necessary information</label></div>
          <form onSubmit={handleSubmit}>
            <div className='total_partition'>
            <div className='add_pdl_partition_one'>
            <div className='add_pdl_table'>
              <div className='left_one'>PDL # <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="pdl_no" className='right_one' placeholder='Input PDL Number Here' value={form.pdl_no} onChange={e => setField('pdl_no', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>First Name: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="fname" className='right' placeholder='Input First Name Here' value={form.fname} onChange={e => setField('fname', e.target.value)} required />
            </div>
            <div className='add_pdl_table'>
              <div className='left_one'>Middle Name: </div>
              <input type='text' name="mname" className='right_one' placeholder='Input Middle Name Here' value={form.mname} onChange={e => setField('mname', e.target.value)} />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>Last Name: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="lname" className='right' placeholder='Input Last Name Here' value={form.lname} onChange={e => setField('lname', e.target.value)} required />
            </div>
            {/* <div className='add_pdl_table'>
              <div className='left_one'>Age: <span style={{ color: "red" }}>*</span></div>
              <input type='number' name="age" className='right_one' placeholder='Input Age Here' value={form.age} onChange={e => setField('age', e.target.value)} required />
            </div> */}

            <div className='add_pdl_table'>
              <div className='left'>Gender: <span style={{ color: "red" }}>*</span></div>
              <select className='right' name="gender" required style={{ fontSize: '14px', color:"grey" }} value={form.gender} onChange={e => setField('gender', e.target.value)}>
                <option value="">Select Gender</option>
                <option value="Male">Male/Lalaki</option>
                <option value="Female">Female/Babae</option>
              </select>
              {/* <input type='text' name="gender" className='right' placeholder='Input Gender Here' value={form.gender} onChange={e => setField('gender', e.target.value)} required /> */}
            </div>
            <div className='add_pdl_table'>
              <div className='left_one'>Birthday: <span style={{ color: "red" }}>*</span></div>
              <input type="date" className='right_one' name="birthdate" placeholder='Select Date' value={form.birthdate} onChange={e => setField('birthdate', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>House No.: </div>
              <input type='text' name="houseNo" className='right' placeholder='Input House No. Here' value={form.houseNo} onChange={e => setField('houseNo', e.target.value)} />
            </div>
            <div className='add_pdl_table'>
              <div className='left_one'>Street: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="street" className='right_one' placeholder='Input Street Here' value={form.street} onChange={e => setField('street', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>Barangay: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="barangay" className='right' placeholder='Input Barangay Here' value={form.barangay} onChange={e => setField('barangay', e.target.value)} required />
            </div>
            </div>
            <div className='add_pdl_partition_two'>
            <div className='add_pdl_table'>
              <div className='left_one'>City: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="city" className='right_one' placeholder='Input City Here' value={form.city} onChange={e => setField('city', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>Province: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="province" className='right' placeholder='Input Province Here' value={form.province} onChange={e => setField('province', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left_one'>Country: <span style={{ color: "red" }}>*</span></div>
              <input type='text' name="country" className='right_one' placeholder='Input Country Here' value={form.country} onChange={e => setField('country', e.target.value)} required />
            </div>

            <div className='add_pdl_table'>
              <div className='left'>Contact Person: </div>
              <input type='text' name="contactPerson" className='right' placeholder='Input Contact Person Here' value={form.contactPerson} onChange={e => setField('contactPerson', e.target.value)} />
            </div>

            <div className='add_pdl_table'>
              <div className='left_one'>Relationship: </div>
              <input type='text' name="relationship" className='right_one' placeholder='Input Relationship Here' value={form.relationship} onChange={e => setField('relationship', e.target.value)} />
            </div>
            <div className='add_pdl_table'>
              <div className='left'>Contact Number of Contact Person: </div>
              <input type='text' name="contactNumber" className='right' placeholder='Input Contact Number Here' value={form.contactNumber} onChange={e => setField('contactNumber', e.target.value)} />
            </div>
            </div>
            </div>
            <div className='Upload_Mugshot'>
              <label className='Mugshot_Upload_Label'>Upload PDL Front Mugshot: <span style={{ color: "red" }}>*</span></label>
              <div className='Mughot_Input_File' >
                <input className='Mugshot_Upload' type="file" id="myFile" name="adminImg" onChange={handleImage} required />
              </div>
            </div>
            <div className='Upload_Mugshot'>
              <label className='Mugshot_Upload_Label'>Upload PDL Right Side Mugshot: </label>
              <div className='Mughot_Input_File'>
                <input className='Mugshot_Upload' type="file" id="myFile" name="adminImg" onChange={handleImage1} />
              </div>
            </div>
            <div className='Upload_Mugshot'>
              <label className='Mugshot_Upload_Label'>Upload PDL Left Side Mugshot: </label>
              <div className='Mughot_Input_File' >
                <input className='Mugshot_Upload' type="file" id="myFile" name="adminImg" onChange={handleImage2}/>
              </div>
            </div>
            
            {inputField.map((data, i) => {
              return (
                <div className='add_pdl_table' key={i}>
                  <div className={(i + 1) % 2 === 1 ? 'left' : 'left_one'} style={{ width: '30%' }}>Case {i + 1}: <span style={{ color: "red" }}>*</span></div>
                  <input className={(i + 1) % 2 === 1 ? 'right' : 'right_one'} style={{ width: '66%' }} type='text' name='cases' value={data} onChange={e => handleChange(e, i)} placeholder={`Input Case ${i + 1} Here`} />
                  <button className='xBtn' onClick={e => handleDelete(i)}><IoIcons.IoMdCloseCircleOutline style={{ width: '25px', height: 'auto' }} /></button>
                </div>
              )
            })}
            <button className='detailsbtn' style={{ width: '15%', marginTop: '20px', fontSize: '18px' }} onClick={handleAdd}>Add Cases</button>
            <div>
              {loading ? (
                <button className='add_admin_submit'><ClipLoader color={'white'} loading={loading} size={25} /></button>
              ) : (
                <button className='add_admin_submit' type='submit'>Submit</button>
              )}
              {/* <button className='add_admin_submit' type='submit'>Submit</button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Admin_Add_PDL