import React, { useEffect, useState } from "react";
import '../styles/Home.css'
import Carousel from "../components/Carousel";
import HelpDesk from "../components/HelpDesk";
import ContactFooter from "../components/Con_Footer";
import Head_Banner from "../components/Banner";
import IsLoggedIn from "../auth/isLoggedIn";
import ChatUser from "./ChatUser";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import loadingUI from "../hooks/loadingUI";
import dateFormat from "../hooks/dateFormat";
import axios from "axios";


function Home() {
  const [newsHome, setNewsHome] = useState([])
  const [announcement, setAnnouncement] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUserData()
    getAnnouncementData()
  }, [])

  const getUserData = () => {
    try {
      axios.get('/api/user/getNews').then(res => {
        // setForm(res.data.data[0]);
        // setLoading(false)
        setNewsHome(res.data.news)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAnnouncementData = () => {
    try {
      axios.get('/api/user/getAnnouncement').then(res => {
        // setForm(res.data.data[0]);
        // setLoading(false)
        setAnnouncement(res.data.announcement)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  announcement.sort((a, b) => b.created_at.localeCompare(a.created_at));
  newsHome.sort((a, b) => b.created_at.localeCompare(a.created_at));

  if (loading) return loadingUI(loading)

  return (
    <div>
      <Navbar />
      <div className="body">
        <div className='Banner'><Head_Banner /></div>

        <div className="Con">

          <div className="car"><Carousel /></div>

          <div className="Home_Container">

            <div className="Home_Left">
              <div className="Home_Img"><div className="Dir"></div></div>
              <div className="Home_Img"><a href="https://www.bjmp.gov.ph/index.php/prime-hrm" target="_blank"><div className="PHRM"></div></a></div>
              <div className="Home_Img"><a href="https://www.bjmp.gov.ph/index.php/good-governance/transparency-seal" target="_blank"><div className="TS"></div></a></div>
              <div className="Home_Img"><a href="https://www.bjmp.gov.ph/index.php/iso" target="_blank"><div className="ISOCert"></div></a></div>
              <div className="Home_Img"><a href="https://www.foi.gov.ph/" target="_blank"><div className="FI"></div></a></div>
              <div className="Home_Img"><a href="https://www.bjmp.gov.ph/index.php/home-gad" target="_blank"><div className="GAD"></div></a></div>
              <div className="Home_Img"><a href="https://www.bjmp.gov.ph/index.php/audio-visual-presentation-campaign-on-data-privacy-act-of-2012" target="_blank"><div className="DP"></div></a></div>
              <div className="Home_Title"><h3>BJMP-Baliwag Features</h3></div>
              <div className="Home_Img"><Link to="/Face_To_Face" target="_blank"><div className="f2f_home"></div></Link></div>
              <div className="Home_Img"><Link to="/Online_Visitation" target="_blank"><div className="edalaw_home"></div></Link></div>
              <div className="Home_Img"><Link to="/Geo_Tag" target="_blank"><div className="geoTag_home"></div></Link></div>
            </div>

            <div className="Home_Right">
              <div className="Home_Title"><h3>Announcement</h3></div>
              {announcement.length !== 0 ?
                announcement.slice(0,5).map((item, i) => {
                  return (
                    <Link key={i} to={`/Post/${item.id}`}>
                      <div className="Announcement_Con">
                        <label className="Announce_Title">{item.title}</label>
                        <label className="Announce_Date">{dateFormat(item.created_at, 'long')}</label>
                      </div>
                    </Link>
                  )
                }) : (
                  <Link to="/Announcement">
                    <div className="Announcement_Con">
                      <label className="Announce_Title">Title</label>
                      <label className="Announce_Date">Date Published</label>
                    </div>
                  </Link>
                )
              }


              {/* news */}
              <div className="Home_Title"><h3>News</h3></div>
              {newsHome.length !== 0 ?
                newsHome.slice(0,5).map((item, i) => {
                  return (
                    <Link key={i} to={`/Post/${item.id}`}>
                      <div className="News_Con">
                        <label className="News_Title">{item.title}</label>
                        <label className="News_Date">{dateFormat(item.created_at, 'long')}</label>
                      </div>
                    </Link>
                  )
                })
                : (
                  <>
                    <Link to="/News">
                      <div className="News_Con">
                        <label className="News_Title">Title</label>
                        <label className="News_Date">Date Published</label>
                      </div>
                    </Link>
                    <Link to="/News">
                      <div className="News_Con">
                        <label className="News_Title">Title</label>
                        <label className="News_Date">Date Published</label>
                      </div>
                    </Link>
                  </>
                )}


              <div className="Home_Title"><h2>WHAT ARE THE NEW FEATURES OF BJMP-Baliwag WEBSITE</h2></div>
              <div className="Feature_Con">
                <label className="Feature_Title">Chat Application</label>
                <label className="Feature_Date">Details</label>
                <label className="Feature_Details">Using a chat program, it is simple to send and receive messages in real
                  time with BJMP Baliwag officers. Users of chat apps can engage in active
                  conversations using personalized messaging features, just like they would in person.
                  Additionally, it prevents customers from searching for a messaging solution elsewhere
                  and keeps them chatting on your platform. Because the officers can offer their feedback or
                  responses as soon as they get the messages, users are able to communicate more swiftly as
                  a result. However, confirmed users are the only ones who can utilize this feature.</label>
              </div>

              <div className="Feature_Con">
                <label className="Feature_Title">Geo-Tagging</label>
                <label className="Feature_Date">Details</label>
                <label className="Feature_Details">In the BJMP Baliwag System, geotagging entails
                  adding geographic coordinates to media depending on the general address of the PDLs.
                  This means that it does not always specify the precise address of each PDL.</label>

              </div>

              <div className="Feature_Con">
                <label className="Feature_Title">E-Dalaw / Online Visitation</label>
                <label className="Feature_Date">Details</label>
                <label className="Feature_Details">The E-Dalaw feature allows family and
                  acquaintances to request  online video meetings with the PDLs via Google Meet.
                  Only verified users have access to this feature.</label>
              </div>

              <div className="Feature_Con">
                <label className="Feature_Title">Quick Response "QR" Code for Face-To-Face Visitation </label>
                <label className="Feature_Date">Details</label>
                <label className="Feature_Details">You must authenticate your Face-To-Face Visitation inside
                  the BJMP Facility using the Quick Response "QR" Code. Only after the admin has approved your
                  request can you discover your QR Code on the Face-to-Face Visitation Page. Be sure to save the
                  QR Code or take a screenshot of it, then hand it to a BJMP Baliwag officer.</label>
              </div>

              <div className="Home_Title"><h2>HOW TO BE A VERIFIED USER?</h2></div>
              <div className="Feature_Con">
                <label className="Feature_Title">Signing Up To BJMP Website </label>
                <label className="Feature_Date">Details</label>
                <label className="Feature_Date">
                  You can sign up by :<br></br>
                  Clicking the "Sign Up Button" in the "Login Page"<br></br>
                  Fill out the form and upload necessary information and pictures<br></br>
                  Submit the request form and wait for the BJMP Admin to validate your request.<br></br>
                  You will be notified whether you request has been approved or not.
                </label>
              </div>
            </div>
          </div>
          <div className="Home_HelpDesk"><HelpDesk /></div>

          {IsLoggedIn() ? (<div><ChatUser /></div>) : (<></>)}

        </div>
      </div>
      <div><ContactFooter /></div>
      <div><Footer /></div>
    </div>
  )
}

export default Home