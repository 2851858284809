import React from 'react'
import "../css/Quick_Buttons.css"
import { Link } from 'react-router-dom';

function Quick_Buttons({ data }) {
    let timestamp = new Date();
    let Month = new Date(timestamp).toLocaleString('default', { month: 'short' });
    let Day = new Date(timestamp).getDate();
    let Year = new Date(timestamp).getFullYear();
    let NewDateFormat = `${Month} ${Day} ${Year}`;
    return (
        <div>
            <div className='Quick_Buttons_Body'>
                <div className='upper'>

                    <div className='Acc_Req'><Link to="/Admin_Pending_Users">
                        <div className='up'>
                            <label>Account Requests</label>
                        </div>
                        <div className='down'>
                            <label>{data.unverifiedUsers}</label>
                        </div>
                    </Link>
                    </div>

                    <div className='FTF_Req'><Link to="/Admin_FTF_Pending">
                        <div className='up'>
                            <label>Face To Face Requests</label>
                        </div>
                        <div className='down'>
                            <label>{data.f2f}</label>
                        </div>
                    </Link>
                    </div>

                    <div className='EDal_Req'><Link to="/Admin_E_Dalaw_Pending">
                        <div className='up'>
                            <label>E-Dalaw Requests</label>
                        </div>
                        <div className='down'>
                            <label>{data.edalaw}</label>
                        </div>
                    </Link>
                    </div>

                    <div className='Time'>
                        <div className='up'>
                            <label>Date</label>
                        </div>
                        <div className='down'>
                            <label>{NewDateFormat}</label>
                        </div>
                    </div>
                </div>
                <div className='lower'>

                    <div className='Reg_Users'>
                        <div className='up'>
                            <label>Registered Users</label>
                        </div>
                        <div className='down'>
                            <label>{data.verifiedUsers}</label>
                        </div>
                    </div>

                    <div className='Total_PDL'><Link to="/Admin_PDL_Info">
                        <div className='up'>
                            <label>Total PDL</label>
                        </div>
                        <div className='down'>
                            <label>{data.pdl}</label>
                        </div>
                    </Link>
                    </div>

                    <div className='Admin_Acc'><Link to="/Admin_View_Admins">
                        <div className='up'>
                            <label>Admin Accounts</label>
                        </div>
                        <div className='down'>
                            <label>{data.admin}</label>
                        </div>
                    </Link>
                    </div>

                    <div className='Setting'><Link to="/">
                        <label>Go to Website</label>
                    </Link>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Quick_Buttons