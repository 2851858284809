import React from 'react'

const timeFormat = (date) => {
let timestamp = new Date(date).getTime();
  let label = 'AM'
  let hour = new Date(timestamp).getHours();
  if(hour > 12){
    hour = hour - 12;
    label = 'PM'
  }
  let minute = new Date(timestamp).getMinutes();
  let NewTimeFormat = `${hour}:${minute} ${label}`;
  return NewTimeFormat
}

export default timeFormat